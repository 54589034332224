import {
  ShopifyStorefrontCart,
  ShopifyStorefrontCartBuyerIdentityInput,
  ShopifyStorefrontCartBuyerIdentityUpdatePayload,
  ShopifyStorefrontCartCreatePayload,
  ShopifyStorefrontCartDiscountCodesUpdatePayload,
  ShopifyStorefrontCartLineInput,
  ShopifyStorefrontCartLinesAddPayload,
  ShopifyStorefrontCartLinesRemovePayload,
  ShopifyStorefrontCartLinesUpdatePayload,
  ShopifyStorefrontMutationCartBuyerIdentityUpdateArgs,
  ShopifyStorefrontMutationCartDiscountCodesUpdateArgs,
  ShopifyStorefrontMutationCartLinesAddArgs,
  ShopifyStorefrontMutationCartLinesRemoveArgs,
  ShopifyStorefrontMutationCartLinesUpdateArgs,
  ShopifyStorefrontQueryRoot,
  ShopifyStorefrontQueryRootCartArgs,
} from '@shopify/types';

import {
  cartBuyerIdentityUpdateMutation,
  cartCreateMutation,
  cartDiscountCodesUpdateMutation,
  cartLinesAddMutation,
  cartLinesRemoveMutation,
  cartLinesUpdateMutation,
  cartQuery,
} from '@/graphql';

import { ShopifyRepository } from './ShopifyRepository';

export class ShopifyCartRepository extends ShopifyRepository {
  async create(): Promise<ShopifyStorefrontCart | undefined> {
    const res = await this.client.send<ShopifyStorefrontCartCreatePayload>(
      cartCreateMutation
    );
    return res.data?.cartCreate.cart || undefined;
  }

  async findOne(id: string): Promise<ShopifyStorefrontCart | undefined> {
    const res = await this.client.send<
      ShopifyStorefrontQueryRoot[`cart`],
      ShopifyStorefrontQueryRootCartArgs
    >(cartQuery, {
      id,
    });
    return res.data?.cart || undefined;
  }

  async addCartLines(
    cartId: string,
    lines: ShopifyStorefrontCartLineInput[]
  ): Promise<ShopifyStorefrontCart | undefined> {
    const res = await this.client.send<
      ShopifyStorefrontCartLinesAddPayload,
      ShopifyStorefrontMutationCartLinesAddArgs
    >(cartLinesAddMutation, {
      cartId,
      lines,
    });
    return res.data?.cart.cart || undefined;
  }

  async removeCartLines(
    cartId: string,
    lineIds: string[]
  ): Promise<ShopifyStorefrontCart | undefined> {
    const res = await this.client.send<
      ShopifyStorefrontCartLinesRemovePayload,
      ShopifyStorefrontMutationCartLinesRemoveArgs
    >(cartLinesRemoveMutation, {
      cartId,
      lineIds,
    });
    return res.data?.cart.cart || undefined;
  }

  async cartBuyerIdentityUpdate(
    cartId: string,
    buyerIdentity: ShopifyStorefrontCartBuyerIdentityInput
  ): Promise<ShopifyStorefrontCart | undefined> {
    const res = await this.client.send<
      ShopifyStorefrontCartBuyerIdentityUpdatePayload,
      ShopifyStorefrontMutationCartBuyerIdentityUpdateArgs
    >(cartBuyerIdentityUpdateMutation, {
      cartId,
      buyerIdentity,
    });
    return res.data?.cart.cart || undefined;
  }

  async updateCartLines(
    cartId: string,
    lines: { id: string; quantity: number }[]
  ): Promise<ShopifyStorefrontCart | undefined> {
    const res = await this.client.send<
      ShopifyStorefrontCartLinesUpdatePayload,
      ShopifyStorefrontMutationCartLinesUpdateArgs
    >(cartLinesUpdateMutation, {
      cartId,
      lines,
    });
    return res.data?.cartLinesUpdate.cart || undefined;
  }

  async applyDiscounts(
    cartId: string,
    discountCodes: string[]
  ): Promise<ShopifyStorefrontCart | undefined> {
    const res = await this.client.send<
      ShopifyStorefrontCartDiscountCodesUpdatePayload,
      ShopifyStorefrontMutationCartDiscountCodesUpdateArgs
    >(cartDiscountCodesUpdateMutation, {
      cartId,
      discountCodes,
    });
    return res.data?.cartDiscountCodesUpdate.cart || undefined;
  }
}
