import { _MedicalExpert, GatsbyPrismicMedicalExpert } from '@/domain/entities';
import { toImage } from '@/domain/mappings/image';

export const toMedicalExpert = (
  medicalExpert?: GatsbyPrismicMedicalExpert
): _MedicalExpert => ({
  firstName: medicalExpert?.data?.first_name || ``,
  lastName: medicalExpert?.data?.last_name || ``,
  jobs: medicalExpert?.data?.jobs?.map((item) => item?.job || ``) || [],
  image: toImage(medicalExpert?.data?.avatar),
});
