import { useToast } from '@chakra-ui/react';

import { useTranslation } from '@/hooks/useTranslation';

export const useToasts = () => {
  const toast = useToast({
    position: `top-right`,
    status: `error`,
    duration: 10_000,
  });
  const { t } = useTranslation();

  const activateAccountError = () =>
    toast({
      title: t(`error.activateAccount.title`),
      description: t(`error.activateAccount.description`),
    });

  const activateAccountSuccess = () =>
    toast({
      status: `success`,
      title: t(`success.activateAccount.title`),
    });

  const creatingAccountError = () =>
    toast({
      title: t(`error.creatingAccount.title`),
      description: t(`error.creatingAccount.description`),
    });

  const creatingAddressSuccess = () =>
    toast({
      status: `success`,
      title: t(`success.creatingAddress.title`),
    });

  const loginError = () =>
    toast({
      title: t(`error.login.title`),
      description: t(`error.login.description`),
    });

  const passwordForgottenError = () =>
    toast({
      title: t(`error.passwordForgotten.title`),
    });

  const passwordForgottenSuccess = () =>
    toast({
      status: `success`,
      title: t(`success.passwordForgotten.title`),
      description: t(`success.passwordForgotten.description`),
    });

  const passwordNotMatchingError = () =>
    toast({
      title: t(`error.passwordNotIdentical.title`),
      description: t(`error.passwordNotIdentical.description`),
    });

  const passwordResetError = () =>
    toast({
      title: t(`error.passwordReset.title`),
      description: t(`error.passwordReset.description`),
    });

  const passwordResetSuccess = () =>
    toast({
      status: `success`,
      title: t(`success.passwordReset.title`),
    });

  const updatingAddressSuccess = () =>
    toast({
      status: `success`,
      title: t(`success.updatingAddress.title`),
    });

  const updatingProfileSuccess = () =>
    toast({
      status: `success`,
      title: t(`success.updatingProfile.title`),
    });

  return {
    activateAccountError,
    activateAccountSuccess,
    creatingAccountError,
    creatingAddressSuccess,
    loginError,
    passwordForgottenError,
    passwordForgottenSuccess,
    passwordNotMatchingError,
    passwordResetError,
    passwordResetSuccess,
    updatingAddressSuccess,
    updatingProfileSuccess,
  };
};
