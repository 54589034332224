import { _LegalPage, GatsbyPrismicLegalPage } from '@/domain/entities';
import { toReactNode } from '@/domain/mappings/react';

export const toLegalPage = (
  legalPage?: GatsbyPrismicLegalPage,
  shouldHaveElements?: boolean,
  additionalProps?: Record<string, any>
): _LegalPage => ({
  heading: legalPage?.data.title || ``,
  content: toReactNode(
    legalPage?.data.content?.richText,
    shouldHaveElements,
    additionalProps
  ),
  firstPublicationDate: new Date(legalPage?.first_publication_date),
  lastPublicationDate: new Date(legalPage?.first_publication_date),
});
