import { ReactNode } from 'react';

export interface Props {
  symbol: ReactNode;
  label: string;
}

export function Emoji(props: Props): JSX.Element {
  const { label, symbol } = props;

  return (
    <span
      style={{ zIndex: 0 }}
      role="img"
      aria-label={label}
      aria-hidden={!label}
    >
      {symbol}
    </span>
  );
}
