import { forwardRef, MouseEvent } from 'react';
import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { _Accordion } from '@/domain/entities';

import { Heading } from '@/components/atoms';

import { useGetUniqueKey } from '@/hooks';

export interface Props extends AccordionProps {
  accordions: _Accordion[];
  isLoading?: boolean;
  onButtonClick?: (
    index: number,
    isExpanded: boolean,
    e: MouseEvent<HTMLButtonElement>
  ) => void;
}

export const Accordion = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { accordions = [], onButtonClick, ...rest } = props;

    const getUniqueKey = useGetUniqueKey();
    const styles = useMultiStyleConfig(`Accordion`, props);

    const onClick = (
      index: number,
      isExpanded: boolean,
      e: MouseEvent<HTMLButtonElement>
    ) => {
      onButtonClick?.(index, isExpanded, e);
    };

    return (
      <ChakraAccordion allowToggle ref={ref} {...rest}>
        {accordions.map((accordion, key) => (
          <AccordionItem key={getUniqueKey(accordion)}>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  __css={styles.button}
                  onClick={(e) => onClick(key, !isExpanded, e)}
                >
                  <Heading as="h3" variant="h4" pr={[5, 10]}>
                    {accordion.heading}
                  </Heading>
                  <AccordionIcon ml="auto" />
                </AccordionButton>
                <AccordionPanel pb={4}>{accordion.text}</AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </ChakraAccordion>
    );
  }
);
