import { Children } from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import {
  Swiper,
  SwiperProps,
  SwiperSlide,
  SwiperSlideProps,
} from 'swiper/react';

export type CarouselCore = SwiperCore;

SwiperCore.use([Autoplay]);

export interface Props extends SwiperProps {
  slideProps?: SwiperSlideProps;
}

export function Carousel(props: Props): JSX.Element {
  const { children, slideProps, ...rest } = props;

  return (
    <Swiper {...rest}>
      {Children.map(children, (child) => (
        <SwiperSlide {...slideProps}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
}
