import { forwardRef } from 'react';
import { useNumberInput } from '@chakra-ui/react';

import { Flex, IconButton, Input, InputProps } from '@/components/atoms';

export interface Props extends InputProps {
  defaultValue: number;
  value: number;
  onInputChange: (_: string, valueAsNumber: number) => void;
}

export const PreviewNumberInput = forwardRef<HTMLInputElement, Props>(
  (props, ref): JSX.Element => {
    const { defaultValue, value, onInputChange } = props;
    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
      useNumberInput({
        defaultValue,
        min: 1,
        max: 99,
        onChange(valueAsString: string, valueAsNumber: number) {
          onInputChange(valueAsString, valueAsNumber);
        },
      });
    const inc = getIncrementButtonProps();
    const dec = getDecrementButtonProps();
    const input = getInputProps();

    return (
      <Flex ref={ref}>
        <IconButton
          variant="numberInput"
          icon="minus"
          aria-label="Decrement"
          bgColor="transparent"
          borderWidth={2}
          textColor="gray.200"
          borderColor="gray.75"
          _hover={{ textColor: `black` }}
          mr={1}
          {...dec}
        />
        <Input
          defaultValue={defaultValue}
          value={value}
          textAlign="center"
          w="70px"
          h="25px"
          borderWidth={2}
          borderColor="gray.75"
          mr={1}
          {...input}
        />
        <IconButton
          variant="numberInput"
          icon="plus"
          aria-label="Increment"
          bgColor="transparent"
          borderWidth={2}
          textColor="gray.200"
          borderColor="gray.75"
          _hover={{ textColor: `black` }}
          {...inc}
        />
      </Flex>
    );
  }
);
