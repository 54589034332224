import { ReactNode } from 'react';
import {
  CloseButtonProps,
  Modal as ChakraModal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { css } from '@emotion/react';

export interface Props extends ModalProps {
  header?: ReactNode;
  footer?: ReactNode;
  spacingX?: number;
  spacingY?: number;
  contentProps?: ModalContentProps;
  headerProps?: ModalHeaderProps;
  bodyProps?: ModalBodyProps;
  footerProps?: ModalFooterProps;
  closeButtonProps?: CloseButtonProps;
}

export function Modal(props: Props): JSX.Element {
  const {
    children,
    header,
    footer,
    contentProps,
    headerProps,
    bodyProps,
    footerProps,
    closeButtonProps,
    ...rest
  } = props;
  return (
    <ChakraModal size="xl" {...rest}>
      <ModalOverlay />
      <ModalContent {...contentProps}>
        <ModalHeader {...headerProps}>{header}</ModalHeader>
        <ModalCloseButton {...closeButtonProps} />
        <ModalBody
          px={[5, null, 20]}
          py={[5, null, 10]}
          css={css`
            p {
              margin-bottom: 20px;

              &:last-of-type {
                margin-bottom: 0;
              }
            }
          `}
          {...bodyProps}
        >
          {children}
        </ModalBody>
        <ModalFooter {...footerProps}>{footer}</ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}
