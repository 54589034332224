import { useTheme } from '@chakra-ui/react';

import { TrackingContentType, TrackingEvent, TrackingValue } from '@/config';

import { _BlogPost } from '@/domain/entities';

import {
  Box,
  BoxProps,
  Button,
  Heading,
  Image,
  Link,
  Text,
} from '@/components/atoms';

import { useIsMobile, useTracking, useTranslation } from '@/hooks';

import { toBlogPostDate } from '@/utils';

export interface Props extends BoxProps {
  blogPost: _BlogPost;
}

export function BlogCardV2(props: Props): JSX.Element {
  const { blogPost, ...rest } = props;
  const mobile = useIsMobile();
  const { t } = useTranslation();
  const { track } = useTracking();
  const theme = useTheme();

  const onBlogPostClick = (blogPost: _BlogPost) => {
    track(TrackingEvent.SELECT_CONTENT, {
      [TrackingValue.CONTENT_TYPE]: TrackingContentType.BLOG_POST,
      [TrackingValue.ITEM_ID]: blogPost.id,
    });
  };

  return (
    <Box
      as={(rest) => (
        <Link
          to={blogPost.link.path}
          onClick={() => onBlogPostClick(blogPost)}
          {...rest}
        />
      )}
      _hover={
        mobile
          ? {}
          : {
              '.hover-blur': {
                filter: `blur(15px)`,
              },
              '.hover-button': {
                opacity: 1,
              },
              '.gradient-heading': {
                bg: theme.colors.grad1,
                color: `transparent`,
                bgClip: `text`,
              },
            }
      }
    >
      <Box
        h={280}
        w="100%"
        borderRadius={20}
        position="relative"
        overflow="hidden"
      >
        <Box
          width="100%"
          position="relative"
          h={280}
          borderRadius={20}
          overflow="hidden"
          willChange="filter"
          {...rest}
        >
          <Box position="relative">
            <Box className="hover-blur" transition="all .3s">
              <Image
                borderRadius={20}
                image={blogPost.image}
                alt={blogPost.image.alt}
                h={280}
              />
            </Box>
            <Box
              borderRadius={20}
              position="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              width="100%"
              height="100%"
              bgGradient="linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))"
            />
            <Box
              position="absolute"
              borderRadius={20}
              top={0}
              right={0}
              bottom={0}
              left={0}
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={5}
            />
            <Box
              position="absolute"
              borderRadius={20}
              top={0}
              right={0}
              bottom={0}
              left={0}
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={5}
            >
              <Button
                className="hover-button"
                transition="opacity .5s"
                opacity={0}
                position="relative"
                top={0}
                left={0}
                sx={{ py: 4, h: `fit-content` }}
                style={{ borderRadius: 99 }}
              >
                {t(`blog.readPost`)}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box ml={5} position="relative">
        <Text variant="5" color="gray.700" mt={5} mb={2.5}>
          {toBlogPostDate(blogPost)}
        </Text>
        <Heading
          className="gradient-heading"
          transition="background .3s"
          variant="h5"
          mb={2}
        >
          {blogPost.title}
        </Heading>
      </Box>
    </Box>
  );
}
