export enum Company {
  address = `Rue du Charron 287C, 1420 Braine l'Alleud (BE)`,
  email = `hello@hedda.health`,
  emailHelp = `help@hedda.health`,
  emailPrivacy = `privacy@hedda.health`,
  name = `Hedda Health`,
  number = `0772.424.262`,
  phone = `+32 (0)2 544 04 81`,
  vat = `BE0772424262`,
}
