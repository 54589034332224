import { _Address, AddressCreateInput } from '@/domain/entities';

import { HeddaRepository } from '@/repositories';

export class NewHeddaAddressesRepository extends HeddaRepository {
  async find(): Promise<_Address[]> {
    return await this.client.get(`${this.url}/address`);
  }

  async createAddress(address: AddressCreateInput): Promise<_Address> {
    return await this.client.post(`${this.url}/address/create`, {
      body: { ...address },
    });
  }

  async updateAddress(
    id: string,
    address: AddressCreateInput
  ): Promise<_Address> {
    return await this.client.post(`${this.url}/address/update`, {
      body: { id, ...address },
    });
  }

  async deleteAddress(id: string): Promise<string> {
    return await this.client.post(`${this.url}/address/delete`, {
      body: { id },
    });
  }

  async updateDefaultAddress(id: string): Promise<_Address> {
    return await this.client.post(`${this.url}/address/make-default`, {
      body: { id },
    });
  }
}
