import { ReactNode } from 'react';
import {
  ShopifyStorefrontCartLine,
  ShopifyStorefrontCartLineConnection,
  ShopifyStorefrontProduct,
  ShopifyStorefrontSellingPlanPercentagePriceAdjustment,
} from '@shopify/types';

import {
  _CartLine,
  _CartPage,
  GatsbyPrismicCartPage,
  GatsbyPrismicHelpPage,
  GatsbyPrismicProductConnection,
  GatsbyPrismicProductsPage,
  GatsbyShopifyProductConnection,
} from '@/domain/entities';
import { toImage } from '@/domain/mappings/image';
import { toReactNode } from '@/domain/mappings/react';

import { createLinkPath, toArray } from '@/utils';

export const toCartPage = (
  cartPage?: GatsbyPrismicCartPage,
  cartLines?: ShopifyStorefrontCartLineConnection,
  prismicProducts?: GatsbyPrismicProductConnection,
  shopifyProducts?: GatsbyShopifyProductConnection,
  productsPage?: GatsbyPrismicProductsPage,
  helpPage?: GatsbyPrismicHelpPage
): _CartPage => ({
  empty: {
    heading: toReactNode(cartPage?.data.empty_heading?.richText),
    productsLink: {
      label: cartPage?.data.empty_button_text || `Cart`,
      path: createLinkPath([cartPage?.lang, productsPage?.uid]),
    },
  },
  lineItems: {
    heading: toReactNode(cartPage?.data.heading?.richText),
    cartLines: toArray(cartLines)?.map((cartLine) =>
      toCartLine(cartLine, prismicProducts, shopifyProducts)
    ),
    banner: toReactNode(cartPage?.data.banner?.richText),
  },
  sidebar: {
    heading: toReactNode(cartPage?.data.sidebar_heading?.richText),
    button: cartPage?.data.sidebar_order || ``,
    legal: toReactNode(cartPage?.data.sidebar_legal?.richText),
    questions: {
      label: cartPage?.data.sidebar_questions_label || `Legal`,
      path: createLinkPath([cartPage?.lang, helpPage?.uid]),
    },
  },
});

export const toCartLine = (
  cartLine?: ShopifyStorefrontCartLine,
  prismicProducts?: GatsbyPrismicProductConnection,
  shopifyProducts?: GatsbyShopifyProductConnection
): _CartLine => {
  const shopifyProduct = toArray(shopifyProducts)?.filter(
    (product) => product.handle === cartLine?.merchandise?.product?.handle
  );

  return {
    id: cartLine?.id || ``,
    sku: shopifyProduct?.[0]?.variants?.[0]?.sku || ``,
    image: toImage(
      toArray(prismicProducts)?.find(
        (product) =>
          product?.data.product?.handle === cartLine?.merchandise.product.handle
      )?.data.image
    ),
    name: cartLine?.merchandise.product.title || ``,
    capacity: (
      cartLine?.merchandise.product as
        | (ShopifyStorefrontProduct & {
            productCapacity: { value: ReactNode } | undefined;
          })
        | undefined
    )?.productCapacity?.value,
    type: (
      cartLine?.merchandise.product as
        | (Omit<ShopifyStorefrontProduct, `productType`> & {
            productType: { value: ReactNode } | undefined;
          })
        | undefined
    )?.productType?.value,
    price: cartLine?.merchandise.priceV2.amount,
    quantity: cartLine?.quantity || 0,
    totalAmount: cartLine?.estimatedCost.totalAmount.amount,
    frequency: cartLine?.sellingPlanAllocation?.sellingPlan.name,
    percentage:
      (
        cartLine?.sellingPlanAllocation?.sellingPlan?.priceAdjustments[0]
          ?.adjustmentValue as ShopifyStorefrontSellingPlanPercentagePriceAdjustment
      )?.adjustmentPercentage || 0,
  };
};
