import { useEffect } from 'react';
import { navigate } from 'gatsby';
import dayjs from 'dayjs';

import { NewHeddaClient } from '@/clients';

import { Countries, IsoCountryCodes, IsoLanguageCodes } from '@/config';

import { useI18n } from '@/state';

export const detectCountry = async (): Promise<IsoCountryCodes> => {
  const heddaClient = new NewHeddaClient();
  const res = await heddaClient.geolocation.lookup();
  return res.country;
};

export const redirectToUrl = (
  country: IsoCountryCodes,
  language: IsoLanguageCodes
) => {
  switch (country) {
    case `BE`:
      if (!Countries[`fr-BE`].isDisabled && language.includes(`fr`)) {
        return navigate(`/${Countries[`fr-BE`].path}`);
      } else if (!Countries[`nl-BE`].isDisabled && language.includes(`nl`)) {
        return navigate(`/${Countries[`nl-BE`].path}`);
      } else {
        return navigate(`/${Countries[`fr-BE`].path}`);
      }
    case `FR`:
      if (!Countries[`fr-FR`].isDisabled) {
        return navigate(`/${Countries[`fr-FR`].path}`);
      } else {
        return navigate(`/${Countries[`fr-BE`].path}`);
      }
    default:
      return navigate(`/${Countries[`fr-BE`].path}`);
  }
};

export const useDetectUrl = () => {
  const { setCountry } = useI18n();

  useEffect(() => {
    const { pathname } = window.location;

    if (
      !Countries[`fr-BE`].isDisabled &&
      pathname.startsWith(`/${Countries[`fr-BE`].path}`)
    ) {
      setCountry(Countries[`fr-BE`]);
      dayjs.locale(`fr`);
    } else if (
      !Countries[`nl-BE`].isDisabled &&
      pathname.startsWith(`/${Countries[`nl-BE`].path}`)
    ) {
      setCountry(Countries[`nl-BE`]);
      dayjs.locale(`nl`);
    } else if (
      !Countries[`fr-FR`].isDisabled &&
      pathname.startsWith(`/${Countries[`fr-FR`].path}`)
    ) {
      setCountry(Countries[`fr-FR`]);
      dayjs.locale(`fr`);
    } else {
      setCountry(Countries[`fr-BE`]);
      dayjs.locale(`fr`);
    }
  }, []);
};

export const useCheckUrl = () => {
  const { country, setSuggestedCountry, setInvalidCountry } = useI18n();

  useEffect(() => {
    if (country) {
      detectCountry().then((detectedCountry) => {
        if (country.iso !== detectedCountry) {
          setInvalidCountry(true);
          const suggestedCountry = Object.values(Countries).find(
            (value) => value.iso === detectedCountry
          );
          if (suggestedCountry) {
            setSuggestedCountry(suggestedCountry);
          }
        }
      });
    }
  }, [country]);
};
