import { forwardRef } from 'react';

import { _HelpQuestion } from '@/domain/entities';

import { Box, BoxProps, Heading, Text } from '@/components/atoms';

export interface Props extends BoxProps {
  question: _HelpQuestion;
}

export const TopQuestion = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { question, ...rest } = props;

    return (
      <Box
        ref={ref}
        as="article"
        borderLeftWidth="5px"
        borderLeftStyle="solid"
        borderLeftColor="#A770EF"
        pl={5}
        {...rest}
      >
        <Box mb={2}>
          <Heading>{question.heading}</Heading>
        </Box>
        <Box>
          <Text variant="3" color="gray.500" lineHeight="taller" noOfLines={4}>
            {question.text}
          </Text>
        </Box>
      </Box>
    );
  }
);
