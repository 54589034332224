import { forwardRef, MouseEvent } from 'react';
import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  useMultiStyleConfig,
  useTheme,
} from '@chakra-ui/react';

import { _Accordion } from '@/domain/entities';

import { Box, Heading, Icon, Text } from '@/components/atoms';

import { useGetUniqueKey } from '@/hooks';

export interface Props extends AccordionProps {
  gradientTitle?: boolean;
  gradient?: string;
  light?: boolean;
  accordions: _Accordion[];
  radius?: number;
  isLoading?: boolean;
  textColor?: string;
  onButtonClick?: (
    index: number,
    isExpanded: boolean,
    e: MouseEvent<HTMLButtonElement>
  ) => void;
}

export const RoundedAccordion = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const {
      accordions = [],
      gradient,
      gradientTitle = false,
      light = false,
      radius = 99,
      textColor = `#A2A1A7`,
      onButtonClick,
      ...rest
    } = props;
    const getUniqueKey = useGetUniqueKey();
    const styles = useMultiStyleConfig(`Accordion`, props);
    const theme = useTheme();

    const onClick = (
      index: number,
      isExpanded: boolean,
      e: MouseEvent<HTMLButtonElement>
    ) => {
      onButtonClick?.(index, isExpanded, e);
    };

    return (
      <ChakraAccordion allowToggle allowMultiple ref={ref} {...rest}>
        {accordions.map((accordion, key) => (
          <AccordionItem
            key={getUniqueKey(accordion)}
            borderColor="transparent"
            mb={4}
            _last={{ mb: 0 }}
          >
            {({ isExpanded }) => (
              <>
                <Box
                  borderRadius={radius}
                  bg={light ? `gray.75` : `#151516`}
                  _hover={{
                    bg: light ? `gray.200` : `#29292e`,
                  }}
                >
                  <AccordionButton
                    py={5}
                    borderRadius={radius}
                    __css={styles.button}
                    onClick={(e) => onClick(key, !isExpanded, e)}
                  >
                    <Heading
                      variant="h6"
                      ml={4}
                      mr="auto"
                      textColor={light ? `black` : `white`}
                      bg={
                        (light || gradientTitle) && isExpanded
                          ? gradient || theme.colors.grad1
                          : `none`
                      }
                      bgClip={
                        (light || gradientTitle) && isExpanded ? `text` : `none`
                      }
                    >
                      {accordion.heading}
                    </Heading>
                    <Box pr={4}>
                      {isExpanded ? (
                        <Icon color={light ? `black` : `white`} icon="minus" />
                      ) : (
                        <Icon color={light ? `black` : `white`} icon="plus" />
                      )}
                    </Box>
                  </AccordionButton>
                </Box>
                <AccordionPanel
                  textColor={light ? `black` : `white`}
                  w="80%"
                  ml={4}
                >
                  <Text lineHeight={8} variant="3" color={textColor}>
                    {accordion.text}
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </ChakraAccordion>
    );
  }
);
