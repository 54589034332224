import { useMemo } from 'react';
import AlgoliaSearch, { SearchClient } from 'algoliasearch/lite';

export const useAlgoliaClient = (): SearchClient => {
  return useMemo<SearchClient>(
    () =>
      AlgoliaSearch(
        process.env.GATSBY_ALGOLIA_APP_ID || ``,
        process.env.GATSBY_ALGOLIA_SEARCH_API_KEY || ``
      ),
    []
  );
};
