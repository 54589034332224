import { TrackingEvent, TrackingValue } from '@/config';

import { AnalyticsPlugin } from '@/types';

import { isBrowser } from '@/utils';

import { toValues } from './utils';

const defaultConfig = {
  assumesPageview: true,
};

const MetaPixelTrackingEvent: { [K in TrackingEvent]?: string } = {
  [TrackingEvent.ADD_TO_CART]: `AddToCart`,
  [TrackingEvent.ADD_TO_WISHLIST]: `AddToWishlist`,
  [TrackingEvent.BEGIN_CHECKOUT]: `InitiateCheckout`,
  [TrackingEvent.GENERIC]: `generic`,
  [TrackingEvent.LOGIN]: `login`,
  [TrackingEvent.PAGE_VIEW]: `PageView`,
  [TrackingEvent.REMOVE_FROM_CART]: `remove_from_cart`,
  [TrackingEvent.SEARCH]: `Search`,
  [TrackingEvent.SELECT_CONTENT]: `select_content`,
  [TrackingEvent.SELECT_ITEM]: `select_item`,
  [TrackingEvent.SIGNUP]: `CompleteRegistration`,
  [TrackingEvent.VIEW_ITEM]: `ViewContent`,
  [TrackingEvent.VIEW_ITEM_LIST]: `view_item_list`,
  [TrackingEvent.VIEW_SEARCH_RESULTS]: `view_search_results`,
  [TrackingEvent.VIEW_CART]: `view_cart`,
};

const MetaPixelTrackingValue: { [K in TrackingValue]?: string } = {
  [TrackingValue.AD_STORAGE]: `ad_storage`,
  [TrackingValue.ANALYTICS_STORAGE]: `analytics_storage`,
  [TrackingValue.CONTENT_NAME]: `name`,
  [TrackingValue.CONTENT_CATEGORY]: `content_category`,
  [TrackingValue.CONTENT_TYPE]: `content_type`,
  [TrackingValue.COUNTRY]: `country`,
  [TrackingValue.CURRENCY]: `currency`,
  [TrackingValue.EVENT_ACTION]: `event_action`,
  [TrackingValue.EVENT_CATEGORY]: `event_category`,
  [TrackingValue.EVENT_ID]: `event_id`,
  [TrackingValue.FEATURE_NAME]: `feature_name`,
  [TrackingValue.FUNCTIONAL_STORAGE]: `functional_storage`,
  [TrackingValue.INDEX]: `index`,
  [TrackingValue.ITEM_BRAND]: `item_brand`,
  [TrackingValue.ITEM_CATEGORY]: `item_category`,
  [TrackingValue.ITEM_CATEGORY_2]: `item_category2`,
  [TrackingValue.ITEM_ID]: `item_id`,
  [TrackingValue.ITEM_NAME]: `item_name`,
  [TrackingValue.ITEM_LIST_ID]: `item_list_id`,
  [TrackingValue.ITEM_LIST_NAME]: `item_list_name`,
  [TrackingValue.ITEMS]: `items`,
  [TrackingValue.METHOD]: `method`,
  [TrackingValue.NECESSARY_COOKIES]: `necessary_cookies`,
  [TrackingValue.PAGE_NAME]: `page_name`,
  [TrackingValue.PERFORMANCES_COOKIES]: `performance_cookies`,
  [TrackingValue.PLATFORM]: `platform`,
  [TrackingValue.PRICE]: `price`,
  [TrackingValue.QUANTITY]: `quantity`,
  [TrackingValue.SCROLL_PERCENTAGE]: `scroll_percentage`,
  [TrackingValue.SEARCH_TERM]: `search_term`,
  [TrackingValue.SOCIAL_MEDIA]: `social_media`,
  [TrackingValue.STEP_NAME]: `step_name`,
  [TrackingValue.VALUE]: `value`,
};

export const providerFacebookPixel = (): AnalyticsPlugin<
  typeof MetaPixelTrackingEvent
> => ({
  name: `facebook-pixel`,
  config: {
    ...defaultConfig,
  },
  track: ({ payload }) => {
    const eventName = MetaPixelTrackingEvent[payload.event];
    const values = toValues(payload.properties, MetaPixelTrackingValue);

    if (eventName) {
      fbq(`track`, eventName, { ...values });
    }
  },
  loaded: () => isBrowser && typeof fbq === `function`,
});
