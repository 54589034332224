import { useCallback } from 'react';

import { ShopifyAdminOrderClosePayload } from '@shopify/types';
import { useNewHeddaClient } from '@/clients';

export const useShopifyOrder = () => {
  const heddaClient = useNewHeddaClient();

  /**
   * Find customer
   */
  const findAllOrders = useCallback(async () => {
    return await heddaClient.order.find();
  }, []);

  /**
   * Close order by ID
   */
  const closeOrder = useCallback(
    async (id: string): Promise<ShopifyAdminOrderClosePayload> => {
      return await heddaClient.order.orderClose(id);
    },
    []
  );

  return {
    closeOrder,
    findAllOrders,
  };
};
