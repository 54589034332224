import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

export interface BannersState {
  cookies: boolean;
  addToCart: boolean;
}

export const bannersState = atom<BannersState>({
  key: `Banners`,
  default: {
    cookies: false,
    addToCart: false,
  },
});

export const useBannersState = () => {
  const [state, setState] = useRecoilState<BannersState>(bannersState);

  const setCookiesBannerVisible = useCallback((state: boolean) => {
    setState((S) => ({
      ...S,
      cookies: state,
    }));
  }, []);

  const setAddToCartBannerVisible = useCallback((state: boolean) => {
    setState((S) => ({
      ...S,
      addToCart: state,
    }));
  }, []);

  return {
    setCookiesBannerVisible,
    setAddToCartBannerVisible,
    ...state,
  };
};
