import { forwardRef, ReactNode } from 'react';
import { ImageDataLike } from 'gatsby-plugin-image';
import { useTheme } from '@chakra-ui/react';

import { _Link } from '@/domain/entities';

import {
  Box,
  BoxProps,
  Button,
  Conditional,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Tag,
  Text,
} from '@/components/atoms';

import { useIsMobile, useTranslation } from '@/hooks';

export interface Props extends BoxProps {
  image: ImageDataLike;
  buttonText: ReactNode;
  displayImage: ImageDataLike;
  alt: string;
  heading: ReactNode;
  featureKey: number;
  text: ReactNode;
  horizontal?: boolean;
  link?: _Link;
  onLinkClick?: () => void;
}

export const HomeImageCard = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const {
      image,
      buttonText,
      alt,
      displayImage,
      featureKey,
      heading,
      text,
      horizontal,
      link,
      onLinkClick,
      ...rest
    } = props;
    const mobile = useIsMobile();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
      <Box
        as={(props) => (
          <Link to={link?.path} onClick={onLinkClick} {...props} />
        )}
        width="100%"
        position="relative"
        zIndex={2}
        h={700}
        borderRadius={20}
        overflow="hidden"
        sx={
          mobile
            ? {}
            : {
                '&:hover': {
                  '.gatsby-image-wrapper': {
                    transform: `scale(1.1)`,
                  },
                  '.text': {
                    transform: `translateY(-40px)`,
                  },
                  '.tag': {
                    transform: `translateX(0px)`,
                  },
                },
              }
        }
        {...props}
      >
        <Box
          ref={ref}
          borderRadius={20}
          p={[8, 10]}
          h={[280, null, horizontal ? 350 : `100%`]}
          position="relative"
          overflow="hidden"
          zIndex={2}
          {...rest}
        >
          <Image
            image={image}
            alt={alt}
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            w="100%"
            h="100%"
            borderRadius={20}
            transition="transform .5s"
          />
          <Box
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            w="100%"
            h="100%"
            bg={
              // @ts-ignore
              image.gatsbyImageData !== null ? `rgba(0, 0, 0, .5)` : `gray.75`
            }
            borderRadius={20}
          />
          <Flex
            flexDirection="column"
            justifyContent={
              // eslint-disable-next-line no-nested-ternary
              featureKey === 0
                ? `center`
                : featureKey === 4
                ? `flex-start`
                : `flex-end`
            }
            h="100%"
            position="relative"
          >
            <Box
              className={featureKey > 1 && featureKey < 4 ? `text` : ``}
              // @ts-ignore
              textColor={image.gatsbyImageData !== null ? `white` : `black`}
              transition="transform ease-out .5s"
              height={featureKey === 1 ? [`100%`, `auto`] : `auto`}
            >
              <Box w="full">
                <Conditional isTrue={featureKey === 0}>
                  <Flex
                    mb={5}
                    w="full"
                    justifyContent="center"
                    textAlign="center"
                    alignItems="center"
                  >
                    <Heading
                      variant="h6"
                      bg={theme.colors.grad1}
                      bgClip="text"
                      color="transparent"
                      w="fit-content"
                    >
                      {t(`common.new`)}
                    </Heading>
                  </Flex>
                  <Box mb={1}>
                    <Heading as="h3" variant="h4" textAlign="center">
                      {heading}
                    </Heading>
                  </Box>
                  <Box mb={0}>
                    <Text
                      textAlign="center"
                      variant="3"
                      fontWeight="semibold"
                      color="gray.500"
                    >
                      {text}
                    </Text>
                  </Box>
                </Conditional>
              </Box>
              <Flex justifyContent="center">
                <Image
                  position={featureKey > 0 ? `absolute` : undefined}
                  image={displayImage}
                  alt={alt}
                  w={
                    // eslint-disable-next-line no-nested-ternary
                    featureKey > 1
                      ? [330, null, 440]
                      : featureKey === 1
                      ? 410
                      : [220, null, 440]
                  }
                  h={
                    // eslint-disable-next-line no-nested-ternary
                    featureKey > 1
                      ? [240, null, 310]
                      : featureKey === 1
                      ? 190
                      : [140, null, 280]
                  }
                  right={
                    // eslint-disable-next-line no-nested-ternary
                    featureKey === 4
                      ? -10
                      : featureKey === 1
                      ? [`-100px`, `-100px`, -40, null, -10]
                      : undefined
                  }
                  bottom={
                    // eslint-disable-next-line no-nested-ternary
                    featureKey === 4
                      ? `-80px`
                      : featureKey === 1
                      ? [10, null, 20]
                      : undefined
                  }
                  transition="transform .5s"
                />
              </Flex>
              <Conditional isTrue={featureKey !== 0}>
                <Box
                  mb={1}
                  position="relative"
                  bottom={0}
                  // top={featureKey === 1 ? 0 : `initial`}
                >
                  <Heading as="h3" variant="h4">
                    {heading}
                  </Heading>
                </Box>
                <Box mb={0} position="relative">
                  <Text variant="3" fontWeight="semibold">
                    {text}
                  </Text>
                </Box>
              </Conditional>
              <Conditional isTrue={featureKey < 2}>
                <Flex
                  mb={1}
                  position="absolute"
                  alignItems="center"
                  justifyContent="flex-end"
                  right={0}
                  bottom={-5}
                >
                  <Conditional isTrue={!mobile}>
                    <Text variant="3" fontWeight={600} color="black" pr={3}>
                      {buttonText}
                    </Text>
                    <Flex
                      as={(props) => (
                        <Button sx={{ h: 10, px: 0 }} {...props} />
                      )}
                      w="56px"
                      h="56px"
                      bg="white"
                      borderRadius="full"
                      boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
                      backdropFilter="blur 10px"
                      justifyContent="center"
                      alignItems="center"
                      mr={2}
                      onClick={onLinkClick}
                      _hover={{
                        '.icon': {
                          color: `white`,
                        },
                      }}
                    >
                      <Icon
                        className="icon"
                        icon="forward"
                        color="black"
                        size="lg"
                      />
                    </Flex>
                  </Conditional>
                </Flex>
              </Conditional>
            </Box>
            <Box
              className={featureKey > 1 ? `tag` : ``}
              transition="transform .5s"
              position="relative"
              transform="translateX(-200px)"
            >
              <Tag
                icon="chevron-double-right"
                rightToLeft
                // @ts-ignore
                color={image.gatsbyImageData !== null ? `white` : `black`}
                iconSize={12}
                labelProps={{
                  // @ts-ignore
                  color: image.gatsbyImageData !== null ? `white` : `black`,
                }}
                position="absolute"
                bottom={featureKey === 4 ? -10 : 0}
              >
                {link?.label}
              </Tag>
            </Box>
          </Flex>
        </Box>
      </Box>
    );
  }
);
