import { forwardRef } from 'react';
import { Collapse } from '@chakra-ui/react';

import { _Link } from '@/domain/entities';

import { Box, BoxProps, Container, Flex, Link } from '@/components/atoms';
import { TagHighlights } from '@/components/molecules';

import { useGetUniqueKey } from '@/hooks';

import { isActiveLink, isBrowser } from '@/utils';

export interface Props extends BoxProps {
  accountLink?: _Link;
  homeLink?: _Link;
  isNavOpen: boolean;
  navLinks: _Link[];
  hasHighlights?: boolean;
}

export const MobileNavigation = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const {
      accountLink,
      homeLink,
      isNavOpen,
      navLinks = [],
      hasHighlights = true,
      ...rest
    } = props;
    const getUniqueKey = useGetUniqueKey();
    const currentPath = isBrowser ? location.pathname : undefined;
    const isActive = isActiveLink(homeLink?.path || ``, currentPath || ``);

    return (
      <Box as="aside" position="relative" zIndex="docked" {...rest}>
        <Collapse in={isNavOpen}>
          <Box
            ref={ref}
            bg="white"
            position="fixed"
            top={0}
            right={0}
            bottom={0}
            left={0}
            w="100%"
            h="100%"
          >
            <Box h="100%" pt={[10, 60]} pb={10} textAlign="center">
              <Container h="100%">
                <Flex h="100%" flexDirection="column">
                  <Flex
                    height="100%"
                    flexDirection="column"
                    justifyContent="center"
                    pt={[10, 5, 5]}
                  >
                    <Box mb={[4, 10]}>
                      <Link
                        to={homeLink?.path || ``}
                        variant="nav"
                        className={isActive ? `active` : ``}
                      >
                        {homeLink?.label}
                      </Link>
                    </Box>
                    {navLinks.map((navLink, key) => {
                      const isLast = key === navLinks.length - 1;

                      return (
                        <Box
                          key={getUniqueKey(navLink)}
                          mb={isLast ? 0 : [3, 4, 10]}
                        >
                          <Link to={navLink.path} variant="nav" partiallyActive>
                            {navLink.label}
                          </Link>
                        </Box>
                      );
                    })}
                  </Flex>
                  {accountLink && (
                    <Box mt={[3, 5]} mb={[2, 10]}>
                      <Link to={accountLink.path} variant="nav" partiallyActive>
                        {accountLink.label}
                      </Link>
                    </Box>
                  )}
                  {hasHighlights && (
                    <Box>
                      <TagHighlights />
                    </Box>
                  )}
                </Flex>
              </Container>
            </Box>
          </Box>
        </Collapse>
      </Box>
    );
  }
);
