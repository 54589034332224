import { forwardRef, MouseEvent } from 'react';
import { useTheme } from '@chakra-ui/react';

import { TrackingContentType, TrackingEvent, TrackingValue } from '@/config';

import { _HelpCategory } from '@/domain/entities';

import {
  Box,
  BoxProps,
  Flex,
  Icon,
  Link,
  MotionBox,
  Text,
} from '@/components/atoms';

import { useTracking } from '@/hooks';

export interface Props extends BoxProps {
  helpCategory: _HelpCategory;
  isSelected?: boolean;
}

export const CategorySelector = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { helpCategory, isSelected, onClick, ...rest } = props;
    const theme = useTheme();
    const { track } = useTracking();

    const handleCategoryClick = (e: MouseEvent<HTMLDivElement>) => {
      onClick?.(e);
      track(TrackingEvent.SELECT_CONTENT, {
        [TrackingValue.CONTENT_TYPE]: TrackingContentType.HELP_CATEGORY,
        [TrackingValue.ITEM_ID]: helpCategory.id,
      });
    };

    return (
      <MotionBox
        animate={
          isSelected
            ? {
                background: theme.colors.grad1,
              }
            : {
                background: `transparent`,
              }
        }
        borderRadius={12}
        position="relative"
        w="100%"
        h="100%"
        p="2px"
      >
        <Flex
          mb="0.5px"
          ref={ref}
          as={(props) => (
            <Link
              sx={{
                _active: {
                  bg: `gray.100`,
                },
              }}
              to={helpCategory.link.path}
              {...props}
            />
          )}
          bg="gray.100"
          borderRadius={10}
          alignItems="center"
          px={6}
          py={4}
          {...rest}
          onClick={handleCategoryClick}
        >
          <Box
            mr={4}
            sx={{
              svg: {
                path: {
                  fill: isSelected ? `black` : `black`,
                },
              },
            }}
          >
            <svg width="0" height="0">
              <defs>
                <radialGradient
                  id="paint0_linear_3333_9365"
                  r="150%"
                  cx="30%"
                  cy="107%"
                >
                  <stop stopColor="#A770EF" />
                  <stop offset="0.5" stopColor="#CF8BF3" />
                  <stop offset="1" stopColor="#FDB99B" />
                </radialGradient>
              </defs>
            </svg>
            <Icon icon={helpCategory.icon} />
          </Box>
          <Box>
            <Text color="black" fontWeight="semibold">
              {helpCategory.heading}
            </Text>
          </Box>
        </Flex>
      </MotionBox>
    );
  }
);
