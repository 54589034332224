import { useEffect } from 'react';
import { ShopifyStorefrontCountryCode } from '@shopify/types';

import { LocalStorage } from '@/config';

import { useAuth } from '@/hooks';

import { useCart, useCustomer, useI18n } from '@/state';

export function Shopify(): JSX.Element {
  const { initializeAuth } = useAuth();
  const { cart, initializeCart, updateCartBuyerIdentity } = useCart();
  const { country } = useI18n();
  const { isLoggedIn } = useCustomer();

  useEffect(() => {
    initializeAuth().then(() => undefined);
  }, []);

  useEffect(() => {
    if (cart.id.length === 0) {
      initializeCart().then(() => undefined);
    }
  }, [cart]);

  useEffect(() => {
    const customerAccessToken = localStorage.getItem(
      LocalStorage.ShopifyCustomerAccessToken
    );
    if (!cart.buyerIdentity.customer?.id) {
      if (cart.id.length > 0 && customerAccessToken && country) {
        updateCartBuyerIdentity(cart.id, {
          customerAccessToken,
          countryCode: country.iso as ShopifyStorefrontCountryCode,
        }).then(() => undefined);
      }
    }
  }, [cart, country, isLoggedIn]);

  return <></>;
}
