import { useCallback } from 'react';
import { useAnalytics } from 'use-analytics';

import { TrackingEvent, TrackingValue } from '@/config';

export const useTracking = () => {
  const { page: analyticsPage, track: analyticsTrack } = useAnalytics();

  const page = useCallback(
    (eventName: TrackingEvent) => {
      analyticsPage(eventName);
    },
    [analyticsPage]
  );

  const track = useCallback(
    (
      eventName: TrackingEvent | string,
      payload?: { [K in TrackingValue]?: unknown }
    ) => {
      analyticsTrack(eventName, payload);
    },
    [analyticsTrack]
  );

  return {
    page,
    track,
  };
};
