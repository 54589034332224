import {
  GatsbyPrismicBlogPostEdge,
  GatsbyPrismicBlogPostGroupConnection,
  GatsbyPrismicHelpQuestionEdge,
  GatsbyPrismicHelpQuestionGroupConnection,
  GatsbyPrismicProductEdge,
  GatsbyPrismicProductGroupConnection,
} from '@/domain/entities';

import { SearchIndexName } from '../config/SearchIndexName';
import { getSearchIndexName } from './getSearchIndexName';

const blogPostQuery = `
{
    blogPost: allPrismicBlogPost {
    edges {
      node {
        id
        lang
        internal {
          contentDigest
        }
        data {
          title {
            text
          }
          intro {
            text
          }
          sub_category {
            uid
          }
          category {
            uid
          }
          body {
            text
          }
        }
      }
    }
  }
}
`;

const helpQuestionQuery = `
{
    helpQuestion : allPrismicHelpQuestion {
    edges {
      node {
        id
        lang
        internal {
          contentDigest
        }
        data {
          text {
            richText
          }
          heading {
            text
          }
        }
      }
    }
  }
}
`;

const productQuery = `
{
    product: allPrismicProduct {
    edges {
      node {
        id
        lang
        internal {
          contentDigest
        }
        data {
          name
          hero_description {
            text
          }
          hero_intro {
            text
          }
        }
      }
    }
  }
}
`;

const formatBlogPostQuery = (edge: GatsbyPrismicBlogPostEdge) => {
  const { id, lang, data } = edge.node;
  return {
    category: data.category?.uid,
    sub_category: data.sub_category?.uid,
    title: data.title?.text,
    intro: data.intro?.text,
    body: data.body?.text,
    internal: {
      contentDigest: edge.node.internal.contentDigest,
    },
    id,
    lang,
  };
};

const formatHelpQuestionQuery = (edge: GatsbyPrismicHelpQuestionEdge) => {
  const { id, lang, data } = edge.node;
  return {
    name: data.heading?.text,
    content: data.text?.richText,
    internal: {
      contentDigest: edge.node.internal.contentDigest,
    },
    id,
    lang,
  };
};

const formatProductQuery = (edge: GatsbyPrismicProductEdge) => {
  const { id, lang, data } = edge.node;
  return {
    name: data.name,
    intro: data.hero_intro?.text,
    description: data.hero_description?.text,
    internal: {
      contentDigest: edge.node.internal.contentDigest,
    },
    id,
    lang,
  };
};

export const algoliaQuery = [
  {
    query: blogPostQuery,
    transformer: ({
      data,
    }: {
      data: Record<`blogPost`, GatsbyPrismicBlogPostGroupConnection>;
    }) => data.blogPost.edges.map((edge) => formatBlogPostQuery(edge)),
    indexName: getSearchIndexName(SearchIndexName.BlogPost),
    matchFields: [
      `title`,
      `category`,
      `sub_category`,
      `intro`,
      `content`,
      `internal.contentDigest`,
    ],
  },
  {
    query: helpQuestionQuery,
    transformer: ({
      data,
    }: {
      data: Record<`helpQuestion`, GatsbyPrismicHelpQuestionGroupConnection>;
    }) => data.helpQuestion.edges.map((edge) => formatHelpQuestionQuery(edge)),
    indexName: getSearchIndexName(SearchIndexName.HelpQuestion),
    matchFields: [`name`, `content`, `internal.contentDigest`],
  },
  {
    query: productQuery,
    transformer: ({
      data,
    }: {
      data: Record<`product`, GatsbyPrismicProductGroupConnection>;
    }) => data.product.edges.map((edge) => formatProductQuery(edge)),
    indexName: getSearchIndexName(SearchIndexName.Product),
    matchFields: [`name`, `intro`, `description`, `internal.contentDigest`],
  },
];
