import { useEffect, useState } from 'react';

import { LocalStorage } from '@/config';

import { _Link } from '@/domain/entities';

import {
  Box,
  Button,
  Conditional,
  Flex,
  Icon,
  IconButton,
  MotionBox,
  Text,
} from '@/components/atoms';
import {
  CookiesConsentBanner,
  CookiesConsentChoice,
} from '@/components/molecules';

import { useTracking, useTranslation } from '@/hooks';

import { useBannersState } from '@/state';

import { getTrackingValidity, isTrackingPermissionGranted } from '@/utils';

export interface Props {
  legal: _Link;
}

export function CookiesConsent(props: Props): JSX.Element {
  const { legal, ...rest } = props;
  const { addToCart, setCookiesBannerVisible } = useBannersState();
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isPerformanceChecked, setIsPerformanceChecked] =
    useState<boolean>(false);
  const [isAnnouncesChecked, setIsAnnouncesChecked] = useState<boolean>(false);
  const { track } = useTracking();
  const { t } = useTranslation();

  const variants = {
    visible: {
      height: 360,
      transition: {
        height: {
          duration: 0.3,
        },
      },
    },
    hidden: {
      height: 144,
      transition: {
        height: {
          duration: 0.3,
        },
      },
    },
  };

  const choicesVariants = {
    visible: {
      opacity: 1,
      height: 360,
      display: `block`,
      transition: {
        opacity: {
          delay: 0.2,
          duration: 0.2,
        },
        height: {
          duration: 0.3,
        },
      },
    },
    hidden: {
      opacity: 0,
      height: 144,
      display: `none`,
      transition: {
        opacity: {
          duration: 0.1,
        },
        height: {
          duration: 0.3,
        },
      },
    },
  };

  const onChooseCookies = () => {
    track(`choose-parameter-cookies-button`);
    setIsSelected(true);
  };

  const onAcceptAllCookies = () => {
    localStorage.setItem(LocalStorage.CookiesBannerIsHidden, `true`);
    setIsVisible(false);
    setCookiesBannerVisible(false);
    track(`all-accepted-cookies-button`);
    document.cookie = `ad_storage=granted; secure; expires=${getTrackingValidity()}`;
    document.cookie = `analytics_storage=granted; secure; expires=${getTrackingValidity()}`;
  };

  const onBackClick = () => {
    setIsSelected(false);
    setIsAllChecked(false);
    setIsPerformanceChecked(false);
    setIsAnnouncesChecked(false);
  };

  const onValidateParameters = () => {
    track(`validate-permissions-cookies-button`);
    document.cookie = `ad_storage=${isTrackingPermissionGranted(
      isPerformanceChecked
    )}; secure; expires=${getTrackingValidity()}`;
    document.cookie = `analytics_storage=${isTrackingPermissionGranted(
      isAnnouncesChecked
    )}; secure; expires=${getTrackingValidity()}`;
    localStorage.setItem(LocalStorage.CookiesBannerIsHidden, `true`);
    setIsVisible(false);
    setCookiesBannerVisible(false);
  };

  const onDenyCookies = () => {
    track(`no-thanks-permissions-cookies-button`);
    document.cookie = `ad_storage=${isTrackingPermissionGranted(
      false
    )}; secure; expires=${getTrackingValidity()}`;
    document.cookie = `analytics_storage=${isTrackingPermissionGranted(
      false
    )}; secure; expires=${getTrackingValidity()}`;
    localStorage.setItem(LocalStorage.CookiesBannerIsHidden, `true`);
    setIsVisible(false);
    setCookiesBannerVisible(false);
  };

  useEffect(() => {
    const isHidden = localStorage.getItem(LocalStorage.CookiesBannerIsHidden);
    setIsVisible(isHidden === null || isHidden === `false`);
    setCookiesBannerVisible(isHidden === null || isHidden === `false`);
  }, []);

  const cookieFooter = (): JSX.Element => {
    return (
      <Flex
        alignItems="center"
        bg="rgba(255, 255, 255, 0.25)"
        backdropFilter="blur(10px)"
        borderBottomRadius={16}
        position="relative"
        willChange="backdrop-filter"
        p={[2, 5, null, 5]}
        mt={[5, 0, null, 0]}
        flexDir={[`column`, `row`, null, `row`]}
      >
        <Button
          mr={2}
          variant="cookies"
          fontSize={14}
          fontWeight={150}
          w={230}
          onClick={() => {
            if (isSelected) {
              onValidateParameters();
            } else {
              onAcceptAllCookies();
            }
          }}
          {...rest}
        >
          {isSelected
            ? t(`cookiesBanner.validateParameters`)
            : t(`cookiesBanner.accept`)}
        </Button>
        <Button
          onClick={() => {
            if (isSelected) {
              onDenyCookies();
            } else {
              onChooseCookies();
              setIsSelected(true);
            }
          }}
          pl={5}
          px={0}
          bg="transparent"
          textColor="gray.700"
          variant="cookies"
          fontSize={14}
          textDecoration="underline"
          data-test="accept-cookies"
          _hover={{ textDecoration: `none` }}
        >
          {isSelected
            ? t(`cookiesBanner.declineParameters`)
            : t(`cookiesBanner.choose`)}
        </Button>
      </Flex>
    );
  };

  const cookieHeader = (): JSX.Element => {
    return (
      <Box
        borderBottomWidth={1}
        borderBottomColor="gray.50"
        borderBottomStyle="solid"
        position="relative"
        overflow="hidden"
        pt={5}
      >
        <Flex alignItems="center" fontSize={20} ml={5} mb={5}>
          {isSelected ? (
            <IconButton
              _hover={{ bg: `red` }}
              variant="noBorders"
              icon="arrow-left_solid"
              onClick={onBackClick}
              aria-label={t(`common.back`)}
            />
          ) : (
            <Icon icon="cookie" />
          )}
          <Text variant="1" fontWeight="bold" pl={3}>
            {t(`cookiesBanner.cookies`)}
          </Text>
        </Flex>
      </Box>
    );
  };

  return (
    <Conditional isTrue={isVisible}>
      <MotionBox
        position="fixed"
        zIndex="sticky"
        left={[1, 8]}
        bottom={addToCart ? 10 : 5}
        bg="rgba(255, 255, 255, 0.25)"
        backdropFilter="blur(10px)"
        willChange="backdrop-filter"
        borderBottomRadius={16}
        ml={[1, 0]}
        maxW={430}
        borderRadius={16}
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.25)"
        {...rest}
      >
        {cookieHeader()}
        <MotionBox
          position="relative"
          initial="hidden"
          animate={isSelected ? `visible` : `hidden`}
          variants={variants}
        >
          <MotionBox animate={isSelected ? { opacity: 0 } : { opacity: 1 }}>
            <CookiesConsentBanner
              legalLink={legal}
              setIsVisible={setIsVisible}
              {...props}
            />
          </MotionBox>
          <MotionBox
            initial="hidden"
            animate={isSelected ? `visible` : `hidden`}
            variants={choicesVariants}
          >
            <CookiesConsentChoice
              setIsVisible={setIsVisible}
              setIsAnnouncesChecked={setIsAnnouncesChecked}
              setIsPerformanceChecked={setIsPerformanceChecked}
              setIsAllChecked={setIsAllChecked}
              isAnnouncesChecked={isAnnouncesChecked}
              isPerformanceChecked={isPerformanceChecked}
              isAllChecked={isAllChecked}
            />
          </MotionBox>
        </MotionBox>
        {cookieFooter()}
      </MotionBox>
    </Conditional>
  );
}
