import { Currencies, Currency } from '@/config';

import { toNumber } from '@/utils/toNumber';

const financial = (value: number | string) => {
  return toNumber(value).toFixed(2);
};

const fixedFinancial = (value: number | string) => {
  return toNumber(value).toFixed(0);
};

export const toPrice = (
  price: string | number | undefined | null,
  currency?: Currencies
) => {
  if (!price) {
    return `0 €`;
  }

  if (currency) {
    return `${financial(price)} ${Currency[currency]}`;
  } else {
    return `${financial(price)} €`;
  }
};

export const toFixedPrice = (
  price: string | number | undefined | null,
  currency?: Currencies
) => {
  if (!price) {
    return `0 €`;
  }

  if (currency) {
    return `${fixedFinancial(price)} ${Currency[currency]}`;
  } else {
    return `${fixedFinancial(price)} €`;
  }
};
