import { NewHeddaClient } from '@/clients';

import { useI18n } from '@/state';

export const useSubscribeToNewsletter = () => {
  const { country } = useI18n();

  const subscribeToNewsletter = async (email: string): Promise<string> => {
    const heddaClient = new NewHeddaClient();
    return await heddaClient.marketing.subscribe(email, country?.lang || `fr`);
  };

  return { subscribeToNewsletter };
};
