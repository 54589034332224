import { Dispatch, forwardRef, SetStateAction } from 'react';
import { useNumberInput } from '@chakra-ui/react';

import { Box, Button, Flex, FlexProps, Input } from '@/components/atoms';

export type NumberInputVariant = `quantity`;

export interface Props extends FlexProps {
  setQuantity: Dispatch<SetStateAction<number>>;
  quantity: number;
}

export const NumberInputV2 = forwardRef<HTMLInputElement, Props>(
  (props, ref): JSX.Element => {
    const { quantity, setQuantity } = props;
    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
      useNumberInput({
        defaultValue: quantity,
        min: 1,
        max: 99,
        onChange(_valueAsString: string, valueAsNumber: number) {
          setQuantity(valueAsNumber);
        },
      });
    const inc = getIncrementButtonProps();
    const dec = getDecrementButtonProps();
    const input = getInputProps();

    return (
      <Flex
        ref={ref}
        h="100%"
        w="100%"
        borderRadius={99}
        sx={{
          bg: `black`,
          border: `2px solid black`,
          textColor: `black`,
          fontWeight: `600`,
        }}
        alignItems="center"
        overflow="hidden"
      >
        <Box
          bg="white"
          transition="0.2s"
          _hover={{ bg: quantity === 1 ? `white` : `black` }}
        >
          <Button
            borderLeftRadius={99}
            borderRightRadius={0}
            leftIcon="minus"
            py={5}
            disabled={quantity - 1 <= 0}
            sx={{
              bg: `transparent`,
              color: `black`,
              w: `fit-content`,
              h: `fit-content`,
              px: 0,
              pl: 2,
            }}
            {...dec}
          />
        </Box>
        <Box height="100%" bg="white">
          <Input
            sx={{
              border: `none`,
              alignItems: `center`,
              textAlign: `center`,
              px: 0,
              height: `56px`,
              width: `30px`,
            }}
            defaultValue={quantity}
            value={quantity}
            focusBorderColor="transparent"
            {...input}
          />
        </Box>
        <Box
          bg="white"
          transition="0.2s"
          _hover={{ bg: quantity === 99 ? `white` : `black` }}
        >
          <Button
            rightIcon="plus"
            borderRightRadius={99}
            borderLeftRadius={0}
            py={5}
            disabled={quantity + 1 > 99}
            sx={{
              bg: `transparent`,
              color: `black`,
              w: `fit-content`,
              h: `fit-content`,
              px: 0,
              pr: 2,
            }}
            {...inc}
          />
        </Box>
      </Flex>
    );
  }
);
