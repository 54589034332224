import { forwardRef } from 'react';
import { motion } from 'framer-motion';

import { Box, BoxProps } from '@/components/atoms';

export interface Props extends BoxProps {
  width: number | (null | number | string)[] | undefined;
  height: number | string | (null | number | string)[] | undefined;
  radius?: number;
  strokeWidth?: number;
  variant?: boolean;
}

export const ImageBorderFilled = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const {
      width,
      height,
      radius,
      strokeWidth = 5,
      variant = false,
      ...rest
    } = props;

    return (
      <Box
        position="absolute"
        transition="all .3s"
        width={width}
        height={height}
        ref={ref}
        {...rest}
      >
        <motion.svg
          fill="url(#paint0_linear_2947_10830)"
          width="100%"
          height="100%"
        >
          <motion.rect
            x={5}
            y={5}
            width="90%"
            height="90%"
            rx={radius || 20}
            stroke={
              variant
                ? `url(#paint0_linear_3139_9656)`
                : `url(#paint0_linear_2947_10830)`
            }
            strokeWidth={strokeWidth}
          />
          <defs>
            <linearGradient
              id="paint0_linear_2947_10830"
              x1={-124.624}
              y1={183.927}
              x2={275.376}
              y2={183.927}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#A770EF" />
              <stop offset={0.5} stopColor="#CF8BF3" />
              <stop offset={1} stopColor="#FDB99B" />
            </linearGradient>
            <linearGradient
              id="paint0_linear_3139_9656"
              x1="0"
              y1="0"
              x2="339.376"
              y2="319.413"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFA8A8" />
              <stop offset="1" stopColor="#FCFF00" />
            </linearGradient>
          </defs>
        </motion.svg>
      </Box>
    );
  }
);
