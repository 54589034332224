import {
  _Image,
  GatsbyFile,
  GatsbyImgixFixed,
  GatsbyImgixFluid,
  GatsbyPrismicImageDimensionsType,
  Scalars,
} from '@/domain/entities';

import { Maybe } from '@/types';

interface GatsbyPrismicImage {
  alt?: Maybe<Scalars[`String`]>;
  copyright?: Maybe<Scalars[`String`]>;
  dimensions?: Maybe<GatsbyPrismicImageDimensionsType>;
  fixed?: Maybe<GatsbyImgixFixed>;
  fluid?: Maybe<GatsbyImgixFluid>;
  gatsbyImageData?: Maybe<Scalars[`JSON`]>;
  localFile?: Maybe<GatsbyFile>;
  url?: Maybe<Scalars[`String`]>;
}

export const toImage = (image?: GatsbyPrismicImage | null): _Image => ({
  ...image,
  parent: ``,
  children: [],
  internal: {
    type: ``,
    contentDigest: ``,
    owner: ``,
  },
  id: ``,
  alt: image?.alt || ``,
});
