import { isNavigator } from '@/utils';

export const useIsIos = () => {
  if (isNavigator) {
    return (
      navigator.userAgent.indexOf(`Mac`) !== -1 ||
      navigator.userAgent.indexOf(`IOS`) !== -1
    );
  }
  return false;
};
