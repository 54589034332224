import { graphql } from '@/utils';

export const productQuery = graphql`
  query ProductQuery($id: ID!) {
    product(id: $id) {
      availableForSale
      variants(first: 1) {
        edges {
          node {
            priceV2 {
              amount
            }
            compareAtPriceV2 {
              amount
            }
          }
        }
      }
    }
  }
`;
