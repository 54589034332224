import { forwardRef } from 'react';
import { Link } from '@chakra-ui/react';

import { Languages } from '@/config';

import { Platform } from '@/domain/types';

import {
  AppStoreBadge,
  Box,
  Flex,
  FlexProps,
  GooglePlayBadge,
} from '@/components/atoms';

export interface Props extends FlexProps {
  lang?: Languages;
  onStoreBadgeClick?: (platform: Platform) => void;
}

export const StoreBadges = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { lang: _lang, onStoreBadgeClick, ...rest } = props;
    const language = _lang || `fr`;

    return (
      <Flex
        ref={ref}
        flexDirection={[`column`, `row`]}
        alignItems="center"
        {...rest}
      >
        <Box
          mr={[0.5, 2]}
          mb={[2, 0, 0, 1, 0]}
          onClick={() => onStoreBadgeClick?.(`apple`)}
        >
          <Link
            href={`https://apps.apple.com/be/app/hedda/id1575232503?l=${language}`}
            target="_blank"
            rel="noopener noreferrer"
            title="App Store"
            display="inline-block"
          >
            <AppStoreBadge />
          </Link>
        </Box>
        <Box
          onClick={() => onStoreBadgeClick?.(`android`)}
          mb={[0, 0, 0, 1, 0]}
        >
          <Link
            href={`https://play.google.com/store/apps/details?id=app.hedda.mobile&hl=${language}`}
            target="_blank"
            rel="noopener noreferrer"
            title="Google Play"
            display="inline-block"
          >
            <GooglePlayBadge />
          </Link>
        </Box>
      </Flex>
    );
  }
);
