import { forwardRef, MouseEventHandler, useMemo } from 'react';

import { _Link } from '@/domain/entities';

import { Box, Flex, FlexProps, Link } from '@/components/atoms';

import { useGetUniqueKey } from '@/hooks';

import { isActiveLink, isBrowser } from '@/utils';

export interface Props extends FlexProps {
  navLinks: _Link[];
  onLinkClick: MouseEventHandler;
}

export const Navigation = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { navLinks = [], onLinkClick, ...rest } = props;
    const getUniqueKey = useGetUniqueKey();
    const currentPath = isBrowser ? location.pathname : undefined;

    const _navLinks = useMemo(() => {
      return navLinks;
    }, [navLinks]);

    return (
      <Flex
        ref={ref}
        as="nav"
        display={[`none`, null, null, `flex`]}
        alignItems="center"
        {...rest}
      >
        {_navLinks.map((navLink, key) => {
          const isLast = key === _navLinks.length - 1;

          return (
            <Flex
              key={getUniqueKey(navLink)}
              alignItems="center"
              sx={{
                '.activeHeader': {
                  bg: `linear-gradient(90deg, #A770EF 0%, #CF8BF3 50%, #FDB99B 100%)`,
                  color: `transparent`,
                  bgClip: `text`,
                },
              }}
            >
              <Link
                className={
                  isActiveLink(navLink.path, currentPath) ? `activeHeader` : ``
                }
                key={navLink.path}
                to={navLink.path}
                variant="nav"
                partiallyActive
                onClick={navLink.label === `Hedda` ? onLinkClick : () => {}}
              >
                {navLink.label}
              </Link>
              {!isLast && <Box px={5}>·</Box>}
            </Flex>
          );
        })}
      </Flex>
    );
  }
);
