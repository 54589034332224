import { cartFragment } from '@/graphql';

import { graphql } from '@/utils';

export const cartLinesUpdateMutation = graphql`
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`;
