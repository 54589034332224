import { Analytics } from 'analytics';

import { providerFacebookPixel } from './providerFacebookPixel';
import { providerGoogleTagManager } from './providerGoogleTagManager';
// import { providerTikTokPixel } from './providerTiktokPixel';

export const analytics = Analytics({
  app: `Hedda Health`,
  plugins: [
    providerGoogleTagManager(),
    providerFacebookPixel(),
    // providerTikTokPixel(),
  ],
});
