import { forwardRef, ReactElement } from 'react';
import {
  IconButton as ChakraIconButton,
  IconButtonProps,
  useBoolean,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { Icons } from '@/config';

import { Icon } from '../Icon';

export type IconButtonSize = `sm` | `md` | `lg`;
export type IconButtonVariant =
  | `black`
  | `noBorders`
  | `numberInput`
  | `carousel`;

export interface Props extends Omit<IconButtonProps, `icon`> {
  icon: Icons | ReactElement;
  size?: IconButtonSize;
  variant?: IconButtonVariant;
}

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  (props, ref): JSX.Element => {
    const { children, icon, ...rest } = props;
    const styles = useMultiStyleConfig(`IconButton`, props);
    const [hover, setHover] = useBoolean(false);

    const renderIcon = () => {
      if (typeof icon === `string`) {
        const color = hover
          ? // @ts-ignore
            styles.icon?._hover?.color || styles.icon?.color
          : styles.icon?.color;

        return (
          <Icon
            icon={icon as Icons}
            color={color as string}
            style={{
              fontSize: styles.icon?.width as number,
            }}
          />
        );
      } else {
        return icon;
      }
    };

    return (
      <ChakraIconButton
        ref={ref}
        {...rest}
        title={props[`aria-label`]}
        sx={styles.button}
        icon={renderIcon()}
        onMouseEnter={setHover.on}
        onMouseLeave={setHover.off}
      >
        {children}
      </ChakraIconButton>
    );
  }
);
