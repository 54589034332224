import { Maybe, PageVariant } from '@/types';

import { createLinkPath } from '@/utils/createLink';

interface GeneratePageVariantInput {
  link?: string;
  lang?: Maybe<string>;
  uid?: Maybe<string>;
  type?: Maybe<string>;
}

export const generatePageVariant = (
  input: GeneratePageVariantInput
): PageVariant => {
  const lang = input.lang || `fr-be`;
  if (input.link) {
    return {
      lang,
      path: createLinkPath([input.link]),
    };
  }

  const link = createLinkPath([input.lang || ``, input.uid || ``]);

  return {
    lang,
    path: link || ``,
  };
};

export const generatePageVariants = (
  input: GeneratePageVariantInput[]
): PageVariant[] => {
  if (!input) {
    return [];
  }

  return input.map<PageVariant>((item) => generatePageVariant(item));
};
