import { Icons } from '@/config';

import {
  _HelpCategory,
  _HelpCategoryPage,
  _HelpPage,
  _HelpQuestion,
  GatsbyPrismicHelpCategory,
  GatsbyPrismicHelpCategoryConnection,
  GatsbyPrismicHelpCategoryPage,
  GatsbyPrismicHelpPage,
  GatsbyPrismicHelpQuestion,
  GatsbyPrismicHelpQuestionConnection,
} from '@/domain/entities';
import { toImage } from '@/domain/mappings/image';
import { toReactNode } from '@/domain/mappings/react';

import { createLinkPath, toArray } from '@/utils';

export const toHelpPage = (
  helpPage?: GatsbyPrismicHelpPage,
  helpQuestions?: GatsbyPrismicHelpQuestionConnection,
  topQuestions?: GatsbyPrismicHelpQuestionConnection,
  categoryQuestionText?: string
): _HelpPage => {
  const helpCategories = helpPage?.data.categories_list?.map((category) =>
    toHelpCategory(
      category?.help_category?.document as GatsbyPrismicHelpCategory,
      toArray(helpQuestions)
        .filter(
          (helpQuestion) =>
            helpQuestion.data.category?.id ===
            category?.help_category?.document?.prismicId
        )
        .reduce((acc) => acc + 1, 0),
      categoryQuestionText,
      helpPage.uid
    )
  );

  const questions =
    topQuestions &&
    toArray(topQuestions)?.map((question) => toHelpQuestion(question));

  return {
    hero: {
      headingDesktop: toReactNode(helpPage?.data?.hero_title?.richText),
      headingMobile: helpPage?.data.hero_title?.text || ``,
      placeholder: helpPage?.data.hero_placeholder || ``,
      image: toImage(helpPage?.data.hero_image),
    },
    categories: {
      heading: helpPage?.data.categories_title?.text || ``,
      categories: helpCategories || [],
    },
    topQuestions: {
      heading: helpPage?.data.top_questions_title?.text || ``,
      questions: questions || [],
    },
  };
};

export const toHelpCategory = (
  helpCategory?: GatsbyPrismicHelpCategory,
  helpQuestionsTotal?: number,
  helpQuestionsTotalText?: string,
  helpPageUid?: string
): _HelpCategory => ({
  id: helpCategory?.id || ``,
  slug: helpCategory?.uid || ``,
  icon: helpCategory?.data.icon as Icons,
  heading: helpCategory?.data.name || ``,
  text: `${helpQuestionsTotal} ${helpQuestionsTotalText}`,
  lang: helpCategory?.lang || ``,
  link: {
    path: createLinkPath([helpCategory?.lang, helpPageUid, helpCategory?.uid]),
    label: helpCategory?.data.name || undefined,
  },
});

export const toHelpQuestion = (
  helpQuestion?: GatsbyPrismicHelpQuestion
): _HelpQuestion => ({
  id: helpQuestion?.id || ``,
  category: toHelpCategory(
    helpQuestion?.data.category?.document as GatsbyPrismicHelpCategory
  ),
  heading: helpQuestion?.data.heading?.text || ``,
  text: toReactNode(helpQuestion?.data.text?.richText) || ``,
});

export const toHelpCategoryPage = (
  helpCategory?: GatsbyPrismicHelpCategory,
  helpQuestions?: GatsbyPrismicHelpQuestionConnection,
  helpCategoryPage?: GatsbyPrismicHelpCategoryPage,
  helpCategories?: GatsbyPrismicHelpCategoryConnection,
  helpPageUid?: string,
  headingQuestionText?: string
): _HelpCategoryPage => ({
  hero: toHelpCategory(
    helpCategory,
    toArray(helpQuestions).length,
    headingQuestionText
  ),
  sidebar: {
    heading: helpCategoryPage?.data.sidebar_categories || ``,
    notFound: helpCategoryPage?.data.sidebar_not_found || ``,
    email: toReactNode(helpCategoryPage?.data.sidebar_email?.richText),
    categories: toArray(helpCategories)?.map((helpCategory) =>
      toHelpCategory(helpCategory, undefined, undefined, helpPageUid)
    ),
  },
  questions: toArray(helpQuestions)?.map((question) =>
    toHelpQuestion(question)
  ),
});
