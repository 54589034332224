import { GraphQLClient } from '@/clients';

import { StoreRepository } from '@/domain/repositories';

export abstract class ShopifyRepository implements StoreRepository {
  client: GraphQLClient;

  constructor(client: GraphQLClient) {
    this.client = client;
  }
}
