import { forwardRef, MouseEvent } from 'react';

import {
  TrackingContentType,
  TrackingEvent,
  TrackingInfo,
  TrackingValue,
} from '@/config';

import { _Product } from '@/domain/entities';

import {
  Badge,
  Box,
  BoxProps,
  Conditional,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from '@/components/atoms';

import { useTracking, useTranslation } from '@/hooks';

import { toTrackingItem } from '@/tracking';

import { toPrice } from '@/utils';

export interface Props extends BoxProps {
  product: _Product;
  trackingInfo?: TrackingInfo;
  index?: number;
}

export const ProductCard = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { onClick, product, trackingInfo, index, ...rest } = props;
    const { t } = useTranslation();
    const { track } = useTracking();

    const handleCardClick = (e: MouseEvent<HTMLDivElement>) => {
      onClick?.(e);

      track(TrackingEvent.SELECT_CONTENT, {
        [TrackingValue.CONTENT_TYPE]: TrackingContentType.PRODUCT,
        [TrackingValue.ITEM_ID]: product.id,
      });

      track(TrackingEvent.SELECT_ITEM, {
        [TrackingValue.ITEM_LIST_ID]: trackingInfo?.itemListId,
        [TrackingValue.ITEM_LIST_NAME]: trackingInfo?.itemListName,
        [TrackingValue.ITEMS]: [
          toTrackingItem.fromProduct({
            item: product,
            itemListId: trackingInfo?.itemListId,
            itemListName: trackingInfo?.itemListName,
            position: index,
          }),
        ],
      });
    };

    return (
      <Box
        ref={ref}
        as={(props) => (
          <Link
            title={product.name}
            to={product.link.path}
            style={{ textDecoration: `none` }}
            {...props}
          />
        )}
        display="block"
        maxW={360}
        mx="auto"
        textAlign="center"
        {...rest}
        onClick={handleCardClick}
      >
        <Flex
          bg="gray.75"
          borderRadius={20}
          justifyContent="center"
          pt={5}
          h="400px"
          pb={4}
          mb={2}
          position="relative"
        >
          {product.hasFreeShipping && (
            <Box position="absolute" top={4} left={4}>
              <Badge bg="teal.100" color="teal.900" borderRadius="md">
                {t(`productPage.freeShippingShort`)}
              </Badge>
            </Box>
          )}
          <Box>
            <Box>
              <Image image={product.image} alt={product.image.alt} w={300} />
            </Box>
            <Box textAlign="center">
              <Conditional isTrue={product.oldPrice !== product.price}>
                <Heading
                  variant="h6"
                  textDecoration="line-through"
                  textDecorationThickness="2px"
                >
                  {toPrice(product.oldPrice)}
                </Heading>
              </Conditional>
              <Heading variant="h5">{toPrice(product.price)}</Heading>
            </Box>
          </Box>
        </Flex>
        <Box>
          <Box mb={1}>
            <Heading variant="h5">{product.name}</Heading>
          </Box>
          <Box mb={1}>
            <Text variant="3" color="gray.500">
              {product.capacity}
              {` `}
              {t(`ProductType.${product.type}`)}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }
);
