import {
  ShopifyStorefrontMutationCustomerAccessTokenCreateArgs,
  ShopifyStorefrontMutationCustomerAccessTokenRenewArgs,
} from '@shopify/types';
import { _Customer, ShopifyAccessToken } from '@/domain/entities';

import { HeddaRepository } from '@/repositories';

export class NewHeddaCustomerRepository extends HeddaRepository {
  async findOne(): Promise<_Customer | undefined> {
    return await this.client.get(`${this.url}/customer`);
  }

  async updateCustomer(customer: {
    hasAcceptedMarketing?: boolean;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
  }): Promise<_Customer | undefined> {
    return await this.client.post(`${this.url}/customer/update`, {
      body: {
        ...customer,
      },
    });
  }

  async accessTokenCreate(
    input: ShopifyStorefrontMutationCustomerAccessTokenCreateArgs[`input`]
  ): Promise<ShopifyAccessToken> {
    return await this.client.post(`${this.url}/auth/login`, {
      body: {
        ...input,
      },
    });
  }

  async accessTokenRenew(
    input: ShopifyStorefrontMutationCustomerAccessTokenRenewArgs
  ): Promise<ShopifyAccessToken> {
    return await this.client.post(`${this.url}/access-token-renew`, {
      body: {
        ...input,
      },
    });
  }

  async modifyPassword(
    email: string,
    oldPassword: string,
    newPassword: string
  ): Promise<ShopifyAccessToken> {
    return await this.client.post(`${this.url}/auth/update-password`, {
      body: {
        email,
        oldPassword,
        newPassword,
      },
    });
  }

  async create({
    firstName,
    lastName,
    email,
    password,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }): Promise<_Customer | undefined> {
    return await this.client.post(`${this.url}/auth/register`, {
      body: {
        input: {
          firstName,
          lastName,
          email,
          password,
        },
      },
    });
  }

  async activateAccount(
    activationUrl: string,
    password: string
  ): Promise<ShopifyAccessToken> {
    return await this.client.post(`${this.url}/activate-account`, {
      body: {
        activationUrl,
        password,
      },
    });
  }
}
