import { forwardRef } from 'react';

import { Box, BoxProps } from '@/components/atoms';

export interface Props extends BoxProps {
  gradient: string;
  width: number | (null | number | string)[] | string;
  height: number | (null | number | string)[] | string;
}

export const GradientPill = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { gradient, width, height, ...rest } = props;

    return (
      <Box
        position="absolute"
        bg={gradient}
        w={width}
        h={height}
        borderRadius={9999}
        ref={ref}
        {...rest}
      />
    );
  }
);
