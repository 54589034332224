import { forwardRef } from 'react';

import { _Link } from '@/domain/entities';

import { Box, Container, Flex, Link } from '@/components/atoms';

import { useGetUniqueKey } from '@/hooks';

import { isActiveLink, isBrowser } from '@/utils';

export interface Props {
  isNavOpen: boolean;
  navLinks: _Link[];
}

export const MobileAccountNavigation = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { isNavOpen, navLinks = [], ...rest } = props;
    const getUniqueKey = useGetUniqueKey();
    const currentPath = isBrowser ? location.pathname : undefined;

    return (
      <Box
        ref={ref}
        as="aside"
        position="absolute"
        bg="white"
        visibility={isNavOpen ? `visible` : `hidden`}
        transform={isNavOpen ? `translateY(0%)` : `translateY(-120%)`}
        transition=".3s"
        zIndex={9}
        width="100%"
        height="100%"
        {...rest}
      >
        <Box width="100%" height="100%" textAlign="center">
          <Container>
            <Flex h="100%" flexDirection="column" justifyContent="center">
              <Flex flexDirection="column" pt={100}>
                {navLinks.map((navLink, key) => {
                  const isLast = key === navLinks.length - 1;
                  return (
                    <Box
                      key={getUniqueKey(navLink)}
                      mb={isLast ? 0 : [3, 4, 10]}
                      sx={{
                        '.active': {
                          bg: `linear-gradient(90deg, #A770EF 0%, #CF8BF3 50%, #FDB99B 100%)`,
                          color: `transparent`,
                          bgClip: `text`,
                        },
                      }}
                    >
                      <Link
                        to={navLink.path}
                        variant="nav"
                        className={
                          isActiveLink(navLink.path, currentPath)
                            ? `active`
                            : ``
                        }
                      >
                        {navLink.label}
                      </Link>
                    </Box>
                  );
                })}
              </Flex>
            </Flex>
          </Container>
        </Box>
      </Box>
    );
  }
);
