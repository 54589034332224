import {
  ShopifyStorefrontCustomer,
  ShopifyStorefrontCustomerRecoverPayload,
  ShopifyStorefrontCustomerResetByUrlPayload,
  ShopifyStorefrontCustomerUserError,
  ShopifyStorefrontMutationCustomerRecoverArgs,
  ShopifyStorefrontMutationCustomerResetByUrlArgs,
} from '@shopify/types';

import { customerRecoverMutation, customerResetByUrlMutation } from '@/graphql';

import { ShopifyRepository } from './ShopifyRepository';

export class ShopifyCustomerRepository extends ShopifyRepository {
  async recoverPassword(
    email: string
  ): Promise<ShopifyStorefrontCustomerUserError[] | undefined> {
    const res = await this.client.send<
      ShopifyStorefrontCustomerRecoverPayload,
      ShopifyStorefrontMutationCustomerRecoverArgs
    >(customerRecoverMutation, {
      email,
    });
    return res.data?.customerRecover?.customerUserErrors || undefined;
  }

  async resetPassword(
    resetUrl: string,
    password: string
  ): Promise<ShopifyStorefrontCustomer | undefined> {
    const res = await this.client.send<
      ShopifyStorefrontCustomerResetByUrlPayload,
      ShopifyStorefrontMutationCustomerResetByUrlArgs
    >(customerResetByUrlMutation, {
      resetUrl,
      password,
    });
    return res.data?.customerResetByUrl.customer || undefined;
  }
}
