import { ShopifyAdminOrderClosePayload } from '@shopify/types';
import { _Order } from '@/domain/entities';

import { HeddaRepository } from '@/repositories';

export class NewHeddaOrderRepository extends HeddaRepository {
  async find(): Promise<_Order[]> {
    return await this.client.get(`${this.url}/order`);
  }

  async orderClose(id: string): Promise<ShopifyAdminOrderClosePayload> {
    return await this.client.post(`${this.url}/order/close`, {
      body: { input: { id } },
    });
  }
}
