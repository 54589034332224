export enum TrackingEvent {
  ADD_TO_CART = `ADD_TO_CART`,
  ADD_TO_WISHLIST = `ADD_TO_WISHLIST`,
  BEGIN_CHECKOUT = `BEGIN_CHECKOUT`,
  CONTACT = `CONTACT`,
  ECOMMERCE = `ECOMMERCE`,
  GENERIC = `GENERIC`,
  PAGE_VIEW = `PAGE_VIEW`,
  REMOVE_FROM_CART = `REMOVE_FROM_CART`,
  SELECT_CONTENT = `SELECT_CONTENT`,
  SELECT_ITEM = `SELECT_ITEM`,
  SEARCH = `SEARCH`,
  VIEW_ITEM = `VIEW_ITEM`,
  VIEW_ITEM_LIST = `VIEW_ITEM_LIST`,
  VIEW_SEARCH_RESULTS = `VIEW_SEARCH_RESULTS`,
  LOGIN = `LOGIN`,
  SIGNUP = `SIGN_UP`,
  ORDER = `ORDER`,
  VIEW_CART = `VIEW_CART`,
}

export enum TrackingValue {
  AD_STORAGE = `AD_STORAGE`,
  ANALYTICS_STORAGE = `ANALYTICS_STORAGE`,
  CONTENT_CATEGORY = `CONTENT_CATEGORY`,
  CONTENT_IDS = `CONTENT_IDS`,
  CONTENT_ID = `CONTENT_ID`,
  CONTENT_NAME = `CONTENT_NAME`,
  CONTENT_TYPE = `CONTENT_TYPE`,
  CONTENTS = `CONTENTS`,
  COUNTRY = `COUNTRY`,
  CURRENCY = `CURRENCY`,
  EVENT_ACTION = `EVENT_ACTION`,
  EVENT_CATEGORY = `EVENT_CATEGORY`,
  EVENT_ID = `EVENT_ID`,
  FEATURE_NAME = `FEATURE_NAME`,
  FUNCTIONAL_STORAGE = `FUNCTIONAL_STORAGE`,
  INDEX = `INDEX`,
  ITEM_BRAND = `ITEM_BRAND`,
  ITEM_CATEGORY = `ITEM_CATEGORY`,
  ITEM_CATEGORY_2 = `ITEM_CATEGORY_2`,
  ITEM_ID = `ITEM_ID`,
  ITEM_NAME = `ITEM_NAME`,
  ITEM_LIST_ID = `ITEM_LIST_ID`,
  ITEM_LIST_NAME = `ITEM_LIST_NAME`,
  ITEM = `ITEM`,
  ITEMS = `ITEMS`,
  METHOD = `METHOD`,
  NECESSARY_COOKIES = `NECESSARY_COOKIES`,
  PAGE_NAME = `PAGE_NAME`,
  PERFORMANCES_COOKIES = `PERFORMANCES_COOKIES`,
  PLATFORM = `PLATFORM`,
  PRICE = `PRICE`,
  QUANTITY = `QUANTITY`,
  SCROLL_PERCENTAGE = `SCROLL_PERCENTAGE`,
  SEARCH_TERM = `SEARCH_TERM`,
  SOCIAL_MEDIA = `SOCIAL_MEDIA`,
  STEP_NAME = `STEP_NAME`,
  VALUE = `VALUE`,
}

export interface TrackingItem {
  [TrackingValue.ITEM_ID]: string;
  [TrackingValue.ITEM_NAME]: string;
  [TrackingValue.CURRENCY]: string;
  [TrackingValue.INDEX]?: number;
  [TrackingValue.ITEM_BRAND]: string;
  [TrackingValue.ITEM_CATEGORY]: string;
  [TrackingValue.ITEM_CATEGORY_2]?: string;
  [TrackingValue.ITEM_LIST_ID]?: string;
  [TrackingValue.ITEM_LIST_NAME]?: string;
  [TrackingValue.PRICE]: number;
  [TrackingValue.QUANTITY]: number;
}

export interface TrackingInfo {
  itemListId: string;
  itemListName: string;
}

export enum TrackingContentType {
  BLOG_POST = `blog_post`,
  HELP_CATEGORY = `help_category`,
  HELP_QUESTION = `help_question`,
  PRODUCT = `product`,
}

export const TrackingCurrency = {
  EUR: `EUR`,
};
