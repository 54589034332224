import { forwardRef } from 'react';
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps,
  useStyleConfig,
} from '@chakra-ui/react';

export interface Props extends CheckboxProps {}

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  (props, ref): JSX.Element => {
    const style = useStyleConfig(`Checkbox`, props);

    return <ChakraCheckbox ref={ref} __css={style} {...props} />;
  }
);
