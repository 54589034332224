import { Link } from 'gatsby';
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';

import { _Link } from '@/domain/entities';

import { useGetUniqueKey } from '@/hooks';

export interface Props {
  currentPage?: number;
  breadcrumbs: _Link[];
}

export function Breadcrumb(props: Props): JSX.Element {
  const { currentPage, breadcrumbs } = props;
  const getUniqueKey = useGetUniqueKey();
  const renderBreadcrumbs = breadcrumbs.map((breadcrumb, key) => {
    const isCurrentPage = currentPage === key;

    return (
      <BreadcrumbItem key={getUniqueKey(key)} isCurrentPage={isCurrentPage}>
        <BreadcrumbLink
          as={isCurrentPage ? undefined : Link}
          to={breadcrumb.path}
          color={isCurrentPage ? `gray.500` : `black`}
          cursor={isCurrentPage ? `auto` : `pointer`}
          _hover={{
            textDecoration: isCurrentPage ? `none` : `underline`,
          }}
        >
          {breadcrumb.label}
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  });

  return <ChakraBreadcrumb>{renderBreadcrumbs}</ChakraBreadcrumb>;
}
