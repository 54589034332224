import { forwardRef, MouseEvent } from 'react';
import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  useMultiStyleConfig,
  useTheme,
} from '@chakra-ui/react';

import { _BenefitAccordion } from '@/domain/entities/_/_BenefitAccordion';

import { Box, Grid, Heading, Icon, MotionBox, Text } from '@/components/atoms';

import { useGetUniqueKey, useIsMobile } from '@/hooks';

export interface Props extends AccordionProps {
  accordions: _BenefitAccordion[];
  isLoading?: boolean;
  onButtonClick?: (
    index: number,
    isExpanded: boolean,
    e: MouseEvent<HTMLButtonElement>
  ) => void;
}

export const BenefitAccordion = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { accordions = [], onButtonClick, ...rest } = props;
    const getUniqueKey = useGetUniqueKey();
    const mobile = useIsMobile();
    const styles = useMultiStyleConfig(`Accordion`, props);
    const theme = useTheme();

    const onClick = (
      index: number,
      isExpanded: boolean,
      e: MouseEvent<HTMLButtonElement>
    ) => {
      onButtonClick?.(index, isExpanded, e);
    };

    return (
      <ChakraAccordion
        allowToggle={!mobile}
        allowMultiple={mobile}
        ref={ref}
        {...rest}
      >
        <Grid columns={[0, null, 2]}>
          {accordions.map((accordion, key) => (
            <AccordionItem
              maxW={580}
              key={getUniqueKey(accordion)}
              borderColor="transparent"
              mb={4}
              _last={{ mb: 0 }}
            >
              {({ isExpanded }) => (
                <>
                  <Box
                    _hover={
                      mobile
                        ? {}
                        : {
                            bg: isExpanded ? `transparent` : `#191919`,
                          }
                    }
                    bg="transparent"
                    position="relative"
                    borderRadius={20}
                  >
                    <MotionBox
                      animate={
                        isExpanded
                          ? {
                              background: theme.colors.grad2,
                            }
                          : {
                              background: `transparent`,
                            }
                      }
                      borderRadius={20}
                      position="relative"
                      w="100%"
                      h="100%"
                      p="2px"
                    >
                      <Box
                        borderRadius={18}
                        bg={isExpanded ? `black` : `transparent`}
                        position="relative"
                      >
                        <AccordionButton
                          py={5}
                          borderRadius={20}
                          __css={styles.button}
                          onClick={(e) => onClick(key, !isExpanded, e)}
                        >
                          <Box>
                            <Box
                              textAlign="left"
                              ml={5}
                              mb={5}
                              sx={{
                                svg: {
                                  path: {
                                    fill: isExpanded
                                      ? `url(#paint0_linear_3139_9493)`
                                      : `white`,
                                  },
                                },
                              }}
                            >
                              <svg width="0" height="0">
                                <defs>
                                  <radialGradient
                                    id="paint0_linear_3139_9493"
                                    r="150%"
                                    cx="30%"
                                    cy="107%"
                                  >
                                    <stop stopColor="#FFA8A8" />
                                    <stop offset="1" stopColor="#FCFF00" />
                                  </radialGradient>
                                </defs>
                              </svg>
                              <Icon
                                aria-hidden
                                size="2x"
                                icon={accordion.icon}
                              />
                            </Box>
                            <Heading
                              maxW={360}
                              variant="h6"
                              ml={4}
                              mr="auto"
                              textColor="white"
                              bg={isExpanded ? theme.colors.grad2 : `none`}
                              bgClip={isExpanded ? `text` : `none`}
                            >
                              {accordion.heading}
                            </Heading>
                          </Box>
                        </AccordionButton>
                        <AccordionPanel textColor="white" w="80%" ml={4}>
                          <Text variant="3" color="#A2A1A7">
                            {accordion.text}
                          </Text>
                        </AccordionPanel>
                      </Box>
                    </MotionBox>
                  </Box>
                </>
              )}
            </AccordionItem>
          ))}
        </Grid>
      </ChakraAccordion>
    );
  }
);
