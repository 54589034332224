import {
  _BlogAuthor,
  _BlogCategory,
  _BlogCategoryPage,
  _BlogPage,
  _BlogPost,
  _BlogPostPage,
  _BlogSubCategory,
  _BlogSubCategoryPage,
  GatsbyPrismicBlogAuthor,
  GatsbyPrismicBlogCategory,
  GatsbyPrismicBlogCategoryConnection,
  GatsbyPrismicBlogPage,
  GatsbyPrismicBlogPost,
  GatsbyPrismicBlogPostConnection,
  GatsbyPrismicBlogSubCategory,
  GatsbyPrismicMedicalExpert,
} from '@/domain/entities';
import { toImage } from '@/domain/mappings/image';
import { toMedicalExpert } from '@/domain/mappings/medicalExpert';
import { toReactNode } from '@/domain/mappings/react';

import { createLinkPath, toArray } from '@/utils';

export const toBlogPage = (
  blogPage?: GatsbyPrismicBlogPage,
  blogPosts?: GatsbyPrismicBlogPostConnection,
  featuredPosts?: GatsbyPrismicBlogPostConnection,
  blogCategories?: GatsbyPrismicBlogCategoryConnection
): _BlogPage => {
  const [featuredPost] =
    toArray(featuredPosts)?.map((featuredPost) =>
      toBlogPost(featuredPost, blogPage)
    ) || [];

  const categories =
    toArray(blogCategories)?.map((blogCategory) =>
      toBlogCategory(blogCategory, blogPage)
    ) || [];

  const posts =
    toArray(blogPosts)?.map((blogPost) => toBlogPost(blogPost, blogPage)) || [];

  return {
    hero: {
      heading: toReactNode(blogPage?.data.hero_title?.richText),
    },
    featuredPost,
    categories: {
      blogCategories: categories,
      blogPosts: posts,
    },
  };
};

export const toBlogCategoryPage = (
  blogPage?: GatsbyPrismicBlogPage,
  blogCategory?: GatsbyPrismicBlogCategory,
  blogPosts?: GatsbyPrismicBlogPostConnection
): _BlogCategoryPage => {
  const category = toBlogCategory(blogCategory, blogPage);

  const posts =
    toArray(blogPosts).map((blogPost) => toBlogPost(blogPost, blogPage)) || [];

  const subCategories =
    blogCategory?.data.sub_categories?.map(
      (subCategory) => subCategory?.sub_category?.document
    ) || [];
  return {
    topBar: {
      breadcrumbs: [
        {
          label: blogPage?.data.title || `Blog`,
          path: createLinkPath([category.lang, blogPage?.uid]),
        },
        {
          label: category.name,
          path: createLinkPath([category.lang, blogPage?.uid, category.slug]),
        },
      ],
    },
    hero: {
      name: category.name,
      postsLength: posts?.length || 0,
    },
    blogCategoryPosts: {
      blogSubCategories:
        subCategories?.map((subCategory) =>
          toBlogSubCategory(
            subCategory as GatsbyPrismicBlogSubCategory,
            blogPage,
            blogCategory
          )
        ) || [],
      blogPosts: posts,
    },
  };
};

export const toBlogSubCategoryPage = (
  blogPage?: GatsbyPrismicBlogPage,
  blogPosts?: GatsbyPrismicBlogPostConnection,
  blogCategory?: GatsbyPrismicBlogCategory,
  subCategory?: GatsbyPrismicBlogSubCategory
): _BlogSubCategoryPage => {
  const category = toBlogCategory(blogCategory, blogPage);

  const blogSubCategory = toBlogSubCategory(
    subCategory,
    blogPage,
    blogCategory
  );

  const posts =
    toArray(blogPosts)?.map((blogPost) => toBlogPost(blogPost, blogPage)) || [];

  return {
    topBar: {
      breadcrumbs: [
        {
          label: blogPage?.data.title || `Blog`,
          path: createLinkPath([blogSubCategory.lang, blogPage?.uid]),
        },
        {
          label: category.name,
          path: createLinkPath([
            blogSubCategory.lang,
            blogPage?.uid,
            category.slug,
          ]),
        },
        {
          label: blogSubCategory.name,
          path: createLinkPath([
            blogSubCategory.lang,
            blogPage?.uid,
            category.slug,
            blogSubCategory.slug,
          ]),
        },
      ],
    },
    hero: {
      name: subCategory?.data.name,
      postsLength: posts?.length || 0,
    },
    blogPosts: posts,
  };
};

export const toBlogPostPage = (
  blogPage?: GatsbyPrismicBlogPage,
  blogPost?: GatsbyPrismicBlogPost,
  additionalProps?: Record<string, any>
): _BlogPostPage => {
  const post = toBlogPost(blogPost, blogPage, additionalProps);
  return {
    topBar: {
      breadcrumbs: [
        {
          label: blogPage?.data.title || `Blog`,
          path: createLinkPath([post.lang, blogPage?.uid]),
        },
        {
          label: post.category.name,
          path: createLinkPath([post.lang, blogPage?.uid, post.category.slug]),
        },
        {
          label: post.subCategory.name,
          path: createLinkPath([
            post.lang,
            blogPage?.uid,
            post.category.slug,
            post.subCategory.slug,
          ]),
        },
      ],
      blogPost: post,
    },
    hero: {
      firstPublicationDate: post.firstPublicationDate || new Date(),
      heading: post.title,
      image: post.image,
      authors: post.authors,
    },
    intro: post.intro,
    medicalExperts: post.experts,
    body: post.body,
    date: {
      firstPublicationDate: post.firstPublicationDate || new Date(),
      lastPublicationDate: post.lastPublicationDate || new Date(),
    },
  };
};

export const toBlogAuthor = (
  blogAuthor?: GatsbyPrismicBlogAuthor | GatsbyPrismicMedicalExpert
): _BlogAuthor => ({
  firstName: blogAuthor?.data?.first_name || ``,
  lastName: blogAuthor?.data?.last_name || ``,
});

export const toBlogPost = (
  blogPost?: GatsbyPrismicBlogPost,
  blogPage?: GatsbyPrismicBlogPage,
  additionalProps?: Record<string, any>
): _BlogPost => {
  const category = toBlogCategory(
    blogPost?.data?.category?.document as GatsbyPrismicBlogCategory
  );
  const subCategory = toBlogSubCategory(
    blogPost?.data?.sub_category?.document as GatsbyPrismicBlogSubCategory
  );
  return {
    id: blogPost?.id || ``,
    slug: blogPost?.uid || ``,
    lang: blogPost?.lang || ``,
    firstPublicationDate: blogPost?.first_publication_date || new Date(),
    lastPublicationDate: blogPost?.last_publication_date || new Date(),
    image: toImage(blogPost?.data?.image),
    title: toReactNode(blogPost?.data?.title?.richText) || ``,
    excerpt: toReactNode(blogPost?.data?.excerpt?.richText) || ``,
    intro: toReactNode(blogPost?.data?.intro?.richText) || ``,
    body: toReactNode(blogPost?.data?.body?.richText, true, additionalProps),
    authors:
      blogPost?.data?.authors?.map((item) =>
        toBlogAuthor(item?.author?.document as GatsbyPrismicBlogAuthor)
      ) || [],
    experts:
      blogPost?.data?.experts?.map((item) =>
        toMedicalExpert(item?.expert?.document as GatsbyPrismicMedicalExpert)
      ) || [],
    category,
    subCategory,
    link: {
      path: createLinkPath([
        blogPost?.lang,
        blogPage?.uid,
        category.slug,
        subCategory.slug,
        blogPost?.uid,
      ]),
    },
    shareTitle: blogPost?.data?.title?.text || ``,
    bodyText: blogPost?.data?.body?.text || ``,
  };
};

export const toBlogCategory = (
  blogCategory?: GatsbyPrismicBlogCategory,
  blogPage?: GatsbyPrismicBlogPage
): _BlogCategory => ({
  id: blogCategory?.id || ``,
  slug: blogCategory?.uid || ``,
  lang: blogCategory?.lang || ``,
  name: blogCategory?.data?.name || ``,
  link: {
    path: createLinkPath([
      blogCategory?.lang,
      blogPage?.uid,
      blogCategory?.uid,
    ]),
  },
});

export const toBlogSubCategory = (
  blogSubCategory?: GatsbyPrismicBlogSubCategory,
  blogPage?: GatsbyPrismicBlogPage,
  blogCategory?: GatsbyPrismicBlogCategory
): _BlogSubCategory => ({
  id: blogSubCategory?.id || ``,
  slug: blogSubCategory?.uid || ``,
  lang: blogSubCategory?.lang || ``,
  name: blogSubCategory?.data?.name || ``,
  link: {
    path: createLinkPath([
      blogSubCategory?.lang,
      blogPage?.uid,
      blogCategory?.uid,
      blogSubCategory?.uid,
    ]),
  },
});
