import {
  Company,
  TrackingCurrency,
  TrackingItem,
  TrackingValue,
} from '@/config';

import { _CartLine, _Product } from '@/domain/entities';

export const toValues = <V extends { [key in TrackingValue]?: string }>(
  properties: Record<string, string>,
  values: V
): Partial<V> => {
  return Object.entries(properties).reduce((acc, [key, value]) => {
    if (typeof value === `undefined`) {
      return acc;
    }

    const getValues = () => {
      if (Array.isArray(value)) {
        return value.map((v) => toValues(v, values));
      }
      return value;
    };

    return values[key as TrackingValue]
      ? { ...acc, [values[key as TrackingValue] || ``]: getValues() }
      : acc;
  }, {}) as Partial<V>;
};

type PickedCartLine = Pick<_CartLine, `sku` | `name` | `price` | `quantity`>;
type PickedProduct = Pick<_Product, `sku` | `name` | `price`>;

interface ToTrackingItemProps<I extends PickedCartLine | PickedProduct> {
  item: I;
  quantity?: number;
  itemListId?: string;
  itemListName?: string;
  position?: number;
}

export interface ToTrackingItem {
  fromCartLine: (
    props: Pick<ToTrackingItemProps<PickedCartLine>, `item`>
  ) => TrackingItem;
  fromProduct: (props: ToTrackingItemProps<PickedProduct>) => TrackingItem;
}

export const toTrackingItem: ToTrackingItem = {
  fromCartLine: (props) => ({
    [TrackingValue.ITEM_ID]: props.item.sku,
    [TrackingValue.ITEM_NAME]: props.item.name,
    [TrackingValue.CURRENCY]: TrackingCurrency.EUR,
    [TrackingValue.ITEM_BRAND]: Company.name,
    [TrackingValue.ITEM_CATEGORY]: `supplement`,
    [TrackingValue.PRICE]: +props.item.price,
    [TrackingValue.QUANTITY]: props.item.quantity,
  }),
  fromProduct: (props) => ({
    [TrackingValue.ITEM_ID]: props.item.sku,
    [TrackingValue.ITEM_NAME]: props.item.name,
    [TrackingValue.CURRENCY]: TrackingCurrency.EUR,
    [TrackingValue.INDEX]: props.position,
    [TrackingValue.ITEM_BRAND]: Company.name,
    [TrackingValue.ITEM_CATEGORY]: `supplement`,
    [TrackingValue.ITEM_LIST_ID]: props.itemListId,
    [TrackingValue.ITEM_LIST_NAME]: props.itemListName,
    [TrackingValue.PRICE]: +props.item.price,
    [TrackingValue.QUANTITY]: props.quantity || 1,
  }),
};
