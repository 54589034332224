import {
  _SearchPage,
  GatsbyPrismicHelpPage,
  GatsbyPrismicSearchPage,
} from '@/domain/entities';

import { createLinkPath } from '@/utils';

import { toReactNode } from './react';

export const toSearchPage = (
  searchPage?: GatsbyPrismicSearchPage,
  helpPage?: GatsbyPrismicHelpPage
): _SearchPage => ({
  heading: toReactNode(searchPage?.data.heading?.richText),
  lang: searchPage?.lang || ``,
  link: {
    path: createLinkPath([searchPage?.lang, helpPage?.uid, searchPage?.uid]),
  },
});
