import { cartFragment } from '@/graphql';

import { graphql } from '@/utils';

export const cartLinesAddMutation = graphql`
  mutation CartLinesAddMutation($cartId: ID!, $lines: [CartLineInput!]!) {
    cart: cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`;
