import { graphql } from '@/utils';

export const customerResetByUrlMutation = graphql`
  mutation CustomerResetByUrlMutation($resetUrl: URL!, $password: String!) {
    customerResetByUrl(resetUrl: $resetUrl, password: $password) {
      customer {
        id
      }
    }
  }
`;
