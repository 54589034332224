import { ChangeEvent, forwardRef, useEffect, useState } from 'react';

import {
  Box,
  Flex,
  FlexProps,
  Input,
  InputProps,
  Select,
  SelectProps,
  Text,
} from '@/components/atoms';

import { useTranslation } from '@/hooks';

export interface Props extends Omit<FlexProps, `onChange`> {
  inputProps?: InputProps;
  selectProps?: SelectProps;
  prefix?: string;
  phone?: string;
  onChange: (prefix: string, number: string) => void;
}

export const PhoneInput = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { inputProps, onChange, selectProps, phone, prefix, ...rest } = props;

    const { t } = useTranslation();
    const [selectedPrefix, setSelectedPrefix] = useState<string>(
      prefix || `+32`
    );
    const [number, setNumber] = useState<string>(phone || ``);

    const countries: Array<Record<`code` | `name` | `prefix`, string>> = t(
      `countries`,
      {
        returnObjects: true,
      }
    );

    useEffect(() => {
      onChange(selectedPrefix, number);
    }, [selectedPrefix, number]);

    useEffect(() => {
      setSelectedPrefix(prefix || ``);
      setNumber(phone || ``);
    }, [prefix, phone]);

    const onCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setSelectedPrefix(value);
    };

    const onPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setNumber(value);
    };

    return (
      <Flex ref={ref} position="relative" {...rest}>
        <Box position="absolute" h={16}>
          <Box position="relative">
            <Select
              borderRightRadius={0}
              opacity={0}
              width="90px"
              zIndex={2}
              onChange={onCountryChange}
              value={selectedPrefix}
              {...selectProps}
            >
              {countries.map((country) => (
                <option key={country.code} value={country.prefix}>
                  {country.name}
                </option>
              ))}
            </Select>
            <Flex
              position="absolute"
              top={5}
              left={5}
              pt="1px"
              alignItems="center"
              zIndex={1}
            >
              <Text>{selectedPrefix}</Text>
            </Flex>
          </Box>
        </Box>
        <Input
          pl="90px"
          type="tel"
          placeholder={t(`placeholder.phone`) || ``}
          onChange={onPhoneNumberChange}
          value={number}
          {...inputProps}
        />
      </Flex>
    );
  }
);
