import { graphql } from '@/utils';

export const cartFragment = graphql`
  fragment CartFragment on Cart {
    id
    createdAt
    updatedAt
    checkoutUrl
    discountAllocations {
      discountedAmount {
        amount
      }
    }
    discountCodes {
      applicable
      code
    }
    buyerIdentity {
      countryCode
      customer {
        id
      }
    }
    estimatedCost {
      totalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
    }
    lines(first: 10) {
      edges {
        node {
          id
          estimatedCost {
            totalAmount {
              amount
              currencyCode
            }
          }
          sellingPlanAllocation {
            sellingPlan {
              name
              priceAdjustments {
                adjustmentValue {
                  ... on SellingPlanPercentagePriceAdjustment {
                    adjustmentPercentage
                  }
                }
              }
            }
          }
          merchandise {
            ... on ProductVariant {
              availableForSale
              product {
                id
                handle
                title
                productType: metafield(key: "type", namespace: "my_fields") {
                  value
                }
                productCapacity: metafield(
                  key: "capacity"
                  namespace: "my_fields"
                ) {
                  value
                }
              }
              priceV2 {
                amount
                currencyCode
              }
            }
          }
          quantity
        }
      }
    }
  }
`;
