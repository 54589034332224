import { forwardRef } from 'react';
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  useMultiStyleConfig,
} from '@chakra-ui/react';

export type NumberInputVariant = `quantity`;

export interface Props extends NumberInputProps {
  variant?: NumberInputVariant;
  disabled?: boolean;
}

export const NumberInput = forwardRef<HTMLInputElement, Props>(
  (props, ref): JSX.Element => {
    const { disabled = false, ...rest } = props;
    const styles = useMultiStyleConfig(`NumberInput`, props);

    return (
      <ChakraNumberInput ref={ref} __css={styles.root} {...rest}>
        <NumberInputField
          disabled={disabled}
          _disabled={{ textColor: `black` }}
          data-test="quantity-modificator"
        />
        <NumberInputStepper borderWidth={0}>
          <NumberIncrementStepper />
          <NumberDecrementStepper
            borderColor="gray.300"
            borderBottomWidth={0}
            borderTopWidth={0}
          />
        </NumberInputStepper>
      </ChakraNumberInput>
    );
  }
);
