import {
  _MobileAppFeature,
  _MobileAppPage,
  GatsbyPrismicMobileAppPage,
  GatsbyPrismicMobileAppPageDataFeaturesv2,
} from '@/domain/entities';
import { toReactNode } from '@/domain/mappings';

import { convertStringToArray, createLinkPath } from '@/utils';

export const toMobileAppPage = (
  mobileAppPage?: GatsbyPrismicMobileAppPage
): _MobileAppPage => {
  return {
    hero: {
      heading: mobileAppPage?.data.hero_titlev2?.text || ``,
      mobileHeading: mobileAppPage?.data.hero_mobile_titlev2?.text || ``,
      text: toReactNode(mobileAppPage?.data.hero_textv2?.richText),
      highlightedWords: convertStringToArray(
        mobileAppPage?.data.hero_highlighted_words || ``
      ),
      storeText: mobileAppPage?.data.hero_store_text || ``,
    },
    features: {
      heading: toReactNode(mobileAppPage?.data.features_title?.richText),
      buttonLink: {
        label: mobileAppPage?.data.features_button_text || `MobileApp`,
        path: createLinkPath([
          mobileAppPage?.lang,
          mobileAppPage?.data?.features_button_link?.uid || ``,
        ]),
      },
      features:
        mobileAppPage?.data.featuresv2?.map((feature) =>
          toFeature(feature || undefined)
        ) || [],
    },
    data: {
      heading: toReactNode(mobileAppPage?.data.data_title?.richText),
      text: toReactNode(mobileAppPage?.data.data_text?.richText),
      link: {
        label: mobileAppPage?.data.data_link_text || ``,
        path: createLinkPath([
          mobileAppPage?.lang,
          mobileAppPage?.data?.data_link?.uid || ``,
        ]),
      },
      highlightedWords: convertStringToArray(
        mobileAppPage?.data.data_highlighted_words || ``
      ),
    },
  };
};

export const toFeature = (
  feature?: GatsbyPrismicMobileAppPageDataFeaturesv2
): _MobileAppFeature => ({
  heading: feature?.featuresv2_heading || ``,
  text: toReactNode(feature?.featuresv2_text?.richText),
  comingSoon: feature?.features_coming_soon || false,
});
