import { forwardRef } from 'react';

import { Box, Skeleton, SkeletonProps } from '@/components/atoms';

export interface Props extends SkeletonProps {}

export const OrderLineLineSkeleton = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const oneSkeleton = (
      <Skeleton {...props} mb={2} width="100%" height={76} borderRadius="xl" />
    );

    return (
      <Box ref={ref}>
        {oneSkeleton}
        {oneSkeleton}
        {oneSkeleton}
      </Box>
    );
  }
);
