import {
  _Link,
  GatsbyPrismicAccountPage,
  GatsbyPrismicBlogPage,
  GatsbyPrismicCartPage,
  GatsbyPrismicHeddaPage,
  GatsbyPrismicHelpPage,
  GatsbyPrismicHomePage,
  GatsbyPrismicMobileAppPage,
  GatsbyPrismicOrdersPage,
  GatsbyPrismicProductsPage,
  GatsbyPrismicSubscriptionsPage,
} from '@/domain/entities';

import { createLinkPath } from '@/utils/createLink';

export const transformPageLinks = (
  pages: (
    | GatsbyPrismicBlogPage
    | GatsbyPrismicCartPage
    | GatsbyPrismicHeddaPage
    | GatsbyPrismicHelpPage
    | GatsbyPrismicHomePage
    | GatsbyPrismicOrdersPage
    | GatsbyPrismicProductsPage
    | GatsbyPrismicAccountPage
    | GatsbyPrismicSubscriptionsPage
    | GatsbyPrismicMobileAppPage
  )[]
) => {
  return pages.map<_Link>((page) => ({
    label: `title` in page.data ? page.data.title || `` : ``,
    path: createLinkPath([page.lang, `uid` in page ? page.uid : ``]),
  }));
};
