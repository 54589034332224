import { toNumber } from '@/utils/toNumber';

export const calculatePercentage = (
  value: number | string,
  percentage: number
) => {
  if (!percentage) {
    return value;
  }
  const percentageValue = Math.round((toNumber(value) / 100) * percentage);
  return toNumber(value) - percentageValue;
};
