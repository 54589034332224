import {
  ShopifyStorefrontProduct,
  ShopifyStorefrontQueryRoot,
} from '@shopify/types';

import { productQuery } from '@/graphql';

import { ShopifyRepository } from './ShopifyRepository';

export class ShopifyProductRepository extends ShopifyRepository {
  async findOne(id: string): Promise<ShopifyStorefrontProduct | undefined> {
    const res = await this.client.send<ShopifyStorefrontQueryRoot[`product`]>(
      productQuery,
      {
        id,
      }
    );
    return res.data?.product || undefined;
  }
}
