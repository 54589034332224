import { forwardRef } from 'react';

import {
  Box,
  Button,
  Flex,
  FlexProps,
  Input,
  InputProps,
} from '@/components/atoms';
import { Search } from '@/components/icons';

export interface Props extends FlexProps {
  inputProps?: InputProps;
  hasButton?: boolean;
}

export const SearchBar = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { inputProps, hasButton = true, ...rest } = props;

    return (
      <Flex ref={ref} alignItems="center" {...rest}>
        <Box mr={2} width={[`100%`, null, `auto`]}>
          <Input
            data-test="searchBar"
            variant="search"
            type="search"
            {...inputProps}
          />
        </Box>
        {hasButton && (
          <Box>
            <Button data-test="go-to-results" type="submit" px={5}>
              <Search fill="white" width="18px" />
            </Button>
          </Box>
        )}
      </Flex>
    );
  }
);
