import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useTheme } from '@chakra-ui/react';

import {
  Box,
  Button,
  Flex,
  FlexProps,
  Icon,
  MotionBox,
  MotionFlex,
} from '@/components/atoms';

import { useIsMobile } from '@/hooks';

import { isBrowser } from '@/utils';

export interface Props extends FlexProps {
  isSelected: boolean;
  setIsSelected: Dispatch<SetStateAction<boolean>>;
}

export const HorizontalSection = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { children, isSelected, setIsSelected } = props;
    const mobile = useIsMobile();
    const theme = useTheme();
    const [buttonPosition, setButtonPosition] = useState<number>(
      isBrowser ? window.innerHeight - 80 : 0
    );
    const resetScroll = useDebouncedCallback(async () => {
      if (horizontalSectionScrollable) {
        await horizontalSectionScrollable.scrollTo(0, 0);
      }
    }, 1000);
    const horizontalSection = isBrowser
      ? document?.querySelector<HTMLElement>(`.horizontalScroll`)
      : undefined;
    const horizontalSectionScrollable: HTMLElement | undefined =
      horizontalSection
        ? (horizontalSection?.firstElementChild as HTMLElement)
        : undefined;
    const horizontalSectionChild: HTMLElement | undefined = horizontalSection
      ? (horizontalSection?.firstElementChild?.firstElementChild as HTMLElement)
      : undefined;
    const container = isBrowser
      ? document.querySelector<HTMLElement>(`.scrollYContainer`)
      : undefined;
    const scrollProgress = isBrowser
      ? document.querySelector<HTMLElement>(`.scrollProgress`)
      : undefined;
    let total = 0;

    const getClientWidth = useCallback((): number => {
      if (
        isBrowser &&
        horizontalSection &&
        horizontalSectionChild &&
        container
      ) {
        if (
          horizontalSectionChild.getBoundingClientRect().width / 2 >
          window.innerWidth
        ) {
          return (
            horizontalSectionChild.getBoundingClientRect().width -
            window.innerWidth
          );
        } else {
          return horizontalSectionChild.getBoundingClientRect().width / 2;
        }
      }
      if (isBrowser) {
        return window.innerWidth;
      } else {
        return 0;
      }
    }, [container, horizontalSection, horizontalSectionChild]);

    const [clientWidth, setClientWidth] = useState<number>(getClientWidth);

    const isEscapePressed = useCallback(
      (e: KeyboardEvent) => {
        if (e.key === `Escape`) {
          setIsSelected(false);
        }
      },
      [setIsSelected]
    );

    const translateContainerOnWheel = (e: WheelEvent) => {
      const preTotal = e.deltaY !== 0 ? total - e.deltaY : total - e.deltaX;
      if (horizontalSectionChild && preTotal !== total) {
        if (preTotal > 0) {
          total = 0;
        } else if (preTotal <= -clientWidth) {
          total = -clientWidth;
        } else {
          total = e.deltaY !== 0 ? (total -= e.deltaY) : (total -= e.deltaX);
        }
        const percentage = (-total / clientWidth) * 100;
        horizontalSectionChild.style.transform = `translateX(${total}px)`;
        if (scrollProgress) {
          scrollProgress.style.width = `${percentage}%`;
        }
      }
    };

    const resetTranslate = useCallback(() => {
      if (scrollProgress && horizontalSectionChild) {
        horizontalSectionChild.style.transitionDelay = `1s`;
        horizontalSectionChild.style.transform = `translateX(${total}px)`;
        scrollProgress.style.transitionDelay = `1s`;
        scrollProgress.style.width = `0%`;
      }
    }, [horizontalSectionChild, scrollProgress, total]);

    const updateScrollProgress = useCallback(() => {
      if (scrollProgress && horizontalSectionChild) {
        const percentage =
          (-horizontalSectionChild.getBoundingClientRect().x / clientWidth) *
            100 <
          1
            ? 0
            : (-horizontalSectionChild.getBoundingClientRect().x /
                clientWidth) *
              100;
        scrollProgress.style.width = `${percentage}%`;
      }
    }, [clientWidth, horizontalSectionChild, scrollProgress]);

    const handleResize = useCallback(() => {
      setClientWidth(getClientWidth());
      setButtonPosition(isBrowser ? window.innerHeight - 80 : 0);
      if (scrollProgress && !mobile) {
        const percentage = (-total / clientWidth) * 100;
        scrollProgress.style.width = `${percentage}%`;
      } else {
        updateScrollProgress();
      }
    }, [
      clientWidth,
      getClientWidth,
      mobile,
      scrollProgress,
      total,
      updateScrollProgress,
    ]);

    useEffect(() => {
      window.addEventListener(`resize`, handleResize);
      return () => {
        window.removeEventListener(`resize`, handleResize);
      };
    }, [isSelected, handleResize]);

    useEffect(() => {
      if (isSelected) {
        if (scrollProgress) {
          scrollProgress.style.width = `0%`;
        }
        if (isBrowser) {
          document.body.style.overflow = `hidden`;
        }
        if (
          horizontalSectionChild &&
          scrollProgress &&
          horizontalSectionScrollable
        ) {
          horizontalSectionChild.style.transitionDelay = `0s`;
          scrollProgress.style.transitionDelay = `0s`;
        }
        setClientWidth(getClientWidth());
        if (!mobile) {
          document.addEventListener(`wheel`, translateContainerOnWheel);
          document.addEventListener(`keydown`, isEscapePressed);
          window.document.body.style.overscrollBehaviorX = `none`;
          window.document.body.style.overscrollBehavior = `none`;
        } else {
          horizontalSectionScrollable?.addEventListener(`scroll`, (e) => {
            e.preventDefault();
            updateScrollProgress();
          });
        }
      } else {
        resetTranslate();
      }
      return () => {
        document.removeEventListener(`wheel`, translateContainerOnWheel);
        document.removeEventListener(`keydown`, isEscapePressed);
        horizontalSectionScrollable?.removeEventListener(`scroll`, (e) => {
          e.preventDefault();
          updateScrollProgress();
        });
      };
    }, [
      isSelected,
      clientWidth,
      getClientWidth,
      horizontalSectionChild,
      horizontalSectionScrollable,
      isEscapePressed,
      mobile,
      resetTranslate,
      scrollProgress,
      updateScrollProgress,
    ]);

    return (
      <Flex overflowY="hidden" position="fixed" h="100vh" ref={ref}>
        <Flex
          px={0.1}
          overflow="hidden"
          overflowX="hidden"
          position="relative"
          w="100vw"
          h="100vh"
          bg="white"
        >
          <Flex
            position="absolute"
            alignItems="center"
            top={
              !mobile && isBrowser ? window.innerHeight - 80 : buttonPosition
            }
            ml={[10, 10, 40, 40, 40]}
            zIndex={7}
            w="auto"
            h="fit-content"
          >
            <Flex
              as={(props) => <Button sx={{ h: 10, px: 0 }} {...props} />}
              w="56px"
              h="56px"
              bg="transparent"
              borderRadius="full"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
              backdropFilter="blur 10px"
              justifyContent="center"
              alignItems="center"
              mr={[4, 12]}
              onClick={() => {
                setIsSelected(false);
                resetScroll();
              }}
              _hover={{
                '.icon': {
                  color: `white`,
                },
              }}
            >
              <Icon className="icon" icon="backward" color="black" size="lg" />
            </Flex>
            <Flex>
              <Box
                w={200}
                position="relative"
                bg="gray.100"
                h="6px"
                zIndex={4}
                borderRadius={9999}
                overflow="hidden"
              >
                <MotionBox
                  className="scrollProgress"
                  position="absolute"
                  style={{
                    x: 0,
                  }}
                  width={200}
                  bg={theme.colors.grad1}
                  h="6px"
                  zIndex={5}
                  borderRadius={9999}
                />
              </Box>
            </Flex>
          </Flex>
          <MotionFlex
            className="scrollYContainer"
            overflow="hidden"
            alignItems="center"
            position="relative"
            mt={6}
          >
            <Flex className="horizontalScroll" overflow="hidden" pt="50px">
              {children}
            </Flex>
          </MotionFlex>
        </Flex>
      </Flex>
    );
  }
);
