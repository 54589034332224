import { forwardRef } from 'react';

import { Box, Flex, FlexProps, Icon, Text } from '@/components/atoms';

import { useTranslation } from '@/hooks';

export interface Props extends FlexProps {
  gradient: string;
}

export const VerifiedBadge = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { gradient, ...rest } = props;
    const { t } = useTranslation();

    return (
      <Flex
        ref={ref}
        bg={gradient}
        borderRadius="full"
        px={3}
        py={1.5}
        {...rest}
      >
        <Flex
          bg="black"
          borderRadius="full"
          justifyContent="center"
          alignItems="center"
          w={22}
          h={22}
          mr={2}
        >
          <Icon icon="check" color="white" size="xs" />
        </Flex>
        <Box>
          <Text variant="4" fontWeight="semibold">
            {t(`homePage.review.verified`)}
          </Text>
        </Box>
      </Flex>
    );
  }
);
