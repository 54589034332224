import { ReactNode, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { motion } from 'framer-motion';

import { TrackingCurrency, TrackingEvent, TrackingValue } from '@/config';

import { _Link } from '@/domain/entities';

import { Box, Button, Flex, FlexProps, Icon } from '@/components/atoms';
import { NumberInput } from '@/components/molecules';

import { useTracking, useTranslation } from '@/hooks';

import { useBannersState, useCart } from '@/state';

interface AddToCartBannerProps extends FlexProps {
  data: {
    variantId: string;
    isAvailableForSale: boolean;
    cartLink: _Link;
    inView: boolean;
    price: string;
    heading: ReactNode;
    sku: string;
  };
}

const MotionBoxContainer = motion(Box);

export function AddToCartBanner(props: AddToCartBannerProps): JSX.Element {
  const { data, ...rest } = props;
  const { track } = useTracking();
  const { setAddToCartBannerVisible } = useBannersState();
  const [quantity, setQuantity] = useState<number>(1);
  const { t } = useTranslation();
  const {
    addCartLine,
    isAddedToCart,
    isCartReady,
    isLoading,
    isReadyToGo,
    setIsAddedToCart,
    setIsReadyToGo,
  } = useCart();

  useEffect(() => {
    if (data.inView) {
      setAddToCartBannerVisible(false);
    } else {
      setAddToCartBannerVisible(true);
    }
  }, [data.inView]);

  const onAddToCart = async () => {
    if (isAddedToCart && !isReadyToGo) {
    } else if (isReadyToGo) {
      setIsAddedToCart(false);
      setIsReadyToGo(false);
      await navigate(data.cartLink.path);
    } else {
      await addCartLine(data.variantId, quantity, undefined);
      track(TrackingEvent.ADD_TO_CART, {
        [TrackingValue.CONTENT_IDS]: [data.sku],
        [TrackingValue.CONTENT_NAME]: data.heading?.toString() || ``,
        [TrackingValue.CONTENT_TYPE]: `product`,
        [TrackingValue.CONTENTS]: [{ id: data.sku, quantity }],
        [TrackingValue.CURRENCY]: TrackingCurrency.EUR,
        [TrackingValue.VALUE]: data.price,
        [TrackingValue.ITEMS]: [
          {
            item_id: data.sku,
            item_name: data.heading,
            currency: `EUR`,
            item_brand: `Hedda Health`,
            item_category: `supplement`,
            price: data.price,
            quantity,
          },
        ],
      });
    }
  };

  const onQuantityChange = (_: string, valueAsNumber: number) => {
    setQuantity(valueAsNumber);
  };

  const renderButtonText = () => {
    if (isReadyToGo) {
      return `Let's go !`;
    } else if (isAddedToCart) {
      return <Icon icon="check" size="lg" />;
    } else {
      return t(`productPage.addToCart`);
    }
  };

  const containerVariants = {
    visible: { y: 0 },
    hidden: { opacity: 0, y: 200 },
  };

  const bannerVariants = {
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
    },
    hidden: { opacity: 0, scale: 0 },
  };

  return (
    <MotionBoxContainer
      variants={containerVariants}
      animate={!data.inView ? `visible` : `hidden`}
      zIndex={20}
      position="fixed"
      bottom="0"
      left={[`50%`, `initial`]}
      right="0"
      mr={[1, 8]}
      ml={[`-49%`, 8]}
      mb={[3, 8]}
    >
      <motion.div
        variants={bannerVariants}
        animate={!data.inView ? `visible` : `hidden`}
        transition={{ duration: 0.3 }}
      >
        <Flex
          flexDirection={[`row`, `row`]}
          alignItems="center"
          w={[`100%`, 380, null, 430]}
          zIndex="20"
          bg="gray.100"
          py={[2, 5, null, 5]}
          px={[2, 8, null, 8]}
          borderRadius={20}
          justifyContent="center"
          boxShadow="box-shadow: 1px 1px 6px -3px rgba(0,0,0,0.75);-webkit-box-shadow: 1px 1px 6px -3px rgba(0,0,0,0.75);-moz-box-shadow: 1px 1px 6px -3px rgba(0,0,0,0.75);"
          {...rest}
        >
          <Box mr={[1, 4]} mb={[0, 0]}>
            <NumberInput
              value={quantity}
              onChange={onQuantityChange}
              min={1}
              max={99}
            />
          </Box>
          <Button
            type="button"
            w="100%"
            rightIcon="shopping-bag"
            onClick={onAddToCart}
            isLoading={isLoading}
            disabled={isLoading || !isCartReady || !data.isAvailableForSale}
          >
            {renderButtonText()}
          </Button>
        </Flex>
      </motion.div>
    </MotionBoxContainer>
  );
}
