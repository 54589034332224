import { forwardRef } from 'react';
import {
  Badge as ChakraBadge,
  BadgeProps,
  useStyleConfig,
} from '@chakra-ui/react';

export type BadgeVariant = `black` | `gray` | `teal`;

export interface Props extends BadgeProps {
  variant?: BadgeVariant;
}

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  (props, ref): JSX.Element => {
    const { children, ...rest } = props;
    const style = useStyleConfig(`Badge`, props);

    return (
      <ChakraBadge ref={ref} sx={style} {...rest}>
        {children}
      </ChakraBadge>
    );
  }
);
