import { toNumber } from '@/utils/toNumber';

export const calculatePriceByCapacity = (
  price: number | string,
  capacity: number
) => {
  return toNumber(price) / capacity;
};

export const calculatePriceByMonth = (price: number | string) => {
  return toNumber(price) / 30;
};
