import {
  _HomeFeature,
  _HomePage,
  _HomeStep,
  GatsbyPrismicHomePage,
  GatsbyPrismicHomePageDataFeaturesv2,
  GatsbyPrismicHomePageDataHomeStepsv2,
  GatsbyPrismicProductsPage,
} from '@/domain/entities';
import { toImage } from '@/domain/mappings/image';
import { toReactNode } from '@/domain/mappings/react';

import { convertStringToArray, createLinkPath } from '@/utils';

export const toHomePage = (
  homePage?: GatsbyPrismicHomePage,
  productsPage?: GatsbyPrismicProductsPage
): _HomePage => ({
  hero: {
    heading: homePage?.data.herov2_title || `Home`,
    headingMobile: homePage?.data.herov2_mobile_title || ``,
    highlightedWords: convertStringToArray(
      homePage?.data.herov2_highlighted_words || ``
    ),
    buttonLink: {
      label: homePage?.data.herov2_button_text || `Home`,
      path: createLinkPath([
        homePage?.lang,
        homePage?.data.herov2_button_link?.uid || ``,
      ]),
    },
    link: {
      label: homePage?.data.herov2_link_text || `Home`,
      path: createLinkPath([
        homePage?.lang,
        homePage?.data.herov2_link?.uid || ``,
      ]),
    },
  },
  features: {
    heading: toReactNode(homePage?.data.featuresv2_title?.richText),
    features:
      homePage?.data.featuresv2?.map((feature, key) =>
        toHomeFeature(
          feature || undefined,
          homePage,
          key === 0 ? productsPage : undefined
        )
      ) || [],
  },
  steps: {
    heading: toReactNode(homePage?.data.home_stepsv2_title?.richText),
    highlightedWords: convertStringToArray(
      homePage?.data.home_stepsv2_highlighted_words || ``
    ),
    buttonText: homePage?.data.home_stepsv2_button_text || `Home`,
    buttonLink: {
      label: homePage?.data.home_stepsv2_button_text || `Home`,
      path: createLinkPath([
        homePage?.lang,
        homePage?.data.home_stepsv2_link?.uid || ``,
      ]),
    },
    steps:
      homePage?.data.home_stepsv2?.map((step, key) =>
        toHomeStep(step || undefined, key + 1)
      ) || [],
  },
});

export const toHomeFeature = (
  homeFeature?: GatsbyPrismicHomePageDataFeaturesv2,
  homePage?: GatsbyPrismicHomePage,
  productsPage?: GatsbyPrismicProductsPage
): _HomeFeature => ({
  heading: toReactNode(homeFeature?.title1?.richText),
  text: toReactNode(homeFeature?.text?.richText),
  bgImage: toImage(homeFeature?.image),
  displayImage: toImage(homeFeature?.display_image),
  buttonText: toReactNode(homeFeature?.button_text?.richText),
  link: {
    label: homeFeature?.link_text || ``,
    path: createLinkPath([
      homePage?.lang,
      productsPage?.uid,
      homeFeature?.link?.uid || ``,
    ]),
  },
});

export const toHomeStep = (
  homeStepV2?: GatsbyPrismicHomePageDataHomeStepsv2,
  number?: number
): _HomeStep => ({
  heading: toReactNode(homeStepV2?.title1?.richText),
  text: toReactNode(homeStepV2?.text?.richText),
  image: toImage(homeStepV2?.image),
  number: number || 0,
});
