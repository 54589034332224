import { forwardRef } from 'react';
import {
  Tag as ChakraTag,
  TagLabel,
  TagLabelProps,
  TagProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { Icons } from '@/config';

import { Icon } from '@/components/atoms';

export type TagSize = `sm` | `md`;

export interface Props extends TagProps {
  icon: Icons;
  iconSize?: number;
  color?: string;
  rightToLeft?: boolean;
  size?: TagSize;
  labelProps?: TagLabelProps;
}

export const Tag = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const {
      children,
      icon,
      iconSize,
      color,
      rightToLeft,
      labelProps,
      ...rest
    } = props;
    const styles = useMultiStyleConfig(`Tag`, props);

    const iconProps = {
      icon,
      color: color as string,
      size: `lg` as SizeProp,
      style: {
        fontSize: iconSize || 16,
        marginLeft: rightToLeft ? 8 : 0,
        marginRight: !rightToLeft ? 8 : 0,
      },
    };

    return (
      <ChakraTag ref={ref} __css={styles.container} {...rest}>
        {!rightToLeft && <Icon {...iconProps} />}
        <TagLabel __css={styles.label} {...labelProps}>
          {children}
        </TagLabel>
        {rightToLeft && <Icon {...iconProps} />}
      </ChakraTag>
    );
  }
);
