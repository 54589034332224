import { useBreakpointValue } from '@chakra-ui/react';

export const useIsMobile = (breakpoint?: string) => {
  const isMobile = useBreakpointValue({
    base: `true`,
    [breakpoint || `md`]: `false`,
  });

  return isMobile === `true`;
};
