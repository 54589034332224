import { forwardRef } from 'react';
import { useHits } from 'react-instantsearch-hooks';

import { Box, Skeleton, SkeletonProps } from '@/components/atoms';

export interface Props extends SkeletonProps {}

export const SearchResultsSkeleton = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { hits } = useHits();

    const renderSocials = hits.map((hit) => (
      <Skeleton
        key={hit.objectID}
        mb={2}
        width={1250}
        height="60px"
        borderRadius={10}
        {...props}
      />
    ));

    return (
      <Box ref={ref} mb={10}>
        {renderSocials}
      </Box>
    );
  }
);
