import { useCallback } from 'react';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import {
  ShopifyStorefrontCustomer,
  ShopifyStorefrontMailingAddress,
  ShopifyStorefrontOrder,
} from '@shopify/types';

import { toArray } from '@/utils';

export interface CustomerState {
  customer: ShopifyStorefrontCustomer;
  isLoggedIn?: boolean;
}

export const customerState = atom<CustomerState>({
  key: `CustomerState`,
  default: {
    customer: {
      id: ``,
      acceptsMarketing: false,
      addresses: {
        edges: [],
        nodes: [],
        pageInfo: {
          hasNextPage: false,
          hasPreviousPage: false,
        },
      },
      createdAt: ``,
      displayName: ``,
      metafields: [],
      numberOfOrders: 0,
      orders: {
        edges: [],
        nodes: [],
        pageInfo: {
          hasNextPage: false,
          hasPreviousPage: false,
        },
        totalCount: 0,
      },
      tags: [],
      updatedAt: ``,
    },
  },
});

export const customerOrders = selector<ShopifyStorefrontOrder[]>({
  key: `CustomerOrdersSelector`,
  get: ({ get }) => {
    const state = get<CustomerState>(customerState);
    return toArray(state.customer.orders).map((order) => order);
  },
});

export const customerAddresses = selector<ShopifyStorefrontMailingAddress[]>({
  key: `CustomerAddressesSelector`,
  get: ({ get }) => {
    const state = get<CustomerState>(customerState);
    return toArray(state.customer.addresses).map((address) => address);
  },
});

export const useCustomer = () => {
  const [state, setState] = useRecoilState(customerState);
  const orders = useRecoilValue(customerOrders);
  const addresses = useRecoilValue(customerAddresses);

  const setCustomer = useCallback(
    (customer: ShopifyStorefrontCustomer) => {
      setState((S) => ({
        ...S,
        customer,
      }));
    },
    [setState]
  );

  const setIsLoggedIn = useCallback(
    (isLoggedIn: boolean) => {
      setState((S) => ({
        ...S,
        isLoggedIn,
      }));
    },
    [setState]
  );

  return {
    setCustomer,
    setIsLoggedIn,
    ...state,
    addresses,
    orders,
  };
};
