import { ReactNode } from 'react';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from '@/components/atoms';

export const toReactNode = (
  richText?: RichTextBlock[],
  shouldHaveElements?: boolean,
  additionalProps?: Record<string, any>
): ReactNode => {
  if (richText && richText.length > 0) {
    return RichText({
      render: richText,
      shouldHaveElements,
      ...additionalProps,
    });
  }
  return ``;
};
