import { forwardRef } from 'react';

import { Box, Flex, Skeleton, SkeletonProps } from '@/components/atoms';

import { useGetUniqueKey } from '@/hooks';

export interface Props extends SkeletonProps {
  number?: number;
}

export const AddressLineSkeleton = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { number, ...rest } = props;

    const getUniqueKey = useGetUniqueKey();

    const renderLines = () => {
      const iterators = Array(number || 1);

      return Array.from(iterators).map((_, key) => (
        <Flex key={getUniqueKey(key)}>
          <Skeleton
            {...rest}
            mb={2}
            width="100%"
            height={108}
            borderRadius="xl"
          />
        </Flex>
      ));
    };

    return <Box ref={ref}>{renderLines()}</Box>;
  }
);
