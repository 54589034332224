import jwtDecode from 'jwt-decode';

type DecodedJwtToken = {
  id: string;
  email: string;
  customerAccessToken: string;
  iat: number;
  exp: number;
};

export const decode = (jwtToken: string) => {
  const decodedToken: DecodedJwtToken = jwtDecode(jwtToken);
  return {
    id: decodedToken.id,
    email: decodedToken.email,
    customerAccessToken: decodedToken.customerAccessToken,
    iat: new Date(decodedToken.iat * 1000).toISOString(),
    exp: new Date(decodedToken.exp * 1000).toISOString(),
  };
};
