import { forwardRef, MouseEvent } from 'react';

import { TrackingContentType, TrackingEvent, TrackingValue } from '@/config';

import { _HelpCategory } from '@/domain/entities';

import {
  Box,
  Flex,
  FlexProps,
  Heading,
  Icon,
  Link,
  Text,
} from '@/components/atoms';

import { useTracking } from '@/hooks';

export interface Props extends FlexProps {
  category: _HelpCategory;
}

export const HelpCategory = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { category, onClick, ...rest } = props;
    const { track } = useTracking();

    const handleCategoryClick = (e: MouseEvent<HTMLDivElement>) => {
      onClick?.(e);
      track(TrackingEvent.SELECT_CONTENT, {
        [TrackingValue.CONTENT_TYPE]: TrackingContentType.HELP_CATEGORY,
        [TrackingValue.ITEM_ID]: category.id,
      });
    };

    return (
      <Flex
        ref={ref}
        as={(props) => (
          <Link
            sx={{
              _active: {
                bg: `gray.100`,
              },
            }}
            title={category.link.label}
            to={category.link.path}
            style={{ textDecoration: `none` }}
            {...props}
          />
        )}
        bg="gray.100"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        pt={16}
        pb={10}
        minW={200}
        h={240}
        borderRadius={20}
        textAlign="center"
        {...rest}
        onClick={handleCategoryClick}
        _hover={{
          '.category': {
            bg: `linear-gradient(90deg, #A770EF 0%, #CF8BF3 50%, #FDB99B 100%)`,
            bgClip: `text`,
            color: `transparent`,
          },
        }}
      >
        <Box
          sx={{
            svg: {
              path: {
                fill: `url(#paint0_linear_3333_9365)`,
              },
            },
          }}
        >
          <svg width="0" height="0">
            <defs>
              <radialGradient
                id="paint0_linear_3333_9365"
                r="150%"
                cx="30%"
                cy="107%"
              >
                <stop stopColor="#A770EF" />
                <stop offset="0.5" stopColor="#CF8BF3" />
                <stop offset="1" stopColor="#FDB99B" />
              </radialGradient>
            </defs>
          </svg>
          <Icon icon={category.icon} size="3x" />
        </Box>
        <Box>
          <Heading className="category" variant="h5">
            {category.heading}
          </Heading>
          <Text variant="4" color="gray.400" fontWeight="medium">
            {category.text}
          </Text>
        </Box>
      </Flex>
    );
  }
);
