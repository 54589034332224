import { IsoCountryCodes } from '@/config';

import { HeddaRepository } from '@/repositories';

interface DetectCountryOutput {
  country: IsoCountryCodes;
}

export class NewHeddaGeolocationRepository extends HeddaRepository {
  async lookup(): Promise<DetectCountryOutput> {
    return await this.client.get<DetectCountryOutput>(`${this.url}/lookup`);
  }
}
