import { RegisterOptions } from 'react-hook-form';

import { FieldValues } from 'react-hook-form/dist/types/fields';
import { useTranslation } from '@/hooks/useTranslation';

const emailRegex = new RegExp(
  `(?:[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])`
);

export const belgianPhoneNumberRegex = new RegExp(
  /^(((\+|00)32[ ]?(?:\(0\)[ ]?)?)|0?){1}(4(60|[789]\d)\/?(\s?\d{2}\.?){2}(\s?\d{2})|(\d\/?\s?\d{3}|\d{2}\/?\s?\d{2})(\.?\s?\d{2}){2})$/
);

export const frenchPhoneNumberRegex = new RegExp(
  /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0?)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/
);

export const switzerlandPhoneNumberRegex = new RegExp(
  /(\b(0041|0?)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/
);

export const luxembourgPhoneNumberRegex = new RegExp(
  /^((\+|00\s?)352)?0?\s?6[269]1(\s?\d{3}){2}$/
);

export const getPhoneNumberRegex = (phoneExtension: string) => {
  switch (phoneExtension) {
    case `+32`:
      return belgianPhoneNumberRegex;
    case `+33`:
      return frenchPhoneNumberRegex;
    case `+41`:
      return switzerlandPhoneNumberRegex;
    case `+352`:
      return luxembourgPhoneNumberRegex;
    default:
      return belgianPhoneNumberRegex;
  }
};

export const useValidation = <F extends FieldValues>() => {
  const { t } = useTranslation();

  const isValidPhoneNumber = (
    value: string
  ): { pattern: RegisterOptions<F>[`pattern`] } => ({
    pattern: {
      value: getPhoneNumberRegex(value),
      message: t(`validation.isValidPhoneNumber`),
    },
  });

  const isEmail: { pattern: RegisterOptions<F>[`pattern`] } = {
    pattern: {
      value: emailRegex,
      message: t(`validation.isEmail`),
    },
  };

  const isRequired: { required: RegisterOptions<F>[`required`] } = {
    required: {
      value: true,
      message: t(`validation.isRequired`) as string,
    },
  };

  const minLength = (
    value: number
  ): { minLength: RegisterOptions<F>[`minLength`] } => ({
    minLength: {
      value,
      message: t(`validation.minLength`, { value }) as string,
    },
  });

  return { isEmail, isRequired, isValidPhoneNumber, minLength };
};
