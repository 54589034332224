import { forwardRef } from 'react';

import { _Link } from '@/domain/entities';

import { Flex, FlexProps, Link, Text } from '@/components/atoms';
import { Cart } from '@/components/icons';

export interface Props extends FlexProps {
  cartLink: _Link;
  totalItemsInCart: number;
}

export const CartFeedback = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { cartLink, totalItemsInCart, ...rest } = props;

    return (
      <Flex
        ref={ref}
        as={(props) => (
          <Link
            sx={{
              _active: {
                bg: `black`,
              },
            }}
            to={cartLink.path}
            style={{ textDecoration: `none` }}
            title={cartLink.label}
            {...props}
          />
        )}
        alignItems="center"
        bg="black"
        borderRadius="full"
        px={5}
        py={3}
        {...rest}
      >
        <Flex
          bg="white"
          borderRadius="full"
          w="28px"
          h="28px"
          justifyContent="center"
          alignItems="center"
          mr={5}
        >
          <Text variant="3" color="black" fontWeight="bold">
            {totalItemsInCart}
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Cart fill="white" width="auto" height="22px" />
        </Flex>
      </Flex>
    );
  }
);
