import { forwardRef, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { _CartLine } from '@/domain/entities';

import {
  Box,
  Flex,
  FlexProps,
  IconButton,
  Image,
  Text,
} from '@/components/atoms';
import { NumberInput } from '@/components/molecules';

import { useCartLineSize, useTranslation } from '@/hooks';

import { useCart } from '@/state';

import { calculatePercentage, toPrice } from '@/utils';

export interface Props extends FlexProps {
  cartLine: _CartLine;
  onDeleteFromCart?: (cartLine: _CartLine) => void;
}

export const CartLine = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { cartLine, onDeleteFromCart, ...rest } = props;
    const { t } = useTranslation();
    const [quantity, setQuantity] = useState<number>(cartLine.quantity);
    const { removeCartLine, updateCartLine } = useCart();
    const cartLineSize = useCartLineSize();
    const debounced = useDebouncedCallback(
      async (id: string, value: number) => {
        await updateCartLine(id, value);
      },
      200
    );

    const onClick = async () => {
      await removeCartLine(cartLine.id);
      onDeleteFromCart?.(cartLine);
    };

    const onQuantityChange = (_: string, valueAsNumber: number) => {
      setQuantity(valueAsNumber);
      debounced(cartLine.id, valueAsNumber);
    };

    const renderCartLineImage = useMemo(
      () => (
        <Image
          image={cartLine.image}
          alt={cartLine.image.alt}
          w={[24, null, 16]}
          h={[24, null, 16]}
        />
      ),
      [cartLine.image]
    );

    return (
      <Flex
        ref={ref}
        flexDirection={[`column`, null, `row`]}
        justifyContent="space-between"
        alignItems="center"
        borderBottomWidth={2}
        borderBottomColor="gray.100"
        borderBottomStyle="solid"
        py={4}
        pr={[null, null, 5]}
        textAlign={[`center`, null, `left`]}
        {...rest}
      >
        <Flex
          flexDirection={[`column`, null, `row`]}
          alignItems="center"
          width={cartLineSize.product}
          mb={[2, null, 0]}
        >
          <Box mr={[0, null, 5]}>{renderCartLineImage}</Box>
          <Box>
            <Text variant="3" fontWeight="semibold">
              {cartLine.name}
            </Text>
            <Text variant="4" color="gray.500">
              {toPrice(
                calculatePercentage(cartLine.price, cartLine.percentage)
              )}
              {` `}·{` `}
              {cartLine.capacity}
              {` `}
              {t(`ProductType.${cartLine.type}`)}
            </Text>
          </Box>
        </Flex>
        <Box
          width={cartLineSize.quantity}
          mb={[1, null, 0]}
          justifyContent="center"
        >
          <NumberInput
            disabled
            value={quantity}
            data-test="input-quantity2"
            onChange={onQuantityChange}
            width={[`30`, null, `60%`]}
            height="80%"
            min={1}
            max={99}
          />
        </Box>
        <Box width={cartLineSize.price} mb={[1, null, 0]}>
          <Text variant="3" fontWeight="semibold">
            {toPrice(cartLine.totalAmount)}
          </Text>
        </Box>
        <Box width={cartLineSize.frequency} mb={[1, null, 0]}>
          <Text variant="3" fontWeight="semibold">
            {cartLine.frequency ? `Tous les mois` : `Une seule fois`}
          </Text>
        </Box>
        <Box width={cartLineSize.actions}>
          <IconButton
            data-test="delete-line-button"
            icon="times"
            aria-label={t(`cart.removeFromCart`)}
            onClick={onClick}
          />
        </Box>
      </Flex>
    );
  }
);
