import { memo } from 'react';

import { _Image } from '@/domain/entities';

import { Box, Button, Image } from '@/components/atoms';

type setMainImageType = {
  readonly index: number;
  readonly image: _Image;
};

export interface ProductHeroImageGalleryItemProps {
  i: number;
  image: _Image;
  setMainImage: ({ index, image }: setMainImageType) => void;
  isCurrent: boolean;
}

export const ProductHeroImageGalleryItem = memo(
  (props: ProductHeroImageGalleryItemProps) => {
    const { i, image, setMainImage, isCurrent } = props;

    return (
      <Box
        as={(props) => (
          <Button
            h="auto"
            bgColor="transparent"
            sx={{
              _hover: {
                color: `transparent`,
              },
            }}
            {...props}
          />
        )}
        key={i}
        onClick={() => setMainImage({ index: i, image })}
        borderRadius={10}
        border="0.5px solid"
        borderColor={isCurrent ? `gray.600` : `gray.300`}
        padding={0.5}
        mb={2}
        mr={[2, null, null, 0]}
        transition=".3s"
        _hover={{ borderColor: `gray.600` }}
      >
        <Image image={image} alt={image.alt} width="50px" />
      </Box>
    );
  },
  (prev, next) => prev.isCurrent === next.isCurrent
);
