import { motion } from 'framer-motion';

export function Informations() {
  return (
    <svg
      width="200"
      height="236"
      viewBox="0 0 228 236"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.700684" width="228" height="235" rx="20" fill="#CBD5E0" />
      <motion.rect
        animate={{
          opacity: [0, 1, 0],
          transition: {
            opacity: {
              repeat: Infinity,
              duration: 3,
              repeatDelay: 1,
              times: [0, 0.5, 1],
            },
          },
        }}
        x="32"
        y="32.7007"
        width="44"
        height="24"
        rx="12"
        fill="#f5f5f5"
      />
      <motion.rect
        animate={{
          opacity: [0, 1, 0],
          transition: {
            opacity: {
              repeat: Infinity,
              duration: 3,
              delay: 0.3,
              repeatDelay: 1,
              times: [0, 0.5, 1],
            },
          },
        }}
        x="92"
        y="32.7007"
        width="104"
        height="24"
        rx="12"
        fill="#f5f5f5"
      />
      <motion.rect
        animate={{
          opacity: [0, 1, 0],
          transition: {
            opacity: {
              repeat: Infinity,
              duration: 2.5,
              delay: 0.6,
              repeatDelay: 1.5,
              times: [0, 0.5, 1],
            },
          },
        }}
        x="32"
        y="76.7007"
        width="104"
        height="24"
        rx="12"
        fill="#f5f5f5"
      />
      <motion.rect
        animate={{
          opacity: [0, 1, 0],
          transition: {
            opacity: {
              repeat: Infinity,
              duration: 2.5,
              delay: 0.9,
              repeatDelay: 1.5,
              times: [0, 0.5, 1],
            },
          },
        }}
        x="152"
        y="76.7007"
        width="44"
        height="24"
        rx="12"
        fill="#f5f5f5"
      />
      <motion.rect
        animate={{
          opacity: [0, 1, 0],
          transition: {
            opacity: {
              repeat: Infinity,
              duration: 2,
              delay: 1.2,
              repeatDelay: 2,
              times: [0, 0.5, 1],
            },
          },
        }}
        x="32"
        y="120.701"
        width="44"
        height="24"
        rx="12"
        fill="#f5f5f5"
      />
      <motion.rect
        animate={{
          opacity: [0, 1, 0],
          transition: {
            opacity: {
              repeat: Infinity,
              duration: 2,
              delay: 1.5,
              repeatDelay: 2,
              times: [0, 0.5, 1],
            },
          },
        }}
        x="92"
        y="120.701"
        width="104"
        height="24"
        rx="12"
        fill="#f5f5f5"
      />
    </svg>
  );
}
