import { motion } from 'framer-motion';

interface Props {
  width?: number;
  height?: number;
}

export function Discussion({ width, height }: Props) {
  return (
    <svg
      width={width || 380}
      height={height || 230}
      viewBox="0 0 429 291"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" y="0.700684" width="228" height="88" rx="44" fill="#CBD5E0" />
      <motion.rect
        animate={{
          opacity: [0, 1, 0],
          transition: {
            opacity: {
              repeat: Infinity,
              duration: 2.5,
              times: [0, 0.5, 1],
            },
          },
        }}
        x="35"
        y="32.7007"
        width="44"
        height="24"
        rx="12"
        fill="white"
      />
      <motion.rect
        animate={{
          opacity: [0, 1, 0],
          transition: {
            opacity: {
              repeat: Infinity,
              duration: 2.5,
              times: [0, 0.5, 1],
            },
          },
        }}
        x="95"
        y="32.7007"
        width="104"
        height="24"
        rx="12"
        fill="white"
      />
      <path
        d="M0 91.7852C14.8732 94.4894 26.1972 86.7148 30 82.4894L29.5775 69.8134C25.493 70.6584 16.6479 71.419 13.9437 67.7007C14.3662 82.912 2.95775 90.5176 0 91.7852Z"
        fill="#CBD5E0"
      />
      <rect x="3" y="196.701" width="168" height="88" rx="44" fill="#CBD5E0" />
      <motion.circle
        animate={{
          scale: [1, 1.2, 1, 1],
        }}
        transition={{
          repeat: Infinity,
          times: [0, 0.05, 0.3, 1],
          duration: 2.5,
          ease: `linear`,
        }}
        cx="47"
        cy="240.701"
        r="12"
        fill="white"
      />
      <motion.circle
        animate={{
          scale: [1, 1.2, 1, 1],
        }}
        transition={{
          repeat: Infinity,
          times: [0, 0.05, 0.3, 1],
          delay: 0.2,
          duration: 2.5,
          ease: `linear`,
        }}
        cx="87"
        cy="240.701"
        r="12"
        fill="white"
      />
      <motion.circle
        animate={{
          scale: [1, 1.2, 1, 1],
        }}
        transition={{
          repeat: Infinity,
          times: [0, 0.05, 0.3, 1],
          delay: 0.4,
          duration: 2.5,
          ease: `linear`,
        }}
        cx="127"
        cy="240.701"
        r="12"
        fill="white"
      />
      <path
        d="M1 289.785C15.8732 292.489 27.1972 284.715 31 280.489L30.5775 267.813C26.493 268.658 17.6479 269.419 14.9437 265.701C15.3662 280.912 3.95775 288.518 1 289.785Z"
        fill="#CBD5E0"
      />
      <path
        d="M423 196.785C408.127 199.489 396.803 191.715 393 187.489L393.423 174.813C397.507 175.658 406.352 176.419 409.056 172.701C408.634 187.912 420.042 195.518 423 196.785Z"
        fill="url(#paint0_linear_2411_8353)"
      />
      <path
        d="M262 142.701C262 118.4 281.699 98.7007 306 98.7007H385C409.301 98.7007 429 118.4 429 142.701C429 167.001 409.301 186.701 385 186.701H306C281.699 186.701 262 167.001 262 142.701Z"
        fill="url(#paint1_linear_2411_8353)"
      />
      <motion.path
        animate={{
          scale: [0, 1, 1],
          transition: {
            scale: {
              repeat: Infinity,
              duration: 2.5,
              repeatDelay: 2.5,
              times: [0, 0.3, 1],
            },
          },
        }}
        d="M322 143.701C322 130.482 332.688 119.701 346 119.701C359.219 119.701 370 130.482 370 143.701C370 157.013 359.219 167.701 346 167.701C332.688 167.701 322 157.013 322 143.701ZM356.781 139.576C357.812 138.544 357.812 136.951 356.781 135.919C355.75 134.888 354.156 134.888 353.125 135.919L343 146.044L338.781 141.919C337.75 140.888 336.156 140.888 335.125 141.919C334.094 142.951 334.094 144.544 335.125 145.576L341.125 151.576C342.156 152.607 343.75 152.607 344.781 151.576L356.781 139.576Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2411_8353"
          x1="262"
          y1="148.022"
          x2="429"
          y2="148.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A770EF" />
          <stop offset="0.5" stopColor="#CF8BF3" />
          <stop offset="1" stopColor="#FDB99B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2411_8353"
          x1="262"
          y1="148.022"
          x2="429"
          y2="148.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A770EF" />
          <stop offset="0.5" stopColor="#CF8BF3" />
          <stop offset="1" stopColor="#FDB99B" />
        </linearGradient>
      </defs>
    </svg>
  );
}
