import { HttpClient } from './HttpClient';

export class GraphQLClient extends HttpClient {
  async send<Response, Variables = Record<string, string>>(
    query: string,
    variables?: Variables
  ): Promise<{ data: Record<string, Response> | undefined }> {
    return await this.post<{ data: Record<string, Response> | undefined }>(
      `/`,
      {
        method: `POST`,
        body: {
          query,
          variables,
        },
        headers: this.defaultHeaders,
      }
    );
  }
}
