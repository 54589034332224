import { createIcon } from '@chakra-ui/icons';

export const Cart = createIcon({
  displayName: `Cart`,
  viewBox: `0 0 21 25`,
  defaultProps: {
    width: `21px`,
    height: `25px`,
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.42871 12.1429C5.42871 12.6951 5.87643 13.1429 6.42871 13.1429C6.981 13.1429 7.42871 12.6951 7.42871 12.1429V9.85715H14.0001V12.1429C14.0001 12.6951 14.4479 13.1429 15.0001 13.1429C15.5524 13.1429 16.0001 12.6951 16.0001 12.1429V9.85715H19.0001V21C19.0001 22.1046 18.1047 23 17.0001 23H4.42871C3.32414 23 2.42871 22.1046 2.42871 21V9.85715H5.42871V12.1429ZM7.42871 7.85715H14.0001V6.14286C14.0001 4.32821 12.5291 2.85715 10.7144 2.85715C8.89977 2.85715 7.42871 4.32821 7.42871 6.14286V7.85715ZM16.0001 7.85715V6.14286C16.0001 3.22364 13.6336 0.857147 10.7144 0.857147C7.79521 0.857147 5.42871 3.22364 5.42871 6.14286V7.85715H1.42871C0.876426 7.85715 0.428711 8.30486 0.428711 8.85715V21C0.428711 23.2091 2.21958 25 4.42871 25H17.0001C19.2093 25 21.0001 23.2091 21.0001 21V8.85715C21.0001 8.30486 20.5524 7.85715 20.0001 7.85715H16.0001Z"
    />
  ),
});
