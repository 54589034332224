import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';

import { useNewHeddaClient, useShopifyClient } from '@/clients';

import { LocalStorage } from '@/config';

import { customerState } from '@/state';

import { decode } from '@/utils';

export const useAuth = () => {
  const shopifyClient = useShopifyClient();
  const heddaClient = useNewHeddaClient();
  const setState = useSetRecoilState(customerState);

  /**
   * Initialize auth
   */
  const initializeAuth = useCallback(async () => {
    const jwtToken =
      localStorage.getItem(LocalStorage.ShopifyJwtToken) || undefined;
    if (jwtToken) {
      return await handleAuth(jwtToken);
    }

    return handleAuth();
  }, []);

  /**
   * Handle auth
   */
  const handleAuth = useCallback(async (jwtToken?: string) => {
    if (jwtToken) {
      const decodedToken = await handleAccessToken(jwtToken);

      if (decodedToken.exp) {
        const isExpired = dayjs(decodedToken.exp).isBefore(new Date());
        if (isExpired) {
          await signOut();
          return undefined;
        } else {
          setState((S) => ({
            ...S,
            isLoggedIn: true,
          }));
          return true;
        }
      }
    }

    setState((S) => ({
      ...S,
      isLoggedIn: false,
    }));

    return false;
  }, []);

  /**
   * Handle access token
   */
  const handleAccessToken = useCallback(async (jwtToken: string) => {
    const jwtDecoded = decode(jwtToken);
    localStorage.setItem(LocalStorage.ShopifyJwtToken, jwtToken);

    return jwtDecoded;
  }, []);

  /**
   * Renew access token
   * Renewal must happen before a token expires.
   * If a token has already expired, a new one should be created instead via customerAccessTokenCreate.
   */
  // const renewAccessToken = useCallback(
  //   async (accessToken: string, expiresAt?: string) => {
  //     const isExpired = dayjs(expiresAt).isBefore(new Date());
  //
  //     if (accessToken && !isExpired) {
  //       return heddaClient.customer.accessTokenRenew({
  //         customerAccessToken: accessToken,
  //       });
  //     } else {
  //       await signOut();
  //       return undefined;
  //     }
  //   },
  //   []
  // );

  /**
   * Sign in as customer with email and password
   */
  const signIn = useCallback(async (email: string, password: string) => {
    const res = await heddaClient.customer.accessTokenCreate({
      email,
      password,
    });

    return await handleAuth(res.jwtToken);
  }, []);

  /**
   * Create a customer account
   * If customer already exist but is not activated, Shopify will send an email with an activation link
   * If not, customer is created + logged in
   */
  const signUp = useCallback(
    async (input: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
    }) => {
      await heddaClient.customer.create(input);
      return await signIn(input.email, input.password);
    },
    []
  );

  /**
   * Activate customer account with an activation link
   */
  const activateAccount = useCallback(
    async (activationUrl: string, password: string) => {
      const res = await heddaClient.customer.activateAccount(
        activationUrl,
        password
      );
      return await handleAuth(res.jwtToken);
    },
    []
  );

  /**
   * Sign out completely
   */
  const signOut = useCallback(async () => {
    const localStorageJwtToken = localStorage.getItem(
      LocalStorage.ShopifyJwtToken
    );

    if (localStorageJwtToken) {
      // await heddaClient.customer.accessTokenDelete(localStorageAccessToken);
      localStorage.removeItem(LocalStorage.ShopifyJwtToken);
      setState((S) => ({
        ...S,
        isLoggedIn: false,
      }));
    }
  }, []);

  /**
   * Send instructions by email to reset password
   */
  const recoverPassword = useCallback(async (email: string) => {
    return await shopifyClient.customer.recoverPassword(email);
  }, []);

  /**
   * Reset password with a reset link
   */
  const resetPassword = useCallback(
    async (resetUrl: string, password: string) => {
      return await shopifyClient.customer.resetPassword(resetUrl, password);
    },
    []
  );

  /**
   * Modify password
   */
  const modifyPassword = useCallback(
    async (email: string, oldPassword: string, newPassword: string) => {
      return await heddaClient.customer.modifyPassword(
        email,
        oldPassword,
        newPassword
      );
    },
    []
  );

  return {
    initializeAuth,
    signIn,
    signUp,
    activateAccount,
    handleAccessToken,
    handleAuth,
    modifyPassword,
    signOut,
    recoverPassword,
    resetPassword,
  };
};
