import { ReactNode } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { useGetUniqueKey } from '@/hooks';

import { Maybe, PageVariant } from '@/types';

type Meta = JSX.IntrinsicElements[`meta`];

export interface Props {
  children?: ReactNode;
  currentPage: PageVariant;
  pageVariants: PageVariant[];
  title?: Maybe<string>;
  description?: Maybe<string>;
  lang?: string;
  meta?: Meta[];
  ogType?: `article` | `website`;
}

export function Seo(props: Props): JSX.Element {
  const {
    children,
    currentPage,
    pageVariants,
    ogType,
    title,
    lang,
    description,
  } = props;
  const getUniqueKey = useGetUniqueKey();
  const { site } = useStaticQuery(query);

  const metaTitle = title || (site.siteMetadata.title as string);

  const metaDescription =
    description || (site.siteMetadata.description as string);

  const metaLang = lang || currentPage.lang;

  const currentUrl = currentPage
    ? `${site.siteMetadata.siteUrl}${currentPage.path}`
    : ``;

  const renderCurrentPage = () => {
    return <link rel="canonical" href={currentUrl} />;
  };

  const renderPageVariants = () => {
    if (currentPage) {
      return [currentPage, ...(pageVariants || [])].map((pageVariant) => (
        <link
          key={getUniqueKey(pageVariant)}
          rel="alternate"
          href={`${site.siteMetadata.siteUrl}${pageVariant.path}`}
          hrefLang={pageVariant.lang}
        />
      ));
    }
    return <link />;
  };

  return (
    <>
      <title>
        {metaTitle}
        {` `}|{` `}
        {site.siteMetadata.title}
      </title>
      <meta name="lang" content={metaLang} />
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={metaTitle} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:url" content={currentUrl} />
      <meta name="og:type" content={ogType || `website`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="Hedda.health" />
      <meta name="twitter:title" content={title || ``} />
      <meta name="fb:app_id" content="1357918094709117" />
      {children}
      {renderCurrentPage()}
      <link
        rel="alternate"
        href={site.siteMetadata.siteUrl}
        hrefLang="x-default"
      />
      {renderPageVariants()}
    </>
  );
}

export const query = graphql`
  fragment AlternateLanguageFragment on PrismicAlternateLanguageType {
    lang
    uid
    type
  }
  query {
    site {
      siteMetadata {
        siteUrl
        title
        description
      }
    }
  }
`;
