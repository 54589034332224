import { isNavigator } from '@/utils/isNavigator';

export const isSafariBrowser = (): boolean => {
  if (isNavigator) {
    return (
      navigator.userAgent.indexOf(`Safari`) !== -1 &&
      navigator.userAgent.indexOf(`Chrome`) === -1
    );
  }
  return false;
};
