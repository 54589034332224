import { forwardRef, MouseEvent } from 'react';
import { useTheme } from '@chakra-ui/react';

import {
  TrackingContentType,
  TrackingCurrency,
  TrackingEvent,
  TrackingInfo,
  TrackingValue,
} from '@/config';

import { _Product } from '@/domain/entities';

import {
  Badge,
  Box,
  BoxProps,
  Button,
  Conditional,
  Flex,
  Heading,
  Icon,
  Image,
  ImageBorder,
  Link,
  Text,
} from '@/components/atoms';

import {
  useGetUniqueKey,
  useIsMobile,
  useTracking,
  useTranslation,
} from '@/hooks';

import { useCart } from '@/state';

import { toTrackingItem } from '@/tracking';

import { toFixedPrice } from '@/utils';

export interface Props extends BoxProps {
  product: _Product;
  trackingInfo?: TrackingInfo;
  index?: number;
}

export const ProductCardV2 = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { onClick, product, trackingInfo, index, ...rest } = props;
    const getUniqueKey = useGetUniqueKey();
    const mobile = useIsMobile();
    const theme = useTheme();
    const { t } = useTranslation();
    const { track } = useTracking();

    const { addCartLine } = useCart();

    const onAddToCart = async () => {
      await addCartLine(product.variantId, 1);

      track(TrackingEvent.ADD_TO_CART, {
        [TrackingValue.CURRENCY]: TrackingCurrency.EUR,
        [TrackingValue.VALUE]: +product.price,
        [TrackingValue.ITEMS]: [
          toTrackingItem.fromProduct({
            item: {
              sku: product.sku,
              name: `${product.name}`,
              price: product.price,
            },
          }),
        ],
      });
    };

    const handleCardClick = (e: MouseEvent<HTMLDivElement>) => {
      onClick?.(e);

      track(TrackingEvent.SELECT_CONTENT, {
        [TrackingValue.CONTENT_TYPE]: TrackingContentType.PRODUCT,
        [TrackingValue.ITEM_ID]: product.id,
      });

      track(TrackingEvent.SELECT_ITEM, {
        [TrackingValue.ITEM_LIST_ID]: trackingInfo?.itemListId,
        [TrackingValue.ITEM_LIST_NAME]: trackingInfo?.itemListName,
        [TrackingValue.ITEMS]: [
          toTrackingItem.fromProduct({
            item: product,
            itemListId: trackingInfo?.itemListId,
            itemListName: trackingInfo?.itemListName,
            position: index,
          }),
        ],
      });
    };

    return (
      <Box
        position="relative"
        _hover={
          mobile
            ? {}
            : {
                '.product-name': {
                  bg: theme.colors.grad1,
                  bgClip: `text`,
                  bgSize: `75%`,
                  color: `transparent`,
                  transition: `.3s`,
                },
                '.border-fill': {
                  opacity: 1,
                  transition: `.3s`,
                },
                '.blur': {
                  opacity: 1,
                  transition: `.3s`,
                },
                '.buy-text': {
                  opacity: 1,
                  mr: 3,
                  transition: `.3s`,
                },
                '.add-box': {
                  width: 165,
                  transition: `.3s`,
                },
              }
        }
      >
        <Box
          ref={ref}
          display="block"
          maxW={360}
          mx="auto"
          textAlign="center"
          position="relative"
          onClick={handleCardClick}
          transition="all .3s"
          {...rest}
        >
          <Conditional isTrue={!mobile}>
            <ImageBorder
              strokeWidth={2}
              width={400}
              height={440}
              left={-4}
              top={-4}
            />
          </Conditional>
          <Flex
            bg="gray.75"
            borderRadius={20}
            flexDirection="column"
            justifyContent="space-between"
            pt={5}
            h="400px"
            pb={4}
            mb={2}
            position="relative"
            willChange="filter"
          >
            <Box>
              <Box>
                <Image
                  image={product.image}
                  alt={product.image.alt}
                  w={[250, 300]}
                />
              </Box>
            </Box>
            <Flex
              className="blur"
              position="absolute"
              w="full"
              h="full"
              top={0}
              borderRadius={20}
              bg="rgba(255, 255, 255, 0.5)"
              boxShadow="0px 0px 20px rgba(0, 0, 0, 0.15)"
              backdropFilter="blur(10px)"
              transition="all .3s"
              opacity={0}
              as={(props) => (
                <Link title={product.name} to={product.link.path} {...props} />
              )}
            >
              <Conditional isTrue={product?.effectsList.length > 0}>
                <Flex
                  justifyContent="center"
                  flexDirection="column"
                  mb={4}
                  mx={5}
                >
                  {product?.effectsList?.map((effect) => (
                    <Flex
                      key={getUniqueKey(effect)}
                      mb={5}
                      _last={{ mb: 0 }}
                      sx={{
                        svg: {
                          path: {
                            fill: `url(#paint0_linear_3333_9364)`,
                          },
                        },
                      }}
                    >
                      <svg width="0" height="0">
                        <defs>
                          <radialGradient
                            id="paint0_linear_3333_9364"
                            r="150%"
                            cx="30%"
                            cy="107%"
                          >
                            <stop stopColor="#A770EF" />
                            <stop offset="0.5" stopColor="#CF8BF3" />
                            <stop offset="1" stopColor="#FDB99B" />
                          </radialGradient>
                        </defs>
                      </svg>
                      <Flex alignItems="center">
                        <Icon icon={effect.icon} />
                        <Text color="black" ml={2}>
                          {effect.effect}
                        </Text>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Conditional>
            </Flex>
            <Flex
              w="100%"
              flexDirection="row"
              alignItems="flex-end"
              justifyContent="space-between"
              mb={0.4}
              px={5}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                bg="black"
                borderRadius={999}
                h="40px"
                px={4}
                color="white"
                zIndex={10}
                mb={[2, 0]}
              >
                {toFixedPrice(product.price)}
              </Flex>
              <Flex
                as={(props) => <Button onClick={onAddToCart} {...props} />}
                className="add-box"
                position="relative"
                borderRadius="full"
                h="40px"
                w="30px"
                px="13px"
                bg="black"
                justifyContent="flex-end"
                alignItems="center"
                overflow="hidden"
                transition="all .3s"
              >
                <Text
                  className="buy-text"
                  position="absolute"
                  mr={-40}
                  pr={2}
                  variant="4"
                  color="white"
                  opacity={0}
                  transition="all .3s"
                >
                  {t(`productPage.addToCart`)}
                </Text>
                <Icon icon="plus" color="white" />
              </Flex>
            </Flex>
          </Flex>
          {product.hasFreeShipping && (
            <Box position="absolute" top={4} left={4}>
              <Badge
                borderRadius={99}
                sx={{
                  bg: `transparent`,
                  border: `2px solid #000000`,
                  textColor: `black`,
                  fontWeight: `600`,
                }}
                color="teal.900"
              >
                {t(`productPage.freeShippingShort`)}
              </Badge>
            </Box>
          )}
          <Box
            as={(props) => (
              <Link
                sx={{
                  _active: {
                    bg: `transparent`,
                  },
                }}
                title={product.name}
                to={product.link.path}
                {...props}
              />
            )}
          >
            <Box
              className="product-name"
              bg="black"
              bgClip="text"
              color="transparent"
              mb={1}
            >
              <Heading variant="h5">{product.name}</Heading>
            </Box>
            <Box mb={1}>
              <Text variant="3" color="gray.500">
                {product.capacity}
                {` `}
                {t(`ProductType.${product.type}`)}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);
