import { forwardRef } from 'react';
import { chakra, TextProps } from '@chakra-ui/react';

export interface Props extends TextProps {}

export const ErrorMessage = forwardRef<HTMLParagraphElement, Props>(
  (props, ref): JSX.Element => {
    const { children, ...rest } = props;

    return (
      <chakra.p
        ref={ref}
        color="red.500"
        fontSize="text.4"
        fontWeight="semibold"
        mt={2}
        {...rest}
      >
        {children}
      </chakra.p>
    );
  }
);
