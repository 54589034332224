import { useCallback } from 'react';
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';
import {
  ShopifyStorefrontCart,
  ShopifyStorefrontCartBuyerIdentityInput,
  ShopifyStorefrontCartDiscountCode,
  ShopifyStorefrontCartLineInput,
  ShopifyStorefrontCurrencyCode,
} from '@shopify/types';

import { useShopifyClient } from '@/clients';

import { LocalStorage } from '@/config';

import { isBrowser, toArray } from '@/utils';

export interface CartState {
  cart: ShopifyStorefrontCart;
  isAddedToCart: boolean;
  isLoading: boolean;
  isReadyToGo: boolean;
}

export const cartState = atom<CartState>({
  key: `CartState`,
  default: {
    cart: {
      attribute: {
        key: ``,
      },
      attributes: [],
      id: ``,
      checkoutUrl: ``,
      createdAt: ``,
      updatedAt: ``,
      totalQuantity: 0,
      buyerIdentity: {
        deliveryAddressPreferences: [],
      },
      deliveryGroups: {
        nodes: [],
        edges: [],
        pageInfo: {
          hasPreviousPage: false,
          hasNextPage: false,
        },
      },
      discountAllocations: [],
      discountCodes: [],
      lines: {
        edges: [],
        nodes: [],
        pageInfo: {
          hasPreviousPage: false,
          hasNextPage: false,
        },
      },
      estimatedCost: {
        subtotalAmount: {
          amount: 0,
          currencyCode: ShopifyStorefrontCurrencyCode.Eur,
        },
        totalAmount: {
          amount: 0,
          currencyCode: ShopifyStorefrontCurrencyCode.Eur,
        },
        checkoutChargeAmount: {
          amount: 0,
          currencyCode: ShopifyStorefrontCurrencyCode.Eur,
        },
      },
      cost: {
        subtotalAmount: {
          amount: 0,
          currencyCode: ShopifyStorefrontCurrencyCode.Eur,
        },
        totalAmount: {
          amount: 0,
          currencyCode: ShopifyStorefrontCurrencyCode.Eur,
        },
        checkoutChargeAmount: {
          amount: 0,
          currencyCode: ShopifyStorefrontCurrencyCode.Eur,
        },
        subtotalAmountEstimated: false,
        totalAmountEstimated: false,
        totalDutyAmountEstimated: false,
        totalTaxAmountEstimated: false,
      },
    },
    isAddedToCart: false,
    isLoading: false,
    isReadyToGo: false,
  },
});

export const isCartReadySelector = selector<boolean>({
  key: `IsCartReadySelector`,
  get: ({ get }) => {
    const state = get<CartState>(cartState);
    const cartId = state.cart.id;

    return cartId.length > 0;
  },
});

export const totalItemsInCartSelector = selector<number>({
  key: `TotalItemsInCartSelector`,
  get: ({ get }) => {
    const state = get<CartState>(cartState);

    return toArray(state.cart.lines).reduce(
      (acc, cartLine) => acc + cartLine.quantity,
      0
    );
  },
});

export const discountCodesSelector = selector<
  Array<ShopifyStorefrontCartDiscountCode>
>({
  key: `DiscountCodesSelector`,
  get: ({ get }) => {
    const state = get<CartState>(cartState);

    return state.cart.discountCodes;
  },
});

export const discountedAmountSelector = selector<number>({
  key: `DiscountedAmountSelector`,
  get: ({ get }) => {
    const state = get<CartState>(cartState);

    return state.cart.discountAllocations.reduce(
      (acc, discountAllocation) =>
        acc + +discountAllocation.discountedAmount.amount,
      0
    );
  },
});

export const useCart = () => {
  const [state, setState] = useRecoilState<CartState>(cartState);
  const isCartReady = useRecoilValue(isCartReadySelector);
  const totalItemsInCart = useRecoilValue(totalItemsInCartSelector);
  const discountCodes = useRecoilValue(discountCodesSelector);
  const discountedAmount = useRecoilValue(discountedAmountSelector);
  const client = useShopifyClient();
  const resetCartState = useResetRecoilState(cartState);

  const setCart = useCallback(
    (cart: ShopifyStorefrontCart) => {
      if (cart) {
        if (isBrowser) {
          localStorage.setItem(LocalStorage.ShopifyCartId, cart.id);
        }

        setState((S) => ({
          ...S,
          cart,
        }));
      }
    },
    [setState]
  );

  const setIsAddedToCart = useCallback(
    (isAddedToCart: boolean) => {
      setState((S) => ({
        ...S,
        isAddedToCart,
      }));
    },
    [setState]
  );

  const setIsLoading = useCallback(
    (isLoading: boolean) => {
      setState((S) => ({
        ...S,
        isLoading,
      }));
    },
    [setState]
  );

  const setIsReadyToGo = useCallback(
    (isReadyToGo: boolean) => {
      setState((S) => ({
        ...S,
        isReadyToGo,
      }));
    },
    [setState]
  );

  const initializeCart = useCallback(async () => {
    setIsLoading(true);
    const existingCartId = isBrowser
      ? localStorage.getItem(LocalStorage.ShopifyCartId)
      : null;

    if (existingCartId && existingCartId !== `null`) {
      try {
        const existingCart = await client.cart.findOne(existingCartId);

        if (existingCart) {
          setCart(existingCart);
          setIsLoading(false);
          return existingCart;
        }
      } catch (e) {
        localStorage.removeItem(LocalStorage.ShopifyCartId);
      }
    }

    const newCart = await client.cart.create();
    if (newCart) {
      setCart(newCart);
      setIsLoading(false);
      return newCart;
    }
    return false;
  }, [client.cart, setCart, setIsLoading, state.isLoading]);

  const addCartLine = useCallback(
    async (variantId: string, quantity: number, sellingPlanId?: string) => {
      setIsLoading(true);

      const cartLines: ShopifyStorefrontCartLineInput[] = [
        {
          merchandiseId: variantId,
          quantity,
          sellingPlanId,
        },
      ];

      const newCart = await client.cart.addCartLines(state.cart.id, cartLines);
      if (newCart) {
        setCart(newCart);
        setIsLoading(false);
        setIsAddedToCart(true);
      }

      if (!state.isReadyToGo) {
        window.setTimeout(() => {
          setIsReadyToGo(true);
        }, 1000);
      }
    },
    [
      client.cart,
      setCart,
      setIsAddedToCart,
      setIsLoading,
      setIsReadyToGo,
      state.cart.id,
      state.isReadyToGo,
    ]
  );

  const addCartLines = useCallback(
    async (
      lines: { variantId: string; quantity: number; sellingPlanId?: string }[]
    ) => {
      setIsLoading(true);

      const cartLines: ShopifyStorefrontCartLineInput[] = lines.map((line) => ({
        merchandiseId: line.variantId,
        quantity: line.quantity,
        sellingPlanId: line.sellingPlanId,
      }));

      const newCart = await client.cart.addCartLines(state.cart.id, cartLines);

      if (newCart) {
        setCart(newCart);
        setIsLoading(false);
        setIsAddedToCart(true);
      }

      if (!state.isReadyToGo) {
        window.setTimeout(() => {
          setIsReadyToGo(true);
        }, 1000);
      }
    },
    [
      client.cart,
      setCart,
      setIsAddedToCart,
      setIsLoading,
      setIsReadyToGo,
      state.cart.id,
      state.isReadyToGo,
    ]
  );

  const removeCartLine = useCallback(
    async (cartLineId: string) => {
      setIsLoading(true);

      const newCart = await client.cart.removeCartLines(state.cart.id, [
        cartLineId,
      ]);
      if (newCart) {
        setCart(newCart);
        setIsLoading(false);
      }
    },
    [client.cart, setCart, setIsLoading, state.cart.id]
  );

  const removeCartLines = useCallback(async () => {
    setIsLoading(true);
    const cartLinesId = toArray(state.cart.lines)?.map((lines) => lines.id);

    const newCart = await client.cart.removeCartLines(
      state.cart.id,
      cartLinesId
    );

    if (newCart) {
      setCart(newCart);
      setIsLoading(false);
    }
  }, [client.cart, setCart, setIsLoading, state.cart.id]);

  const updateCartBuyerIdentity = useCallback(
    async (
      cartId: string,
      buyerIdentity: ShopifyStorefrontCartBuyerIdentityInput
    ) => {
      setIsLoading(true);
      const newCart = await client.cart.cartBuyerIdentityUpdate(
        cartId,
        buyerIdentity
      );
      if (newCart) {
        setCart(newCart);
        setIsLoading(false);
      }
    },
    [client.cart, setCart, setIsLoading]
  );

  const updateCartLine = useCallback(
    async (cartLineId: string, quantity: number) => {
      setIsLoading(true);

      const updatedCart = await client.cart.updateCartLines(state.cart.id, [
        {
          id: cartLineId,
          quantity,
        },
      ]);
      if (updatedCart) {
        setCart(updatedCart);
        setIsLoading(false);
      }
    },
    [client.cart, setCart, setIsLoading, state.cart.id]
  );

  const applyDiscount = useCallback(
    async (discountCodes?: string) => {
      if (discountCodes) {
        const discountApplied = await client.cart.applyDiscounts(
          state.cart.id,
          [discountCodes]
        );
        if (
          discountApplied?.discountCodes[0].applicable &&
          discountApplied?.discountAllocations.length
        ) {
          setCart(discountApplied);
          return true;
        } else {
          setIsLoading(false);
          return false;
        }
      } else {
        setIsLoading(true);
        const discountRemoved = await client.cart.applyDiscounts(
          state.cart.id,
          [``]
        );
        if (discountRemoved) {
          setIsLoading(false);
          setCart(discountRemoved);
          return null;
        }
      }
      setIsLoading(false);
      return null;
    },
    [client.cart, setCart, setIsLoading, state.cart.id]
  );

  const resetCart = useCallback(() => {
    resetCartState();
    localStorage.removeItem(LocalStorage.ShopifyCartId);
  }, []);

  return {
    applyDiscount,
    discountCodes,
    discountedAmount,
    isCartReady,
    totalItemsInCart,
    initializeCart,
    addCartLine,
    addCartLines,
    removeCartLine,
    removeCartLines,
    updateCartBuyerIdentity,
    updateCartLine,
    setIsAddedToCart,
    setIsLoading,
    setIsReadyToGo,
    resetCart,
    ...state,
  };
};
