import { forwardRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useDebouncedCallback } from 'use-debounce';
import { ShopifyStorefrontCartLine } from '@shopify/types';

import { GatsbyPrismicProductConnection } from '@/domain/entities';

import {
  Box,
  Flex,
  FlexProps,
  IconButton,
  Image,
  Text,
} from '@/components/atoms';
import { PreviewNumberInput } from '@/components/molecules';

import { useTranslation } from '@/hooks';

import { useCart } from '@/state';

import { toArray, toPrice } from '@/utils';

export interface Props extends FlexProps {
  cartLine: ShopifyStorefrontCartLine;
}

export const CartPreviewLine = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { cartLine, ...rest } = props;
    const { updateCartLine, removeCartLine } = useCart();
    const debounced = useDebouncedCallback(
      async (id: string, value: number) => {
        await updateCartLine(id, value);
      },
      150
    );
    const [quantity, setQuantity] = useState<number>(cartLine.quantity);
    const productImages =
      useStaticQuery<
        Record<`allPrismicProduct`, GatsbyPrismicProductConnection>
      >(imagesQuery);
    const { t } = useTranslation();
    const matchingProduct = toArray(productImages.allPrismicProduct).find(
      (product) =>
        product.data.product?.handle === cartLine.merchandise.product.handle
    );

    // @ts-ignore
    const productCapacity = cartLine.merchandise.product.productCapacity.value;
    // @ts-ignore
    const productType = cartLine.merchandise.product.productType.value;

    const onQuantityChange = (_: string, valueAsNumber: number) => {
      setQuantity(valueAsNumber);
      debounced(cartLine.id, valueAsNumber);
    };

    const onClick = async () => {
      await removeCartLine(cartLine.id);
    };

    return (
      <Flex
        flexDirection={[`column`, null, `row`]}
        justifyContent="space-between"
        alignItems="center"
        borderBottomWidth={2}
        borderBottomColor="gray.75"
        borderBottomStyle="solid"
        py={4}
        w="100%"
        textAlign="left"
        ref={ref}
        {...rest}
      >
        <Flex
          flexDirection={[`column`, null, `row`]}
          alignItems="center"
          mb={[2, null, 0]}
          w="100%"
        >
          <Flex alignItems="flex-start" w="100%">
            {matchingProduct?.data.image && (
              <Box w="30%">
                <Image
                  alt={matchingProduct.data.image.alt || ``}
                  image={matchingProduct.data.image.gatsbyImageData}
                  w="65px"
                  h="65px"
                  mr={3}
                />
              </Box>
            )}
            <Flex direction="column" mt={2} w="100%">
              <Box>
                <Text variant="2" mr={3}>
                  {cartLine.merchandise.product.title}
                </Text>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text variant="4" color="gray.500">
                    {toPrice(cartLine.merchandise.priceV2.amount)}
                    {` `}·{` `}
                    {productCapacity}
                    {` `}
                    {t(`ProductType.${productType}`)}
                  </Text>
                </Flex>
              </Box>
              <Flex mt={3} alignItems="center" w="100%" justify="space-between">
                <Flex>
                  <IconButton
                    variant="numberInput"
                    data-test="delete-line-button"
                    icon="times"
                    aria-label={t(`cart.removeFromCart`)}
                    onClick={onClick}
                    borderWidth={2}
                    borderColor="gray.75"
                    w="7px"
                    mr={1}
                  />
                  <PreviewNumberInput
                    onInputChange={onQuantityChange}
                    defaultValue={cartLine.quantity}
                    value={quantity}
                  />
                </Flex>
                <Text fontWeight="semibold">
                  {toPrice(cartLine.estimatedCost.totalAmount.amount)}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  }
);

const imagesQuery = graphql`
  query ImagesQuery {
    allPrismicProduct {
      edges {
        node {
          data {
            image {
              alt
              gatsbyImageData(placeholder: BLURRED)
            }
            product {
              handle
              title
            }
          }
        }
      }
    }
  }
`;
