import {
  faArrowLeft,
  faArrowRight,
  faAt,
  faCalendarCheck,
  faCalendarDay,
  faCapsules,
  faClipboardListCheck,
  faClock,
  faCreditCard,
  faCreditCardBlank,
  faDumbbell,
  faEye,
  faEyeSlash,
  faFaceSmileHearts,
  faGlobeEurope,
  faHandPaper,
  faHeadSideBrain,
  faHeartbeat,
  faLock,
  faMobile,
  faQuestion,
  faShieldCheck,
  faShippingFast,
  faUndoAlt,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAnglesDown,
  faArrowDown,
  faArrowLeft as faArrowLeftRegular,
  faAt as faAtRegular,
  faBackward,
  faCalendarClock,
  faCheck,
  faChevronDoubleRight,
  faClipboardList,
  faFaceSmilePlus,
  faForward,
  faHeart,
  faMattressPillow,
  faPaperPlane,
  faPenToSquare,
  faShieldHeart,
  faShieldVirus,
  faShoppingBag,
  faSignOutAlt,
  faSnooze,
  faStar as faStarRegular,
  faTimes,
  faTrash,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowLeft as faArrowLeftSolid,
  faArrowRight as faArrowRightSolid,
  faArrowUpRightFromSquare,
  faBaby,
  faBolt,
  faBone,
  faBoneBreak,
  faBrain,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChild,
  faCircleCheck,
  faCircleDot,
  faClipboard,
  faCookie,
  faFaceSadTear,
  faFaceScream,
  faFaceTired,
  faFaceTissue,
  faFingerprint,
  faFire,
  faGlass,
  faGlasses,
  faHandDots,
  faHeart as faHeartSolid,
  faJoint,
  faLeaf,
  faLollipop,
  faLungs,
  faMartiniGlass,
  faMinus,
  faMoon,
  faPeopleGroup,
  faPersonBreastfeeding,
  faPersonCane,
  faPlus,
  faQuestionCircle,
  faSalad,
  faShoePrints,
  faStar,
  faStarHalfAlt,
  faStars,
  faStethoscope,
  faStomach,
  faSwords,
  faSyringe,
  faTooth,
  faUserHairLong,
  faUserHeadset,
  faVial,
  faVirus,
  faWatchApple,
  faWatchSmart,
  faWavePulse,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookF,
  faLinkedinIn,
  faPinterest,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

export type Icons =
  | IconName
  | `${IconName}_light`
  | `${IconName}_regular`
  | `${IconName}_solid`;

export const Icon: { [K in Icons]?: IconDefinition } = {
  'angles-down': faAnglesDown,
  'arrow-down': faArrowDown,
  'arrow-left': faArrowLeft,
  'arrow-left_regular': faArrowLeftRegular,
  'arrow-left_solid': faArrowLeftSolid,
  'arrow-right': faArrowRight,
  'arrow-right_solid': faArrowRightSolid,
  'arrow-up-right-from-square': faArrowUpRightFromSquare,
  at: faAt,
  at_regular: faAtRegular,
  baby: faBaby,
  backward: faBackward,
  bolt: faBolt,
  bone: faBone,
  'bone-break': faBoneBreak,
  brain: faBrain,
  capsules: faCapsules,
  'calendar-day': faCalendarDay,
  'calendar-check': faCalendarCheck,
  'calendar-clock': faCalendarClock,
  check: faCheck,
  'chevron-double-right': faChevronDoubleRight,
  'chevron-down': faChevronDown,
  'chevron-left': faChevronLeft,
  clipboard: faClipboard,
  'chevron-right': faChevronRight,
  child: faChild,
  'circle-dot': faCircleDot,
  'circle-check': faCircleCheck,
  'clipboard-list': faClipboardList,
  'clipboard-list-check': faClipboardListCheck,
  clock: faClock,
  cookie: faCookie,
  'credit-card': faCreditCard,
  'credit-card-blank': faCreditCardBlank,
  dumbbell: faDumbbell,
  facebook: faFacebookF,
  'face-tired': faFaceTired,
  fingerprint: faFingerprint,
  'face-sad-tear': faFaceSadTear,
  'face-scream': faFaceScream,
  'face-smile-plus': faFaceSmilePlus,
  'face-smile-hearts': faFaceSmileHearts,
  'face-tissue': faFaceTissue,
  eye: faEye,
  'eye-slash': faEyeSlash,
  fire: faFire,
  forward: faForward,
  glass: faGlass,
  glasses: faGlasses,
  'globe-europe': faGlobeEurope,
  'hand-dots': faHandDots,
  'hand-paper': faHandPaper,
  'head-side-brain': faHeadSideBrain,
  heart: faHeart,
  heart_solid: faHeartSolid,
  heartbeat: faHeartbeat,
  joint: faJoint,
  leaf: faLeaf,
  linkedin: faLinkedinIn,
  lock: faLock,
  'mattress-pillow_regular': faMattressPillow,
  lollipop: faLollipop,
  lungs: faLungs,
  'martini-glass': faMartiniGlass,
  mobile: faMobile,
  moon: faMoon,
  minus: faMinus,
  'paper-plane_light': faPaperPlane,
  'people-group': faPeopleGroup,
  'person-breastfeeding': faPersonBreastfeeding,
  'person-cane': faPersonCane,
  'pen-to-square': faPenToSquare,
  pinterest: faPinterest,
  plus: faPlus,
  question: faQuestion,
  'question-circle': faQuestionCircle,
  salad: faSalad,
  'shield-check': faShieldCheck,
  'shield-heart': faShieldHeart,
  'shield-virus': faShieldVirus,
  'shipping-fast': faShippingFast,
  'shopping-bag': faShoppingBag,
  'shoe-prints': faShoePrints,
  'sign-out-alt': faSignOutAlt,
  snooze: faSnooze,
  star: faStar,
  stars: faStars,
  star_regular: faStarRegular,
  'star-half-alt': faStarHalfAlt,
  stethoscope: faStethoscope,
  stomach: faStomach,
  swords: faSwords,
  syringe: faSyringe,
  times: faTimes,
  tooth: faTooth,
  trash: faTrash,
  twitter: faTwitter,
  'undo-alt': faUndoAlt,
  user: faUser,
  'user-hair-long': faUserHairLong,
  'user-headset': faUserHeadset,
  vial: faVial,
  virus: faVirus,
  'watch-apple': faWatchApple,
  'wave-pulse': faWavePulse,
  'watch-smart': faWatchSmart,
  xmark: faXmark,
};
