import { forwardRef, ReactNode } from 'react';

import { Box, Flex, FlexProps, Text } from '@/components/atoms';

export interface Props extends FlexProps {
  label: ReactNode;
  value: ReactNode;
  isTotal?: boolean;
}

export const SummaryLine = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { label, value, isTotal, ...rest } = props;

    const fontWeight = isTotal ? `semibold` : `medium`;

    return (
      <Flex ref={ref} justifyContent="space-between" mb={5} {...rest}>
        <Box>
          <Text variant="3" fontWeight={fontWeight}>
            {label}
          </Text>
        </Box>
        <Box>
          <Text variant="3" fontWeight={fontWeight}>
            {value}
          </Text>
        </Box>
      </Flex>
    );
  }
);
