import { cartFragment } from '@/graphql';

import { graphql } from '@/utils';

export const cartBuyerIdentityUpdateMutation = graphql`
  mutation CartBuyerIdentityUpdate(
    $cartId: ID!
    $buyerIdentity: CartBuyerIdentityInput!
  ) {
    cart: cartBuyerIdentityUpdate(
      cartId: $cartId
      buyerIdentity: $buyerIdentity
    ) {
      cart {
        ...CartFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${cartFragment}
`;
