import { forwardRef } from 'react';

import ArrowRightArrowLeft from '@/svg/Highlights/arrow-right-arrow-left.svg';
import CreditCard from '@/svg/Highlights/credit-card.svg';
import MessageQuestion from '@/svg/Highlights/message-question.svg';
import TruckBolt from '@/svg/Highlights/truck-bolt.svg';

import { HighlightIcons } from '@/config';

import { _Highlight } from '@/domain/entities';

import { Box, BoxProps, Flex, Heading, Text } from '@/components/atoms';

export interface Props extends BoxProps {
  highlight: _Highlight;
}

export const Highlight = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { highlight, ...rest } = props;

    const renderIcon = () => {
      switch (highlight.icon) {
        case HighlightIcons.arrow: {
          return <ArrowRightArrowLeft />;
        }
        case HighlightIcons.credit: {
          return <CreditCard />;
        }
        case HighlightIcons.message: {
          return <MessageQuestion />;
        }
        case HighlightIcons.truck: {
          return <TruckBolt />;
        }
        default: {
          return <></>;
        }
      }
    };

    return (
      <Box
        ref={ref}
        as="article"
        bg="#E6EDF4"
        w={[`100%`, 410]}
        h={[`fit-content`, 410]}
        borderRadius={20}
        padding={10}
        {...rest}
      >
        <Flex mb={5} justifyContent={[`center`, null, `left`]}>
          {renderIcon()}
        </Flex>
        <Box mb={2}>
          <Heading as="h3" variant="h4">
            {highlight.heading}
          </Heading>
        </Box>
        <Box>
          <Text variant="3" color="gray.500">
            {highlight.text}
          </Text>
        </Box>
        {/*<Box>*/}
        {/*  <Tag*/}
        {/*    as={(props) => <Link to={highlight.link.path} {...props} />}*/}
        {/*    icon="arrow-right_solid"*/}
        {/*    iconSize={12}*/}
        {/*    rightToLeft*/}
        {/*  >*/}
        {/*    {highlight.link.label}*/}
        {/*  </Tag>*/}
        {/*</Box>*/}
      </Box>
    );
  }
);
