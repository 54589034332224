import { useCallback } from 'react';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';

import { Country } from '@/config';

export interface I18nState {
  country?: Country;
  suggestedCountry?: Country;
  invalidCountry: boolean;
}

export const i18nState = atom<I18nState>({
  key: `I18nState`,
  default: {
    invalidCountry: false,
  },
});

export const useI18n = () => {
  const [state, setState] = useRecoilState<I18nState>(i18nState);

  const setCountry = useCallback(
    (country: Country) => {
      setState((S) => ({
        ...S,
        country,
      }));
    },
    [setState]
  );

  const setSuggestedCountry = useCallback(
    (suggestedCountry: Country) => {
      setState((S) => ({
        ...S,
        suggestedCountry,
      }));
    },
    [setState]
  );

  const setInvalidCountry = useCallback(
    (invalidCountry: boolean) => {
      setState((S) => ({
        ...S,
        invalidCountry,
      }));
    },
    [setState]
  );

  return {
    ...state,
    setCountry,
    setSuggestedCountry,
    setInvalidCountry,
  };
};

const prices = {
  BE: {
    price: 6,
  },
  FR: {
    price: 4.99,
  },
};

export const countryShippingPrice = selector<number>({
  key: `CountryShippingPrice`,
  get: ({ get }) => {
    const state = get(i18nState);
    return state.country?.iso ? prices[state.country.iso].price : 0;
  },
});

export const useCountryShippingPrice = (): number => {
  return useRecoilValue<number>(countryShippingPrice);
};
