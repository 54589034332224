import { forwardRef } from 'react';

import { _Accordion, _SearchResult } from '@/domain/entities';
import { toReactNode } from '@/domain/mappings';

import { RoundedAccordion } from '@/components/molecules';

import { useI18n } from '@/state';

export interface Props {
  searchResults: _SearchResult[];
  isLoading?: boolean;
}

export const SearchResults = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { searchResults, isLoading, ...rest } = props;
    const { country } = useI18n();
    const accordions = searchResults
      .filter((searchResult) => searchResult.lang === country?.path)
      .map<_Accordion>((searchResult) => ({
        heading: searchResult.name,
        text: toReactNode(searchResult.content),
      }));

    return (
      <RoundedAccordion
        textColor="black"
        radius={10}
        light
        ref={ref}
        {...rest}
        accordions={accordions}
        isLoading={isLoading}
        _last={{ mb: 0 }}
      />
    );
  }
);
