import { useState } from 'react';
import { navigate } from 'gatsby';

import { Country } from '@/config';

import { Box, Button, Flex, FlexProps, Icon, Text } from '@/components/atoms';
import { CountrySwitcher } from '@/components/molecules';

import { useTranslation } from '@/hooks';

import { useI18n } from '@/state';

export interface Props extends FlexProps {}

export function InvalidCountry(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { setCountry, setInvalidCountry, suggestedCountry } = useI18n();
  const [selectedCountry, setSelectedCountry] = useState<Country>();

  const onClick = () => {
    setInvalidCountry(false);
  };

  const handleClick = (country: Country) => {
    setSelectedCountry(country);
  };

  const onContinue = async () => {
    if (selectedCountry) {
      setInvalidCountry(false);
      setCountry(selectedCountry);
      await navigate(`/${selectedCountry.path}`);
    }
  };

  return (
    <Flex
      bg="gray.100"
      py={[5, null, 3]}
      px={[5, null, 0]}
      flexDirection={[`column`, null, null, `row`]}
      justifyContent="center"
      alignItems="center"
      position="relative"
      {...props}
    >
      <Box mr={[null, null, null, 5]} mb={[5, null, null, 0]}>
        <Text variant="3" fontWeight="semibold">
          {t(`invalidCountry.text`)}
        </Text>
      </Box>
      <Flex flexDirection={[`column`, `row`]} alignItems="center">
        <Box w="100%" mr={2} mb={[2, 0]}>
          <CountrySwitcher
            handleClick={handleClick}
            suggestedCountry={suggestedCountry}
          />
        </Box>
        <Box w="100%" mr={[0, 5]}>
          <Button h={12} onClick={onContinue}>
            {t(`common.continue`)}
          </Button>
        </Box>
      </Flex>
      <Box
        as={(props) => (
          <Button borderRadius={0} color="black" h={8} {...props} />
        )}
        onClick={onClick}
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
        right={2}
        py={1}
        px={2}
        transition="background-color .2s"
        bg={[`gray.200`, null, `transparent`]}
        _hover={{
          bg: `gray.200`,
        }}
        // @ts-ignore
        disabled={!selectedCountry}
      >
        <Icon icon="times" />
      </Box>
    </Flex>
  );
}
