import { forwardRef } from 'react';

import { _Product } from '@/domain/entities';

import { Box, Flex, Text } from '@/components/atoms';

import { useTranslation } from '@/hooks';

import { DefaultProps } from '@/types';

interface ProductLineData {
  product: _Product;
}

export interface Props extends DefaultProps<ProductLineData> {}

export const ProductLine = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { data, ...rest } = props;
    const { t } = useTranslation();

    return (
      <Flex
        ref={ref}
        as="article"
        borderWidth={2}
        borderStyle="solid"
        borderColor="gray.200"
        borderRadius={8}
        p={4}
        _hover={
          {
            // borderColor: `black`,
          }
        }
        // transition="border-color .2s"
        {...rest}
      >
        <Box>
          <Text variant="3" fontWeight="semibold">
            {data.product.name}
          </Text>
        </Box>
        <Box ml="auto">
          <Text variant="4" color="gray.500">
            {data.product.capacity}
            {` `}
            {t(`ProductType.${data.product.type}`)}
          </Text>
        </Box>
      </Flex>
    );
  }
);
