import { _BlogPost } from '@/domain/entities';

const readingTime = require(`reading-time/lib/reading-time`);

export const toBlogPostDate = (blogPost: _BlogPost): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: `numeric`,
    month: `short`,
    year: `numeric`,
  };

  const time = Math.round(readingTime(blogPost?.bodyText || ``).minutes);

  return `${blogPost.firstPublicationDate.toLocaleDateString(
    undefined,
    options
  )} · ${time} min.`;
};
