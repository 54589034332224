import {
  _AccountLayout,
  _AccountLayoutData,
  _AuthLayout,
  _AuthLayoutData,
  _Layout,
  _LayoutData,
  GatsbyPrismicAccountPage,
  GatsbyPrismicBlogPage,
  GatsbyPrismicCartPage,
  GatsbyPrismicCreateAccountPage,
  GatsbyPrismicHeddaPage,
  GatsbyPrismicHelpPage,
  GatsbyPrismicHomePage,
  GatsbyPrismicLoginPage,
  GatsbyPrismicMobileAppPage,
  GatsbyPrismicOrdersPage,
  GatsbyPrismicPasswordForgottenPage,
  GatsbyPrismicPasswordResetPage,
  GatsbyPrismicProductsPage,
} from '@/domain/entities';
import {
  toDownloadAppSection,
  toHighlightsSection,
  toNewsletterSection,
} from '@/domain/mappings/section';

import { useTranslation } from '@/hooks';

import { createLinkPath, toArray, transformPageLinks } from '@/utils';

export const toLayout = (
  data: _LayoutData,
  ordersPage?: GatsbyPrismicOrdersPage
): _Layout => ({
  downloadAppSection: toDownloadAppSection(data.downloadAppSection?.data),
  highlightsSection: toHighlightsSection(data.highlightsSection?.data),
  newsletterSection: toNewsletterSection(data.newsletterSection?.data),
  legalLinks: toArray(data.legalPages).map((legalPage) => ({
    label: legalPage.data.title || ``,
    path: createLinkPath([legalPage.lang, legalPage.uid || ``]),
  })),
  navLinks: transformPageLinks([
    data.productsPage as GatsbyPrismicProductsPage,
    data.mobileAppPage as GatsbyPrismicMobileAppPage,
    data.blogPage as GatsbyPrismicBlogPage,
    data.helpPage as GatsbyPrismicHelpPage,
    data.heddaPage as GatsbyPrismicHeddaPage,
  ]),
  pageLinks: transformPageLinks([
    data.homePage as GatsbyPrismicHomePage,
    data.productsPage as GatsbyPrismicProductsPage,
    data.mobileAppPage as GatsbyPrismicMobileAppPage,
    data.blogPage as GatsbyPrismicBlogPage,
    data.heddaPage as GatsbyPrismicHeddaPage,
  ]),
  supportLinks: transformPageLinks([
    data.helpPage as GatsbyPrismicHelpPage,
    data.cartPage as GatsbyPrismicCartPage,
    data.accountPage as GatsbyPrismicAccountPage,
  ]),
  accountLink: {
    label: data.accountPage?.data.title || ``,
    path: createLinkPath([
      data.homePage?.lang,
      data.accountPage?.uid,
      ordersPage?.uid,
    ]),
  },
  cartLink: {
    path: createLinkPath([data.homePage?.lang, data.cartPage?.uid]),
  },
  homeLink: {
    label: data.homePage?.data.title || ``,
    path: createLinkPath([data.homePage?.lang]),
  },
  productsLink: {
    path: createLinkPath([data.homePage?.lang, data.productsPage?.uid]),
  },
});

const UidResolver = (uid: string): string => {
  const { t } = useTranslation();
  return t(`account.${uid}`);
};

export const toAccountLayout = (
  data: _AccountLayoutData,
  homePage?: GatsbyPrismicHomePage,
  accountPage?: GatsbyPrismicAccountPage
): _AccountLayout => ({
  homeLink: {
    label: data.homePage?.data.title || ``,
    path: createLinkPath([data.homePage?.lang]),
  },
  loginLink: {
    path: createLinkPath([
      homePage?.lang,
      accountPage?.uid,
      data.loginPage?.uid,
    ]),
  },
  navLinks: transformPageLinks([
    data.productsPage as GatsbyPrismicProductsPage,
    data.mobileAppPage as GatsbyPrismicMobileAppPage,
    data.blogPage as GatsbyPrismicBlogPage,
    data.helpPage as GatsbyPrismicHelpPage,
    data.heddaPage as GatsbyPrismicHeddaPage,
  ]),
  accountLinks: [
    {
      label: UidResolver(data.ordersPage.type),
      path: createLinkPath([
        homePage?.lang,
        accountPage?.uid,
        data.ordersPage?.uid,
      ]),
    },
    {
      label: UidResolver(data.profilePage.type),
      path: createLinkPath([
        homePage?.lang,
        accountPage?.uid,
        data.profilePage?.uid,
      ]),
    },
    {
      label: UidResolver(data.addressesPage.type),
      path: createLinkPath([
        homePage?.lang,
        accountPage?.uid,
        data.addressesPage?.uid,
      ]),
    },
    {
      label: UidResolver(data.newsletterPage.type),
      path: createLinkPath([
        homePage?.lang,
        accountPage?.uid,
        data.newsletterPage?.uid,
      ]),
    },
    {
      label: UidResolver(data.passwordPage.type),
      path: createLinkPath([
        homePage?.lang,
        accountPage?.uid,
        data.passwordPage?.uid,
      ]),
    },
    // {
    //   label: UidResolver(data.paymentMethodPage.type),
    //   path: createLinkPath([
    //     homePage?.lang,
    //     accountPage?.uid,
    //     data.paymentMethodPage?.uid,
    //   ]),
    // },
  ],
});

export const toAuthLayout = (
  data: _AuthLayoutData,
  ordersPage?: GatsbyPrismicOrdersPage,
  loginPage?: GatsbyPrismicLoginPage,
  createAccount?: GatsbyPrismicCreateAccountPage,
  passwordForgotten?: GatsbyPrismicPasswordForgottenPage,
  resetPassword?: GatsbyPrismicPasswordResetPage
): _AuthLayout => ({
  accountLink: {
    path: createLinkPath([
      loginPage?.lang,
      data.accountPage?.uid,
      ordersPage?.uid,
    ]),
  },
  loginLink: {
    path: createLinkPath([
      loginPage?.lang,
      data.accountPage?.uid,
      loginPage?.uid,
    ]),
  },
  createAccountLink: {
    path: createLinkPath([
      createAccount?.lang,
      data.accountPage?.uid,
      createAccount?.uid,
    ]),
  },
  passwordForgottenLink: {
    path: createLinkPath([
      loginPage?.lang,
      data.accountPage?.uid,
      passwordForgotten?.uid,
    ]),
  },
  resetPasswordLink: {
    path: createLinkPath([
      loginPage?.lang,
      data.accountPage?.uid,
      resetPassword?.uid,
    ]),
  },
});
