import { forwardRef } from 'react';
import { Link } from 'gatsby';

import { _Link } from '@/domain/entities';

import { Box, Button, Flex, FlexProps, Heading } from '@/components/atoms';

import { useTracking, useTranslation } from '@/hooks';

export interface Props extends FlexProps {
  productsLink: _Link;
}

export const CartEmptyPreview = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { productsLink, ...rest } = props;
    const { t } = useTranslation();
    const { track } = useTracking();

    return (
      <Flex
        flexDirection={[`column`, null, `row`]}
        justifyContent="space-between"
        alignItems="center"
        pr={[null, null, 5]}
        textAlign={[`center`, null, `left`]}
        ref={ref}
        {...rest}
      >
        <Box
          bg="white"
          borderRadius={20}
          p={10}
          boxShadow="rgba(0, 0, 0, 0.15) 0px 0px 25px"
        >
          <Flex display="inline" textAlign="center">
            <Heading variant="h5">{t(`cartPreview.cartEmpty`)}</Heading>
            <Box pt={5}>
              <Button
                size="sm"
                bgColor="black"
                textColor="white"
                as={(props) => (
                  <Link
                    to={productsLink.path}
                    onClick={() => track(`cart-preview-cart-empty`)}
                    {...props}
                  />
                )}
              >
                {t(`cartPreview.addProducts`)}
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    );
  }
);
