import { useCallback } from 'react';
import {
  ShopifyStorefrontMutationCustomerAccessTokenCreateArgs,
  ShopifyStorefrontMutationCustomerAccessTokenRenewArgs,
} from '@shopify/types';

import { useNewHeddaClient } from '@/clients';

import { _Customer, ShopifyAccessToken } from '@/domain/entities';

export const useShopifyCustomer = () => {
  const heddaClient = useNewHeddaClient();

  /**
   * Find customer
   */
  const findOneCustomer = useCallback(async () => {
    return await heddaClient.customer.findOne();
  }, []);

  /**
   * Update customer
   */
  const updateCustomer = useCallback(
    async (input: {
      hasAcceptedMarketing?: boolean;
      firstName?: string;
      lastName?: string;
      email?: string;
      phone?: string;
    }): Promise<_Customer | undefined> => {
      return await heddaClient.customer.updateCustomer(input);
    },
    []
  );

  /**
   * Create access token (Login customer)
   */
  const accessTokenCreate = useCallback(
    async (
      input: ShopifyStorefrontMutationCustomerAccessTokenCreateArgs[`input`]
    ): Promise<ShopifyAccessToken | undefined> => {
      return await heddaClient.customer.accessTokenCreate(input);
    },
    []
  );

  /**
   * Renew access token
   */
  const accessTokenRenew = useCallback(
    async (
      input: ShopifyStorefrontMutationCustomerAccessTokenRenewArgs
    ): Promise<ShopifyAccessToken | undefined> => {
      return await heddaClient.customer.accessTokenRenew(input);
    },
    []
  );

  /**
   * Create new customer
   */
  const create = useCallback(
    async (
      firstName: string,
      lastName: string,
      email: string,
      password: string
    ): Promise<_Customer | undefined> => {
      return await heddaClient.customer.create({
        firstName,
        lastName,
        email,
        password,
      });
    },
    []
  );

  /**
   * Active account by url
   */
  const activateAccount = useCallback(
    async (
      activationUrl: string,
      password: string
    ): Promise<ShopifyAccessToken | undefined> => {
      return await heddaClient.customer.activateAccount(
        activationUrl,
        password
      );
    },
    []
  );

  return {
    accessTokenCreate,
    accessTokenRenew,
    activateAccount,
    create,
    findOneCustomer,
    updateCustomer,
  };
};
