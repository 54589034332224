import { createIcon } from '@chakra-ui/icons';

export const Avatar = createIcon({
  displayName: `Avatar`,
  viewBox: `0 0 25 24`,
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4287 6C16.4287 8.20914 14.6378 10 12.4287 10C10.2196 10 8.42871 8.20914 8.42871 6C8.42871 3.79086 10.2196 2 12.4287 2C14.6378 2 16.4287 3.79086 16.4287 6ZM15.9212 10.8794C17.4395 9.79067 18.4287 8.01085 18.4287 6C18.4287 2.68629 15.7424 0 12.4287 0C9.115 0 6.42871 2.68629 6.42871 6C6.42871 8.01085 7.41791 9.79067 8.93625 10.8794C7.06818 11.5289 5.34934 12.6958 3.94343 14.3025C2.10964 16.3983 0.930648 19.1025 0.556951 22C0.472046 22.6583 0.428711 23.3266 0.428711 24H2.42871H12.4287H22.4287H24.4287C24.4287 23.3266 24.3854 22.6583 24.3005 22C23.9268 19.1025 22.7478 16.3983 20.914 14.3025C19.5081 12.6958 17.7892 11.5289 15.9212 10.8794ZM19.4088 15.6195C20.9131 17.3387 21.9212 19.5719 22.2815 22H12.4287H2.5759C2.9362 19.5719 3.9443 17.3387 5.44858 15.6195C7.35094 13.4454 9.86928 12.2857 12.4287 12.2857C14.9881 12.2857 17.5065 13.4454 19.4088 15.6195Z"
    />
  ),
});
