import { ReactNode } from 'react';

export interface Props {
  children: ReactNode;
  isLoading: boolean;
  fallback: ReactNode;
}

export function Suspense(props: Props): JSX.Element {
  const { children, fallback, isLoading } = props;

  return isLoading ? <>{fallback}</> : <>{children}</>;
}
