import { useMemo } from 'react';

import { LocalStorage } from '@/config';

import {
  NewHeddaAddressesRepository,
  NewHeddaCustomerRepository,
  NewHeddaGeolocationRepository,
  NewHeddaMarketingRepository,
  NewHeddaOrderRepository,
  NewHeddaSubscriptionRepository,
} from '@/repositories';

import { isBrowser } from '@/utils';

import { HttpClient } from './HttpClient';

export class NewHeddaClient {
  addresses;
  customer;
  geolocation;
  marketing;
  order;
  subscription;

  constructor(accessToken?: string) {
    const baseUrl = process.env.GATSBY_HEDDA_API || ``;

    const defaultHeaders: HeadersInit = {};

    if (accessToken) {
      Object.assign(defaultHeaders, { Authorization: `Bearer ${accessToken}` });
    }

    const httpClient = new HttpClient(baseUrl, defaultHeaders);

    this.addresses = new NewHeddaAddressesRepository(
      httpClient,
      `/shop-gateway`
    );
    this.customer = new NewHeddaCustomerRepository(httpClient, `/shop-gateway`);
    this.geolocation = new NewHeddaGeolocationRepository(
      httpClient,
      `/geolocation`
    );
    this.order = new NewHeddaOrderRepository(httpClient, `/shop-gateway`);
    this.marketing = new NewHeddaMarketingRepository(
      httpClient,
      `/shop-gateway`
    );
    this.subscription = new NewHeddaSubscriptionRepository(
      httpClient,
      `/shopify/subscription`
    );
  }
}

export const useNewHeddaClient = () => {
  return useMemo(() => {
    const accessToken = isBrowser
      ? localStorage.getItem(LocalStorage.ShopifyJwtToken)
      : undefined;
    return new NewHeddaClient(accessToken || undefined);
  }, []);
};
