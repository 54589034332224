import { ReactNode } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

import { Box, TextProps } from '@/components/atoms';

import { useIsMobile } from '@/hooks';

const defaultBreakpoint = `md`;

export interface Props extends TextProps {
  desktop: ReactNode;
  mobile: ReactNode;
  breakpoint?: string;
}

export function ResponsiveText(props: Props): JSX.Element {
  const { desktop, mobile, breakpoint, ...rest } = props;
  const isMobile = useIsMobile(breakpoint);
  const desktopStyle = useBreakpointValue({
    base: `none`,
    [breakpoint || defaultBreakpoint]: `inline-block`,
  });

  const mobileStyle = useBreakpointValue({
    base: `inline-block`,
    [breakpoint || defaultBreakpoint]: `none`,
  });

  return (
    <>
      {!isMobile && (
        <Box as="span" display={desktopStyle} {...rest}>
          {desktop}
        </Box>
      )}
      {isMobile && (
        <Box as="span" display={mobileStyle} {...rest}>
          {mobile}
        </Box>
      )}
    </>
  );
}
