import { forwardRef } from 'react';
import {
  Select as ChakraSelect,
  SelectProps,
  useStyleConfig,
} from '@chakra-ui/react';

export interface Props extends SelectProps {}

export const Select = forwardRef<HTMLSelectElement, Props>(
  (props, ref): JSX.Element => {
    const style = useStyleConfig(`Select`, props);

    return <ChakraSelect ref={ref} __css={style} {...props} />;
  }
);
