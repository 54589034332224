import { forwardRef } from 'react';
import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { Icons } from '@/config';

import { Flex, ImageBorder } from '@/components/atoms';

import { Icon } from '../Icon';

export type ButtonSize = `sm` | `md`;
export type ButtonVariant = `menu` | `cookies`;

export interface Props extends Omit<ButtonProps, `leftIcon` | `rightIcon`> {
  size?: ButtonSize;
  leftIcon?: Icons;
  rightIcon?: Icons;
  variant?: ButtonVariant;
  width: number;
  height: number;
}

export const ButtonBorder = forwardRef<HTMLButtonElement, Props>(
  (props, ref): JSX.Element => {
    const { children, height, rightIcon, leftIcon, width, ...rest } = props;

    return (
      <Flex
        position="relative"
        w={width}
        h={height}
        _hover={{
          '.border-fill': {
            opacity: 1,
            transition: `.2s`,
          },
        }}
      >
        <ImageBorder
          radius={30}
          width={width + 9}
          height={height + 5}
          left="-3px"
          top="7px"
        />
        <ChakraButton
          className="button"
          transition="transform ease .4s"
          borderRadius={99}
          iconSpacing={3}
          top="6px"
          left="6px"
          leftIcon={leftIcon ? <Icon icon={leftIcon} /> : undefined}
          rightIcon={rightIcon ? <Icon icon={rightIcon} /> : undefined}
          ref={ref}
          {...rest}
        >
          {children}
        </ChakraButton>
      </Flex>
    );
  }
);

export const MotionButtonBorder = motion(ButtonBorder);
