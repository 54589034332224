import { useRef, useState } from 'react';
import { CloseButton, useOutsideClick } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';

import { DateFormat } from '@/config';

import { _Order } from '@/domain/entities';
import { toCurrency } from '@/domain/mappings';

import {
  Badge,
  Box,
  BoxProps,
  Button,
  Conditional,
  Flex,
  Heading,
  MotionFlex,
  Text,
} from '@/components/atoms';
import { Modal, OrderDetailLine, SummaryLine } from '@/components/molecules';

import { useGetUniqueKey, useTranslation } from '@/hooks';

import { useCart } from '@/state';

import { renderOrderBadge, toPrice } from '@/utils';

interface Props extends Omit<BoxProps, `order`> {
  order: _Order | undefined;
  closeDetail: () => void;
  isOpen: boolean;
  cartLink: string;
}

export function OrderDetail(props: Props): JSX.Element {
  const { cartLink, closeDetail, isOpen, order, ...rest } = props;

  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref,
    handler: () => (modalOpen ? () => {} : closeDetail()),
  });
  const getUniqueKey = useGetUniqueKey();
  const { addCartLines, cart, removeCartLines } = useCart();

  const overlay = {
    visible: {
      scale: 1,
      backgroundColor: `rgba(0, 0, 0, 0.3)`,
      transition: {
        backgroundColor: {
          duration: 0.2,
        },
        scale: {
          delay: 0,
          duration: 0,
        },
      },
    },
    hidden: {
      scale: 0,
      backgroundColor: `rgba(0, 0, 0, 0)`,
      transition: {
        scale: {
          delay: 0.6,
          duration: 0.0000001,
        },
        backgroundColor: {
          delay: 0.3,
          duration: 0.3,
        },
      },
    },
  };

  const modal = {
    visible: {
      x: `-100%`,
      opacity: 1,
      transition: {
        x: {
          duration: 0.5,
          type: `spring`,
          bounce: 0.3,
        },
        opacity: {
          duration: 0,
        },
      },
    },
    hidden: {
      x: 0,
      opacity: 0,
      transition: {
        x: {
          duration: 0.5,
          type: `spring`,
        },
        opacity: {
          delay: 0.5,
          duration: 0,
        },
      },
    },
  };

  const totalItems = order?.lineItems.reduce((acc) => acc + 1, 0);

  const summaryLines = [
    {
      label: `${totalItems} ${
        totalItems === 1 ? `${t(`common.product`)}` : `${t(`common.products`)}`
      }`,
      value: toPrice(
        order?.subtotalPriceV2?.amount,
        toCurrency(order?.subtotalPriceV2?.currencyCode)
      ),
    },
    {
      label: `${t(`common.shipping`)}`,
      value: toPrice(
        order?.totalShippingPriceV2?.amount,
        toCurrency(order?.totalShippingPriceV2?.currencyCode)
      ),
    },
    {
      label: `${t(`common.taxes`)}`,
      value: toPrice(
        order?.totalTaxV2?.amount,
        toCurrency(order?.totalTaxV2?.currencyCode)
      ),
    },
  ];

  async function onOrderAgain() {
    if (cart.lines.edges?.length > 0) {
      setModalOpen(true);
    } else {
      await onAddToCart();
    }
  }

  async function onAddToCart() {
    await removeCartLines();
    if (order?.lineItems) {
      await addCartLines(order.lineItems);
      await navigate(cartLink);
    }
  }

  const renderSummaryLines = summaryLines.map((summaryLine) => (
    <SummaryLine
      key={getUniqueKey(summaryLine)}
      label={summaryLine.label}
      value={summaryLine.value}
    />
  ));

  const hasDiscount = (order?.discountCodes?.length || 0) > 0;

  return (
    <MotionFlex
      className="FDP"
      position="absolute"
      initial="hidden"
      animate={isOpen ? `visible` : `hidden`}
      variants={overlay}
      right={0}
      top={0}
      width="100vw"
      height="100vh"
      zIndex={700}
      bg="rgba(0, 0, 0, 0)"
    >
      <MotionFlex
        initial="hidden"
        animate={isOpen ? `visible` : `hidden`}
        variants={modal}
        justifyContent="center"
        alignItems="center"
        position="fixed"
        top={0}
        left="100%"
        width={[`100%`, null, `auto`]}
        height="100%"
        px={[4, null, 10]}
        zIndex={800}
      >
        <Box
          ref={ref}
          position="relative"
          width={[`100%`, null, 600]}
          height="90%"
          bg="gray.50"
          borderRadius="3xl"
          py={7}
          px={[4, null, 8]}
          boxShadow="0px 0px 5px -1px rgba(0,0,0,0.2)"
        >
          <Box position="absolute" top={7} right={4}>
            <CloseButton bg="gray.100" onClick={closeDetail} />
          </Box>
          <Box
            height="100%"
            overflowY="scroll"
            sx={{
              '::-webkit-scrollbar': {
                display: `none`,
              },
              scrollbarWidth: `none`,
            }}
            {...rest}
          >
            <Box mb={2}>
              <Text variant="5">
                {dayjs(order?.processedAt).format(DateFormat.DD_MM_YYYY)}
              </Text>
            </Box>
            <Flex flexDirection={[`column`, `row`]} alignItems="flex-start">
              <Badge
                size="sm"
                mr={[0, 2]}
                mb={2}
                {...renderOrderBadge(order?.financialStatus)}
              />
              <Badge
                size="sm"
                mb={[0, 2]}
                {...renderOrderBadge(order?.fulfillmentStatus)}
              />
            </Flex>
            <Conditional isTrue={hasDiscount}>
              <Flex alignItems="flex-end">
                <Text mr={2}>{t(`order.discountCodes`)} :</Text>
                {order?.discountCodes?.map((discountCode) => (
                  <Badge key={getUniqueKey(discountCode)} size="sm">
                    {discountCode}
                  </Badge>
                ))}
              </Flex>
            </Conditional>
            <Box bg="gray.100" p={5} borderRadius="2xl" mt={10}>
              <Box mb={5}>
                <Text fontWeight="bold">#{order?.orderNumber}</Text>
              </Box>
              <Box>
                {order?.lineItems?.map((lineItem) => (
                  <OrderDetailLine
                    key={getUniqueKey(lineItem)}
                    lineItem={lineItem}
                  />
                ))}
              </Box>
            </Box>
            <Box bg="gray.100" p={5} borderRadius="2xl" mt={5}>
              {renderSummaryLines}
              <SummaryLine
                label="Total de la commande"
                value={toPrice(
                  order?.totalPriceV2?.amount,
                  toCurrency(order?.totalPriceV2?.currencyCode)
                )}
                isTotal
                justifyContent="space-between"
                borderTopWidth={2}
                borderTopColor="gray.200"
                borderTopStyle="solid"
                mt={5}
                pt={5}
                mb={10}
              />
            </Box>
            <Box mt={5}>
              <Button w="100%" rightIcon="undo-alt" onClick={onOrderAgain}>
                {t(`order.orderAgain`)}
              </Button>
            </Box>
          </Box>
        </Box>
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          size="xl"
          bodyProps={{ px: [5, null, 10], py: [5, null, 5] }}
          footer={
            <Flex>
              <Button
                width="100%"
                mr={5}
                onClick={() => setModalOpen(false)}
                size="xs"
                bg="transparent"
                borderRadius="lg"
                borderWidth={1}
                borderColor="black"
                color="black"
                _hover={{ bg: `transparent` }}
              >
                {t(`common.cancel`)}
              </Button>
              <Button
                width="100%"
                borderRadius="lg"
                size="xs"
                onClick={onAddToCart}
              >
                {t(`common.confirm`)}
              </Button>
            </Flex>
          }
          footerProps={{ bg: `gray.50`, borderBottomRadius: `lg`, mt: 10 }}
        >
          <Heading variant="h5" mb={5}>
            {t(`order.cartNotEmpty`)}
          </Heading>
          <Text>{t(`order.cartDelete`)}</Text>
        </Modal>
      </MotionFlex>
    </MotionFlex>
  );
}
