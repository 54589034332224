import { TrackingContentType, TrackingEvent, TrackingValue } from '@/config';

import { _BlogPost } from '@/domain/entities';

import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Text,
} from '@/components/atoms';

import { useTracking, useTranslation } from '@/hooks';

export interface Props extends BoxProps {
  blogPost: _BlogPost;
}

export function BlogCard(props: Props): JSX.Element {
  const { blogPost, ...rest } = props;
  const { t } = useTranslation();
  const { track } = useTracking();

  const onBlogPostClick = (blogPost: _BlogPost) => {
    track(TrackingEvent.SELECT_CONTENT, {
      [TrackingValue.CONTENT_TYPE]: TrackingContentType.BLOG_POST,
      [TrackingValue.ITEM_ID]: blogPost.id,
    });
  };

  return (
    <Box
      as={(rest) => (
        <Link
          to={blogPost.link.path}
          onClick={() => onBlogPostClick(blogPost)}
          {...rest}
        />
      )}
      width="100%"
      position="relative"
      zIndex={2}
      h={280}
      borderRadius={20}
      overflow="hidden"
      sx={{
        '&:hover': {
          '.gatsby-image-wrapper': {
            transform: `scale(1.1)`,
          },
        },
      }}
      {...rest}
    >
      <Image
        image={blogPost.image}
        alt={blogPost.image.alt}
        h={280}
        transition="transform .5s"
      />
      <Box
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        width="100%"
        height="100%"
        bgGradient="linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))"
      />
      <Box
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        alignItems="flex-end"
        p={5}
      >
        <Box>
          <Heading variant="h5" color="white" mb={2}>
            {blogPost.title}
          </Heading>
          <Flex alignItems="center">
            <Text variant="4" color="white" mr={2}>
              {t(`blog.readPost`)}
            </Text>
            <Icon icon="arrow-right" color="white" fontSize={12} />
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}
