import { forwardRef } from 'react';

import { Icons } from '@/config';

import { Box, BoxProps, Flex, Icon, Suspense, Text } from '@/components/atoms';

import { useGetUniqueKey } from '@/hooks';

export interface Props extends Omit<BoxProps, `color`> {
  score: number;
  numberOfReviews?: number;
  color?: string;
  star?: JSX.Element;
}

export const StarRating = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { color, numberOfReviews, score, star, ...rest } = props;
    const getUniqueKey = useGetUniqueKey();

    const scoring = Math.floor(score);
    const half = scoring < score;

    const renderIcon = (e: number): Icons => {
      const key = e + 1;

      if (key <= scoring) {
        return `star`;
      }
      if (half && key <= Math.round(score)) {
        return `star-half-alt`;
      }
      return `star_regular`;
    };

    const renderStars = () => {
      return Array.from(Array(5)).map((_, key) => (
        <Box key={getUniqueKey(key)} mr={1} _last={{ mr: 1.5 }}>
          <Suspense
            isLoading={!star}
            fallback={<Icon icon={renderIcon(key)} color={color || `black`} />}
          >
            {star}
          </Suspense>
        </Box>
      ));
    };

    return (
      <Flex ref={ref} alignItems="center" {...rest}>
        {renderStars()}
        {numberOfReviews && (
          <Text variant="4" fontWeight="semibold" h="20px">
            ({numberOfReviews})
          </Text>
        )}
      </Flex>
    );
  }
);
