import { Word } from '@/types';

const renderPronoun = (word: Word): string => {
  if (
    typeof word.isFemale === `undefined` &&
    typeof word.isPlural === `undefined`
  ) {
    return ``;
  }

  if (word.isFemale) {
    if (word.isPlural) {
      return `de la ${word.value}`;
    } else {
      return `la ${word.value}`;
    }
  } else if (word.isPlural) {
    return `du ${word.value}`;
  } else {
    return `le ${word.value}`;
  }
};

export const linkWordsTogether = (terms: Word[]): string => {
  return terms.reduce((acc, term, key) => {
    const isLast = key === terms.length - 1;
    const isSecondToLast = key === terms.length - 2;
    if (isLast) {
      return acc;
    }
    return acc + renderPronoun(term) + (isSecondToLast ? ` et ` : `, `);
  }, ``);
};
