import { TrackingEvent, TrackingValue } from '@/config';

import { toValues } from '@/tracking/utils';

import { AnalyticsPlugin, AnalyticsPluginConfig } from '@/types';

import { isBrowser } from '@/utils';

interface GoogleTagManagerConfig extends AnalyticsPluginConfig {
  debug: boolean;
  containerId: string | null;
  dataLayerName: string;
  dataLayer: any[] | undefined;
  preview: undefined;
  auth: undefined;
  execution: string;
  assumesPageview: boolean;
}

export const defaultConfig = {
  debug: false,
  containerId: null,
  dataLayerName: `dataLayer`,
  dataLayer: undefined,
  preview: undefined,
  auth: undefined,
  execution: `async`,
  assumesPageview: true,
};

const GoogleTagManagerTrackingEvent: { [K in TrackingEvent]?: string } = {
  [TrackingEvent.ADD_TO_CART]: `add_to_cart`,
  [TrackingEvent.ADD_TO_WISHLIST]: `add_to_wishlist`,
  [TrackingEvent.BEGIN_CHECKOUT]: `begin_checkout`,
  [TrackingEvent.GENERIC]: `generic`,
  [TrackingEvent.LOGIN]: `login`,
  [TrackingEvent.PAGE_VIEW]: `page_view`,
  [TrackingEvent.REMOVE_FROM_CART]: `remove_from_cart`,
  [TrackingEvent.SEARCH]: `search`,
  [TrackingEvent.SELECT_CONTENT]: `select_content`,
  [TrackingEvent.SELECT_ITEM]: `select_item`,
  [TrackingEvent.SIGNUP]: `sign_up`,
  [TrackingEvent.VIEW_ITEM]: `view_item`,
  [TrackingEvent.VIEW_ITEM_LIST]: `view_item_list`,
  [TrackingEvent.VIEW_SEARCH_RESULTS]: `view_search_results`,
  [TrackingEvent.VIEW_CART]: `view_cart`,
};

const GoogleTagManagerTrackingValue: { [K in TrackingValue]?: string } = {
  [TrackingValue.AD_STORAGE]: `ad_storage`,
  [TrackingValue.ANALYTICS_STORAGE]: `analytics_storage`,
  [TrackingValue.CONTENT_NAME]: `name`,
  [TrackingValue.CONTENT_TYPE]: `content_type`,
  [TrackingValue.COUNTRY]: `country`,
  [TrackingValue.CURRENCY]: `currency`,
  [TrackingValue.EVENT_ACTION]: `event_action`,
  [TrackingValue.EVENT_CATEGORY]: `event_category`,
  [TrackingValue.EVENT_ID]: `event_id`,
  [TrackingValue.FEATURE_NAME]: `feature_name`,
  [TrackingValue.FUNCTIONAL_STORAGE]: `functional_storage`,
  [TrackingValue.INDEX]: `index`,
  [TrackingValue.ITEM_BRAND]: `item_brand`,
  [TrackingValue.ITEM_CATEGORY]: `item_category`,
  [TrackingValue.ITEM_CATEGORY_2]: `item_category2`,
  [TrackingValue.ITEM_ID]: `item_id`,
  [TrackingValue.ITEM_NAME]: `item_name`,
  [TrackingValue.ITEM_LIST_ID]: `item_list_id`,
  [TrackingValue.ITEM_LIST_NAME]: `item_list_name`,
  [TrackingValue.ITEMS]: `items`,
  [TrackingValue.METHOD]: `method`,
  [TrackingValue.NECESSARY_COOKIES]: `necessary_cookies`,
  [TrackingValue.PAGE_NAME]: `page_name`,
  [TrackingValue.PERFORMANCES_COOKIES]: `performance_cookies`,
  [TrackingValue.PLATFORM]: `platform`,
  [TrackingValue.PRICE]: `price`,
  [TrackingValue.QUANTITY]: `quantity`,
  [TrackingValue.SCROLL_PERCENTAGE]: `scroll_percentage`,
  [TrackingValue.SEARCH_TERM]: `search_term`,
  [TrackingValue.SOCIAL_MEDIA]: `social_media`,
  [TrackingValue.STEP_NAME]: `step_name`,
  [TrackingValue.VALUE]: `value`,
};

export const providerGoogleTagManager = (): AnalyticsPlugin<
  typeof GoogleTagManagerTrackingEvent,
  GoogleTagManagerConfig
> => {
  return {
    name: `google-tag-manager`,
    config: {
      ...defaultConfig,
    },
    initialize: ({ config }) => {
      if (isBrowser) {
        const { dataLayerName } = config;
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        config.dataLayer = window[dataLayerName];
      }
    },
    page: ({ config, payload }) => {
      if (typeof config.dataLayer !== `undefined`) {
        config.dataLayer.push(function () {
          // @ts-ignore
          this.reset();
        });
        config.dataLayer.push(payload.properties);
      }
    },
    track: async ({ config, payload }) => {
      if (typeof config.dataLayer !== `undefined`) {
        const eventName =
          GoogleTagManagerTrackingEvent[payload.event] || payload.event;
        const values = toValues(
          payload.properties,
          GoogleTagManagerTrackingValue
        );
        const dataLayer = {
          event: eventName,
          ...values,
        };
        config.dataLayer.push(function () {
          // @ts-ignore
          this.reset();
        });
        config.dataLayer.push(dataLayer);
        // navigator.clipboard.writeText(JSON.stringify(dataLayer));
      }
    },
    loaded: () =>
      isBrowser && Array.isArray(window[defaultConfig.dataLayerName as any]),
  };
};
