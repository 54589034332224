import { ShopifyAdminSubscriptionContract } from '@shopify/types';

import { HeddaRepository } from '@/repositories';

export class NewHeddaSubscriptionRepository extends HeddaRepository {
  async findAll(): Promise<ShopifyAdminSubscriptionContract[]> {
    return await this.client.get<ShopifyAdminSubscriptionContract[]>(
      `${this.url}/contracts`
    );
  }

  async pauseSubscription(
    id: string
  ): Promise<ShopifyAdminSubscriptionContract> {
    const base64Id = btoa(id);
    return await this.client.patch<ShopifyAdminSubscriptionContract>(
      `${this.url}/contracts/${base64Id}/pause`
    );
  }

  async resumeSubscription(
    id: string
  ): Promise<ShopifyAdminSubscriptionContract> {
    const base64Id = btoa(id);
    return await this.client.patch<ShopifyAdminSubscriptionContract>(
      `${this.url}/contracts/${base64Id}/resume`
    );
  }

  async cancelSubscription(
    id: string
  ): Promise<ShopifyAdminSubscriptionContract> {
    const base64Id = btoa(id);
    return await this.client.patch<ShopifyAdminSubscriptionContract>(
      `${this.url}/contracts/${base64Id}/cancel`
    );
  }
}
