import { forwardRef, ReactElement, ReactNode } from 'react';
import { chakra } from '@chakra-ui/react';
import { renderToString } from 'react-dom/server';
import parse from 'html-react-parser';

export interface Props {
  isAnimated?: boolean;
  children: ReactNode;
  gradient?: string;
  outline?: string;
  words: string[];
}

export const GradientText = forwardRef<HTMLHeadingElement, Props>(
  (props, ref): JSX.Element => {
    const { isAnimated = false, children, outline, gradient, words } = props;

    const splitWord = (word: string) => {
      return word.replace(
        // eslint-disable-next-line no-control-regex
        /([^\x00-\x80]|\w)/g,
        `<span class='letter'>$&</span>`
      );
    };

    const replaceWords = (text: string, words: string[]) => {
      return text.replaceAll(/\w+\S+|\w+/gm, (match) => {
        if (words.includes(match)) {
          return `<span class="outline">${
            isAnimated ? splitWord(match) : match
          }</span>`;
        }
        return isAnimated ? splitWord(match) : match;
      });
    };

    return (
      <chakra.div
        className="animated-heading"
        sx={
          gradient
            ? {
                '& > span': {
                  background: gradient,
                  backgroundClip: `text`,
                },
              }
            : {}
        }
        css={
          gradient
            ? ``
            : `
          .outline {
            color: transparent;
            -webkit-text-stroke: ${outline || `2px black`};
            text-stroke: ${outline || `2px black`};
            text-shadow: none;
          }
        `
        }
        ref={ref}
      >
        {parse(replaceWords(renderToString(children as ReactElement), words))}
      </chakra.div>
    );
  }
);
