import { forwardRef } from 'react';

import { _ProductReview } from '@/domain/entities';

import {
  Box,
  BoxProps,
  Button,
  Flex,
  Text,
  VerifiedBadge,
} from '@/components/atoms';
import { StarRating } from '@/components/molecules';

import { useTranslation } from '@/hooks';

export interface Props extends BoxProps {
  review: _ProductReview;
  hasReadMore?: boolean;
  onReadMoreClick?: (review: _ProductReview) => void;
  gradient: string;
  star?: JSX.Element;
}

export const ReviewCard = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { review, gradient, hasReadMore, onReadMoreClick, star, ...rest } =
      props;
    const { t } = useTranslation();

    return (
      <Box
        ref={ref}
        bg="rgba(255, 255, 255, 0.25)"
        backdropFilter="blur(10px)"
        borderRadius={20}
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.05)"
        px={12}
        py={10}
        w="100%"
        maxW={[360, 400, null, 400]}
        minH={248}
        position="relative"
        willChange="backdrop-filter"
        {...rest}
      >
        <Flex alignItems="center" mb={1}>
          <Box>
            <Text variant="2" fontWeight="semibold">
              {review.firstname}
              {` `}
              {review.lastname}
            </Text>
          </Box>
          <Box ml="auto">
            <VerifiedBadge gradient={gradient} />
          </Box>
        </Flex>
        <Box mb={5}>
          <StarRating score={5} star={star} color="black" />
        </Box>
        <Box minH={12}>
          <Text
            variant="3"
            color="gray.500"
            fontWeight="medium"
            noOfLines={hasReadMore ? 2 : undefined}
          >
            {review.message}
          </Text>
        </Box>
        {hasReadMore && (
          <Box mt={2}>
            <Text
              as={(props) => (
                <Button
                  color="black"
                  borderRadius={0}
                  px={0}
                  h={4}
                  bgColor="transparent"
                  _hover={{ color: `none` }}
                  {...props}
                />
              )}
              textDecoration="underline"
              onClick={() => onReadMoreClick?.(review)}
            >
              {t(`productPage.readReview`)}
            </Text>
          </Box>
        )}
      </Box>
    );
  }
);
