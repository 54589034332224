import { useMemo } from 'react';

import {
  ShopifyCartRepository,
  ShopifyCustomerRepository,
  ShopifyProductRepository,
} from '@/repositories';

import { GraphQLClient } from './GraphQLClient';

interface ShopifyClientConfig {
  storeDomain: string;
  storefrontAccessToken: string;
}

export class ShopifyClient {
  cart;
  customer;
  product;

  constructor(config: ShopifyClientConfig) {
    const url = `https://${config.storeDomain}/api/2023-01/graphql.json`;

    const headers: HeadersInit = {
      'X-Shopify-Storefront-Access-Token': config.storefrontAccessToken,
    };

    const graphQLClient = new GraphQLClient(url, headers);

    this.cart = new ShopifyCartRepository(graphQLClient);
    this.customer = new ShopifyCustomerRepository(graphQLClient);
    this.product = new ShopifyProductRepository(graphQLClient);
  }
}

const storeDomain = process.env.GATSBY_SHOPIFY_STORE_URL || ``;
const storefrontAccessToken = process.env.GATSBY_STOREFRONT_ACCESS_TOKEN || ``;

export const useShopifyClient = () => {
  return useMemo(() => {
    return new ShopifyClient({
      storeDomain,
      storefrontAccessToken,
    });
  }, []);
};
