export const isActiveLink = (
  pathName: string | undefined,
  currentPath: string | undefined
) => {
  const removeLangFromPath = (path: string | undefined) => {
    if (pathName) {
      return pathName
        .split(`/`)
        .filter((element) => element.length > 0)
        .filter((_, key) => key !== 0)
        .join(`/`);
    } else {
      return path
        ?.split(`/`)
        .filter((element) => element.length > 0)
        .filter((_, key) => key !== 0)
        .join(`/`);
    }
  };

  const pathNameWithoutLang = removeLangFromPath(pathName);

  return (
    currentPath &&
    pathNameWithoutLang &&
    currentPath.includes(pathNameWithoutLang)
  );
};
