import { Icons } from '@/config';

import {
  _Benefit,
  _Cause,
  _Form,
  _PricingPolicy,
  _Product,
  _ProductBanner,
  _ProductEffectsList,
  _ProductIngredient,
  _ProductPage,
  _ProductQuestion,
  _ProductReview,
  _ProductsPage,
  _ProductType,
  _ProductWhy,
  _SellingPlan,
  _Usage,
  _Who,
  GatsbyPrismicActiveSubstance,
  GatsbyPrismicBlogPage,
  GatsbyPrismicBlogPostConnection,
  GatsbyPrismicCartPage,
  GatsbyPrismicFaqSection,
  GatsbyPrismicHelpPage,
  GatsbyPrismicProduct,
  GatsbyPrismicProductConnection,
  GatsbyPrismicProductDataGlobalInformationsAdvantages,
  GatsbyPrismicProductDataGlobalInformationsIngredients,
  GatsbyPrismicProductDataGlobalInformationsQuestions,
  GatsbyPrismicProductDataListOfIngredients,
  GatsbyPrismicProductDataProductEffectsList,
  GatsbyPrismicProductDataQuestions,
  GatsbyPrismicProductDataReviews,
  GatsbyPrismicProductDataWhySupplementHighlight,
  GatsbyPrismicProductPage,
  GatsbyPrismicProductsPage,
  GatsbyPrismicProductWhy,
  GatsbyPrismicProductWhyDataBenefits,
  GatsbyPrismicProductWhyDataCauses,
  GatsbyPrismicProductWhyDataForms,
  GatsbyPrismicProductWhyDataUsages,
  GatsbyPrismicProductWhyDataWhos,
  GatsbyShopifyProduct,
  GatsbyShopifyProductConnection,
  GatsbyShopifyProductSellingPlan,
  GatsbyShopifyProductSellingPlanSellingPlanGroupsEdgesNodeSellingPlansEdgesNode,
  GatsbyShopifyProductSellingPlanSellingPlanGroupsEdgesNodeSellingPlansEdgesNodePricingPolicies,
} from '@/domain/entities';
import { toActiveSubstance } from '@/domain/mappings/activeSubstance';
import { toBlogPost } from '@/domain/mappings/blog';
import { toFaqSection } from '@/domain/mappings/section';
import { toImage } from '@/domain/mappings/image';
import { toMetafield } from '@/domain/mappings/metafield';
import { toReactNode } from '@/domain/mappings/react';

import { Maybe } from '@/types';

import {
  convertStringToArray,
  createLinkPath,
  firstLetterLowerCase,
  firstLetterUpperCase,
  getMetaField,
  toArray,
} from '@/utils';

export const toProductsPage = (
  productsPage?: GatsbyPrismicProductsPage,
  shopifyProducts?: GatsbyShopifyProductConnection,
  helpPage?: GatsbyPrismicHelpPage
): _ProductsPage => {
  const productsList = productsPage?.data.list_products?.map(
    (list) => list?.product?.document
  );

  return {
    hero: {
      heading: productsPage?.data.title || ``,
    },
    productsList: {
      products:
        productsList?.map((product) =>
          toProduct(
            product as GatsbyPrismicProduct,
            productsPage,
            shopifyProducts
          )
        ) || [],
    },
    productsApp: {
      heading: {
        desktop: toReactNode(productsPage?.data.hero_heading?.richText),
        mobile: toReactNode(productsPage?.data.hero_heading?.richText),
      },
      text: toReactNode(productsPage?.data.hero_text?.richText),
      tagLink: {
        label: productsPage?.data.hero_tag_text || `Products`,
        path: createLinkPath([
          productsPage?.lang,
          helpPage?.uid,
          productsPage?.data.hero_tag_link?.uid || ``,
        ]),
      },
      pack: {
        text: productsPage?.data.hero_pack_text,
        image: toImage(productsPage?.data.hero_pack_image),
      },
      bestsellers: {
        text: productsPage?.data.hero_bestsellers_text,
        image: toImage(productsPage?.data.hero_bestsellers_image),
      },
    },
  };
};

interface ProductPageData {
  product?: GatsbyPrismicProduct;
  products?: GatsbyPrismicProductConnection;
  productPage?: GatsbyPrismicProductPage;
  productsPage?: GatsbyPrismicProductsPage;
  shopifyProduct?: GatsbyShopifyProduct;
  shopifyProducts?: GatsbyShopifyProductConnection;
  cartPage?: GatsbyPrismicCartPage;
  productSellingPlan?: GatsbyShopifyProductSellingPlan;
  blogPage?: GatsbyPrismicBlogPage;
  blogPosts?: GatsbyPrismicBlogPostConnection;
  faqSection?: GatsbyPrismicFaqSection;
  isAvailableForSale?: boolean;
}

export const toSellingPlan = (
  sellingPlan: Maybe<GatsbyShopifyProductSellingPlanSellingPlanGroupsEdgesNodeSellingPlansEdgesNode>
): _SellingPlan => ({
  id: sellingPlan?.id || ``,
  name: sellingPlan?.name || ``,
  pricingPolicies: sellingPlan?.pricingPolicies?.map(toPricingPolicy) || [],
});

export const toPricingPolicy = (
  pricingPolicy: Maybe<GatsbyShopifyProductSellingPlanSellingPlanGroupsEdgesNodeSellingPlansEdgesNodePricingPolicies>
): _PricingPolicy => ({
  adjustmentType: pricingPolicy?.adjustmentType || ``,
  adjustmentValue: {
    percentage: pricingPolicy?.adjustmentValue?.percentage || 0,
  },
});

export const toProductPage = (data?: ProductPageData): _ProductPage => {
  const sellingPlans =
    toArray(data?.productSellingPlan?.sellingPlanGroups)?.flatMap(
      (sellingPlanGroup) =>
        toArray(sellingPlanGroup?.sellingPlans)?.map(toSellingPlan)
    ) || [];

  const relatedPosts =
    toArray(data?.blogPosts)?.map((blogPost) =>
      toBlogPost(blogPost, data?.blogPage)
    ) || [];

  return {
    hero: {
      cartLink: {
        path: createLinkPath([data?.product?.lang, data?.cartPage?.uid]),
      },
      frequencyText: toReactNode(
        data?.productPage?.data.frequency_text?.richText,
        true
      ),
      sellingPlans,
      faqSection: toFaqSection(data?.faqSection),
    },
    nutritionalInformation: {
      usageTips: toReactNode(data?.product?.data.usage_tips?.richText),
      precautionsForUse: toReactNode(
        data?.product?.data.precautions_for_use?.richText
      ),
    },
    recommendedProducts:
      toArray(data?.products)?.map((product) =>
        toProduct(product, data?.productsPage, data?.shopifyProducts)
      ) || [],
    relatedBlogPosts: {
      blogPosts: relatedPosts,
    },
  };
};

export const toProduct = (
  product?: GatsbyPrismicProduct,
  productsPage?: GatsbyPrismicProductsPage,
  shopifyProducts?: GatsbyShopifyProductConnection,
  helpPage?: GatsbyPrismicHelpPage
): _Product => {
  const shopifyProduct = toArray(shopifyProducts).find(
    (shopifyProduct) => shopifyProduct.handle === product?.data.product?.handle
  );
  const shopifyProductVariant = shopifyProduct?.variants?.[0] || {
    id: ``,
    price: ``,
    compareAtPrice: ``,
    sku: ``,
    storefrontId: ``,
  };

  const metaFields = shopifyProduct?.metafields?.map((metaField) =>
    toMetafield<_ProductType & number>(metaField)
  );
  // @ts-ignore
  const type = getMetaField<_ProductType>(metaFields || [], `type`);
  // @ts-ignore
  const capacity = Number(getMetaField<number>(metaFields || [], `capacity`));

  return {
    id: product?.id || ``,
    lang: product?.lang || ``,
    name: product?.data.name || ``,
    sku: shopifyProductVariant.sku || ``,
    variantId: shopifyProductVariant?.storefrontId || ``,
    intro: toReactNode(product?.data.hero_intro?.richText),
    description: toReactNode(product?.data.hero_description?.richText),
    image: toImage(product?.data?.image),
    productWhyCta: product?.data.hero_why_supplement || ``,
    treatmentDuration: product?.data.hero_duration || ``,
    rightProductLink: {
      path: createLinkPath([
        product?.lang,
        product?.data.hero_right_product_link?.uid || ``,
      ]),
    },
    galleryImages:
      product?.data.gallery_images?.map((image) =>
        toImage(image?.gallery_image)
      ) || [],
    effectsList:
      product?.data.product_effects_list?.map((effect) =>
        toProductEffectsList(effect || undefined)
      ) || [],
    hasFreeShipping: product?.data.free_shipping || false,
    isPack: product?.data.type === `PACK` || false,
    activeSubstances:
      product?.data.active_substances
        ?.map(
          (item) =>
            item?.active_substance?.document as GatsbyPrismicActiveSubstance
        )
        .map(toActiveSubstance) || [],
    link: {
      path: createLinkPath([product?.lang, productsPage?.uid, product?.uid]),
    },
    type: type as _ProductType,
    capacity: +capacity,
    price: shopifyProductVariant.price as string,
    oldPrice: shopifyProductVariant.compareAtPrice as string,
    label: product?.data.product_label?.url || ``,
    ingredientsList:
      product?.data.list_of_ingredients?.map((ingredient) =>
        toProductIngredient(ingredient || undefined)
      ) || [],
    guaranteedWithout:
      product?.data.guaranteed_without?.map((item) => item?.item || ``) || [],
    whyHedda: {
      heading: toReactNode(product?.data.why_hedda_heading?.richText),
      text: toReactNode(product?.data.why_hedda?.richText, true),
      image: toImage(product?.data.why_hedda_image),
    },
    whySupplement: {
      heading: toReactNode(product?.data.why_supplement_heading?.richText),
      text: toReactNode(product?.data.why_supplement?.richText, true),
      highlights:
        product?.data.why_supplement_highlight?.map((highlight) =>
          toProductWhySupplementHighlight(highlight || undefined)
        ) || [],
    },
    reviews:
      product?.data.reviews?.map((review) =>
        toProductReview(review || undefined)
      ) || [],
    questions:
      product?.data.questions?.map((question) =>
        toProductQuestion(question || undefined)
      ) || [],
    packProducts: product?.data.pack_products?.map((packProduct) =>
      toProduct(
        (packProduct?.pack_product?.document as Maybe<GatsbyPrismicProduct>) ||
          undefined,
        productsPage,
        shopifyProducts
      )
    ),
    globalInformation: {
      globalQuestions:
        product?.data.global_informations_questions?.map((question) =>
          toProductGlobalInformationQuestion(question || undefined)
        ) || [],
      advantages:
        product?.data.global_informations_advantages?.map((advantage) =>
          toProductGlobalInformationAdvantage(advantage || undefined)
        ) || [],
      ingredients:
        product?.data.global_informations_ingredients?.map((ingredient) =>
          toProductGlobalInformationIngredients(
            ingredient || undefined,
            productsPage,
            helpPage,
            product?.data.type === `PACK`,
            product?.data.name || ``
          )
        ) || [],
      cautions: toReactNode(
        product?.data.global_informations_cautions?.richText
      ),
      composition: toReactNode(
        product?.data.global_informations_composition?.richText
      ),
    },
    productWhy: toProductWhy(
      product,
      (product?.data.product_why?.document as GatsbyPrismicProductWhy) ||
        undefined
    ),
  };
};

export const toProductWhy = (
  product?: GatsbyPrismicProduct,
  productWhy?: GatsbyPrismicProductWhy
): _ProductWhy => {
  return {
    id: productWhy?.id || ``,
    lang: productWhy?.lang || ``,
    title: productWhy?.data.title || ``,
    productName: productWhy?.data.product_name || ``,
    hero: {
      text: toReactNode(productWhy?.data?.text?.richText),
      abbreviation: productWhy?.data.product_abreviation || ``,
      pronunciation: productWhy?.data.pronunciation || ``,
      number: productWhy?.data.number || ``,
      numberText: toReactNode(productWhy?.data.number_text?.richText),
      highlightedWords: convertStringToArray(
        productWhy?.data.highlighted_words || ``
      ),
      productName: product?.data.name || ``,
    },
    benefits: {
      heading: toReactNode(productWhy?.data.benefits_heading?.richText),
      benefits:
        productWhy?.data.benefits?.map((benefit) =>
          toProductWhyBenefit(benefit || undefined)
        ) || [],
    },
    causes: {
      heading: toReactNode(productWhy?.data.causes_heading?.richText),
      text: toReactNode(productWhy?.data.causes_text?.richText),
      causes:
        productWhy?.data.causes?.map((cause) =>
          toProductWhyCause(cause || undefined)
        ) || [],
    },
    forms: {
      heading: toReactNode(productWhy?.data.forms_heading?.richText),
      leftText: toReactNode(productWhy?.data.forms_left_text?.richText),
      rightText: toReactNode(productWhy?.data.forms_right_text?.richText),
      forms:
        productWhy?.data.forms?.map((form) =>
          toProductWhyForm(form || undefined)
        ) || [],
      productName: product?.data.name || ``,
    },
    richAliments: {
      heading: toReactNode(productWhy?.data.aliments_heading?.richText),
      text: toReactNode(productWhy?.data.aliments_text?.richText),
      aliments:
        productWhy?.data.aliments?.map((aliment) => aliment?.aliment || ``) ||
        [],
    },
    usage: {
      heading: toReactNode(productWhy?.data.usage_title?.richText),
      usages:
        productWhy?.data.usages?.map((usage) =>
          toProductWhyUsage(usage || undefined)
        ) || [],
    },
    who: {
      heading: toReactNode(productWhy?.data.who_title?.richText),
      text: toReactNode(productWhy?.data.who_text?.richText),
      image: toImage(productWhy?.data.who_image),
      listHeading: toReactNode(productWhy?.data.whos_title?.richText),
      whos:
        productWhy?.data.whos?.map((who) =>
          toProductWhyWho(who || undefined)
        ) || [],
    },
    why: {
      heading: toReactNode(productWhy?.data.why_title?.richText),
      leftImage: toImage(productWhy?.data.why_left_image),
      rightImage: toImage(productWhy?.data.why_right_image),
      whys:
        productWhy?.data.whys?.map((why) =>
          toReactNode(why?.why_text?.richText)
        ) || [],
    },
  };
};

export const toProductBanner = (
  productWhy: string,
  productName?: string,
  productsPage?: GatsbyPrismicProductsPage
): _ProductBanner => {
  return {
    productLink: {
      label: firstLetterUpperCase(productName || ``).replace(`-`, ` `),
      path: createLinkPath([
        productsPage?.lang,
        productsPage?.uid,
        productName?.toLowerCase().replace(` `, `-`).replace(`é`, `e`),
      ]),
    },
    productWhyLink: {
      path: createLinkPath([
        productsPage?.lang,
        productsPage?.uid,
        productName?.toLowerCase().replace(` `, `-`).replace(`é`, `e`),
        firstLetterLowerCase(productWhy),
      ]),
    },
  };
};

export const toProductEffectsList = (
  productEffect?: GatsbyPrismicProductDataProductEffectsList
): _ProductEffectsList => ({
  icon: productEffect?.product_effect_icon as Icons,
  effect: productEffect?.product_effect?.text || ``,
});

export const toProductIngredient = (
  productIngredient?: GatsbyPrismicProductDataListOfIngredients
): _ProductIngredient => ({
  name: productIngredient?.ingredient_name || ``,
  quantity: productIngredient?.ingredient_quantity || ``,
});

export const toProductWhySupplementHighlight = (
  highlight?: GatsbyPrismicProductDataWhySupplementHighlight
) => ({
  heading: highlight?.highlight_heading?.text || ``,
  description: highlight?.highlight_description?.text || ``,
});

export const toProductReview = (
  productReview?: GatsbyPrismicProductDataReviews
): _ProductReview => ({
  firstname: productReview?.review_firstname || ``,
  lastname: productReview?.review_lastname || ``,
  message: productReview?.review_message || ``,
});

export const toProductQuestion = (
  productQuestion?: GatsbyPrismicProductDataQuestions
): _ProductQuestion => ({
  heading: productQuestion?.question_heading?.text,
  text: toReactNode(productQuestion?.question_text?.richText, true, {
    mb: 2,
  }),
});

export const toProductGlobalInformationQuestion = (
  productGlobalQuestion?: GatsbyPrismicProductDataGlobalInformationsQuestions
) => ({
  question: productGlobalQuestion?.heading || ``,
  answer: toReactNode(productGlobalQuestion?.text?.richText || ``),
});

export const toProductGlobalInformationIngredients = (
  productGlobalIngredient?: GatsbyPrismicProductDataGlobalInformationsIngredients,
  productsPage?: GatsbyPrismicProductsPage,
  helpPage?: GatsbyPrismicHelpPage,
  isPack?: boolean,
  product?: string
) => ({
  ingredient: productGlobalIngredient?.ingredient || ``,
  link: {
    label: productGlobalIngredient?.ingredient || ``,
    path: createLinkPath([
      productsPage?.lang,
      helpPage && !isPack && productGlobalIngredient?.ingredient !== product
        ? helpPage?.uid
        : productsPage?.uid,
      productGlobalIngredient?.ingredient_link?.uid || ``,
      !isPack && productGlobalIngredient?.ingredient === product
        ? `pourquoi`
        : ``,
    ]),
  },
});

export const toProductGlobalInformationAdvantage = (
  productGlobalAdvantage?: GatsbyPrismicProductDataGlobalInformationsAdvantages
) => ({
  heading: toReactNode(productGlobalAdvantage?.heading?.richText),
  icon: productGlobalAdvantage?.icon || ``,
  text: toReactNode(productGlobalAdvantage?.text?.richText),
  withoutList: productGlobalAdvantage?.without_list?.split(`,`) || [],
});

export const toProductWhyBenefit = (
  productWhyBenefit?: GatsbyPrismicProductWhyDataBenefits
): _Benefit => ({
  icon: productWhyBenefit?.icon as Icons,
  heading: productWhyBenefit?.heading || ``,
  text: toReactNode(productWhyBenefit?.benefit_text?.richText),
});

export const toProductWhyCause = (
  productWhyCause?: GatsbyPrismicProductWhyDataCauses
): _Cause => ({
  heading: productWhyCause?.title || ``,
  text: toReactNode(productWhyCause?.cause_text?.richText),
});

export const toProductWhyForm = (
  productWhyForm?: GatsbyPrismicProductWhyDataForms
): _Form => ({
  heddaForm: productWhyForm?.hedda_form || false,
  form: productWhyForm?.form || ``,
  tenor: productWhyForm?.tenor || ``,
  biodisponibility: productWhyForm?.biodisponibility || ``,
  specs: toReactNode(productWhyForm?.specs?.richText),
});

export const toProductWhyUsage = (
  productWhyUsage?: GatsbyPrismicProductWhyDataUsages
): _Usage => ({
  heading: toReactNode(productWhyUsage?.heading?.richText),
  firstNumber: productWhyUsage?.first_number || ``,
  firstText: toReactNode(productWhyUsage?.first_text?.richText),
  secondNumber: productWhyUsage?.second_number || ``,
  secondText: toReactNode(productWhyUsage?.second_text?.richText),
});

export const toProductWhyWho = (
  productWhyWho?: GatsbyPrismicProductWhyDataWhos
): _Who => ({
  icon: productWhyWho?.icon as Icons,
  heading: toReactNode(productWhyWho?.who_heading?.richText),
  text: toReactNode(productWhyWho?.whos_text?.richText),
});
