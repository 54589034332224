import { cartFragment } from '@/graphql';

import { graphql } from '@/utils';

export const cartCreateMutation = graphql`
  mutation CartCreateMutation($input: CartInput) {
    cartCreate(input: $input) {
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`;
