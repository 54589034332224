import {
  _ActiveSubstance,
  GatsbyPrismicActiveSubstance,
} from '@/domain/entities';

export const toActiveSubstance = (
  activeSubstance?: GatsbyPrismicActiveSubstance
): _ActiveSubstance => ({
  slug: activeSubstance?.uid || ``,
  name: activeSubstance?.data.name || ``,
  isFemale: activeSubstance?.data.female || false,
  isPlural: activeSubstance?.data.plural || false,
});
