import { useCallback } from 'react';

import { useNewHeddaClient } from '@/clients';

import { _Address, AddressCreateInput } from '@/domain/entities';

export const useShopifyAddresses = () => {
  const heddaClient = useNewHeddaClient();

  /**
   * Find customer
   */
  const findAllAddresses = useCallback(async () => {
    return await heddaClient.addresses.find();
  }, []);

  /**
   * Create customer address
   */
  const createAddress = useCallback(
    async (address: AddressCreateInput): Promise<_Address | undefined> => {
      return await heddaClient.addresses.createAddress(address);
    },
    []
  );

  /**
   * Update customer address
   */
  const updateAddress = useCallback(
    async (
      id: string,
      address: AddressCreateInput
    ): Promise<_Address | undefined> => {
      return await heddaClient.addresses.updateAddress(id, address);
    },
    []
  );

  /**
   * Delete customer address
   */
  const deleteAddress = useCallback(
    async (id: string): Promise<string | undefined> => {
      return await heddaClient.addresses.deleteAddress(id);
    },
    []
  );

  /**
   * Update customer default address
   */
  const updateDefaultAddress = useCallback(
    async (id: string): Promise<_Address> => {
      return await heddaClient.addresses.updateDefaultAddress(id);
    },
    []
  );

  return {
    createAddress,
    findAllAddresses,
    updateAddress,
    deleteAddress,
    updateDefaultAddress,
  };
};
