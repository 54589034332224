import { forwardRef } from 'react';
import {
  Input as ChakraInput,
  InputProps,
  useStyleConfig,
} from '@chakra-ui/react';

export type InputVariant = `search`;

export interface Props extends InputProps {
  variant?: InputVariant;
}

export const Input = forwardRef<HTMLInputElement, Props>(
  (props, ref): JSX.Element => {
    const { children, ...rest } = props;

    const style = useStyleConfig(`Input`, props);

    return (
      <ChakraInput ref={ref} __css={style} {...rest}>
        {children}
      </ChakraInput>
    );
  }
);
