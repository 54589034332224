import { forwardRef } from 'react';

import { Box, Skeleton, SkeletonProps } from '@/components/atoms';

export interface Props extends SkeletonProps {}

export const CartLineSkeleton = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const oneSkeleton = <Skeleton {...props} mb={2} width={800} height={19} />;

    return (
      <Box ref={ref}>
        {oneSkeleton}
        {oneSkeleton}
        {oneSkeleton}
      </Box>
    );
  }
);
