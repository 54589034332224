import { forwardRef } from 'react';

import { _Address } from '@/domain/entities';

import {
  Box,
  Conditional,
  Flex,
  FlexProps,
  IconButton,
  Text,
} from '@/components/atoms';

import { useTranslation } from '@/hooks';

export interface Props extends FlexProps {
  setSelectedAddress: (id: string) => void;
  removeAddress?: ((id: string) => Promise<void>) | undefined;
  addressLine: _Address;
}

export const AddressLine = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { addressLine, removeAddress, setSelectedAddress, ...rest } = props;

    const { t } = useTranslation();

    async function deleteAddress(id: string) {
      if (removeAddress) {
        await removeAddress(id);
      }
    }

    const countries: Array<Record<`code` | `name`, string>> = t(`countries`, {
      returnObjects: true,
    });

    const currentCountry = countries.reduce(
      (acc: { [code: string]: string }, country) => {
        acc[country.code] = country.name;
        return acc;
      },
      {}
    );

    return (
      <Flex
        ref={ref}
        as="li"
        justifyContent="space-between"
        alignItems="center"
        p={[2, 5]}
        bg={addressLine.isDefault ? `gray.75` : `transparent`}
        borderRadius="xl"
        transition=".3s"
        _last={{
          borderBottomWidth: 0,
          mb: 0,
        }}
        {...rest}
      >
        <Flex width="100%">
          <Box width="100%">
            <Flex
              flexDirection={[`column`, `row`]}
              alignItems={[`flex-start`, `center`]}
            >
              <Flex>
                <Text variant="3" mr={2} fontWeight="bold">
                  {addressLine.firstName}
                </Text>
                <Text variant="3" fontWeight="bold">
                  {addressLine.lastName}
                </Text>
              </Flex>
              <Box ml={[0, 3]}>
                <Text variant="4" color="gray.700">
                  {addressLine.phone}
                </Text>
              </Box>
            </Flex>
            <Flex flexDirection="column">
              <Flex flexDirection={[`column`, null, null, `row`]}>
                <Flex>
                  <Text variant="4" mr={1} color="gray.500">
                    {addressLine.street}
                  </Text>
                  <Text variant="4" color="gray.500" mr={2}>
                    {addressLine.apartment}
                  </Text>
                </Flex>
                <Flex flexDirection={[`column`, `row`]}>
                  <Text variant="4" mr={1} color="gray.500">
                    {addressLine.zip}
                  </Text>
                  <Text variant="4" mr={2} color="gray.500">
                    {addressLine.city}
                  </Text>
                </Flex>
              </Flex>
              <Text variant="4" mr={2} color="gray.500">
                {currentCountry[addressLine.countryCode]}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex>
          <Conditional isTrue={addressLine.isDefault}>
            <IconButton
              icon="circle-check"
              aria-label={t(`account.aria_labels.delete`)}
              size="md"
            />
          </Conditional>
          <IconButton
            icon="pen-to-square"
            ml={2}
            aria-label={t(`account.aria_labels.edit`)}
            size="md"
            onClick={() => setSelectedAddress(addressLine.id)}
          />
          <Conditional isTrue={!addressLine.isDefault}>
            <IconButton
              icon="trash"
              ml={2}
              aria-label={t(`account.aria_labels.delete`)}
              size="md"
              onClick={() => deleteAddress(addressLine.id)}
            />
          </Conditional>
        </Flex>
      </Flex>
    );
  }
);
