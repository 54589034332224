import { forwardRef } from 'react';
import {
  Skeleton as ChakraSkeleton,
  SkeletonProps,
  useStyleConfig,
} from '@chakra-ui/react';

export interface Props extends SkeletonProps {}

export const Skeleton = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const style = useStyleConfig(`Skeleton`, props);

    return <ChakraSkeleton ref={ref} __css={style} {...props} />;
  }
);
