import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

import { Icon as icon, Icons } from '@/config';

export interface Props extends Omit<FontAwesomeIconProps, `icon`> {
  icon: Icons;
}

export function Icon(props: Props): JSX.Element {
  return (
    <FontAwesomeIcon {...props} icon={icon[props.icon] || `sensor-alert`} />
  );
}
