import { useEffect } from 'react';
import { globalHistory } from '@reach/router';

import { useCart } from '@/state';

export function Router() {
  const { isCartReady, setIsAddedToCart, setIsReadyToGo } = useCart();

  useEffect(() => {
    return globalHistory.listen(({ action, location }) => {
      if (isCartReady) {
        if (
          action === `PUSH` &&
          location.pathname.includes(`nos-complements`)
        ) {
          setIsAddedToCart(false);
          setIsReadyToGo(false);
        }
      }
    });
  }, [isCartReady]);

  return <></>;
}
