import { HeddaRepository } from '@/repositories/hedda';

export class NewHeddaMarketingRepository extends HeddaRepository {
  async subscribe(email: string, lang: string): Promise<string> {
    return await this.client.post(
      `${this.url}/marketing/newsletter-subscribe`,
      {
        body: {
          email,
          locale: lang,
        },
      }
    );
  }
}
