import { cartFragment } from '@/graphql';

import { graphql } from '@/utils';

export const cartDiscountCodesUpdateMutation = graphql`
  mutation CartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ...CartFragment
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartFragment}
`;
