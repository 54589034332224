import { forwardRef } from 'react';

import Step4 from '@/svg/HomePage/step4.svg';

// @ts-ignore
import { _HomeStepV2 } from '@/domain/entities';

import {
  Box,
  BoxProps,
  Checks,
  Conditional,
  Discussion,
  Flex,
  Heading,
  ImageBorder,
  Informations,
  Text,
} from '@/components/atoms';

import { useIsMobile } from '@/hooks';

export interface Props extends BoxProps {
  // @ts-ignore
  step: _HomeStepV2;
  stepKey: number;
}

export const StepV2 = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { step, stepKey, ...rest } = props;

    const isMobile = useIsMobile();

    const renderSVG = () => {
      switch (stepKey) {
        case 0:
          return (
            <Discussion
              width={isMobile ? 200 : undefined}
              height={isMobile ? 150 : undefined}
            />
          );
        case 1:
          return (
            <Checks
              width={isMobile ? 200 : undefined}
              height={isMobile ? 150 : undefined}
            />
          );
        case 2:
          return <Informations />;
        case 3:
          return <Step4 />;
        default:
          return <></>;
      }
    };

    return (
      <Flex
        ref={ref}
        justifyContent={[`center`, null, null, `space-between`]}
        position="relative"
        bg="gray.100"
        borderRadius={20}
        w="100%"
        h="100%"
        boxSizing="border-box"
        {...rest}
      >
        <Flex
          px={[4, null, 8, 10]}
          py={[4, null, 8, 10]}
          position="relative"
          flexDir={[`column`, null, null, `row`, `row`]}
          alignItems={
            // eslint-disable-next-line no-nested-ternary
            stepKey === 3
              ? `center`
              : stepKey === 0
              ? `center`
              : [`center`, null, `flex-start`]
          }
          justifyContent={stepKey < 2 ? `flex-start` : `center`}
          w="100%"
        >
          <Flex
            flexDir={
              stepKey === 0 ? [`column`, `column`, null, `row`] : `column`
            }
            alignItems={stepKey !== 1 ? `center` : `left`}
            justifyContent="space-between"
            w={stepKey === 0 ? `full` : `initial`}
          >
            <Conditional isTrue={stepKey === 1 || stepKey === 3}>
              <Flex mb={10} justifyContent="center" w="100%">
                {renderSVG()}
              </Flex>
            </Conditional>
            <Flex
              flexDir="column"
              textAlign={stepKey < 2 ? [`center`, null, `left`] : `center`}
            >
              <Box mb={2}>
                <Heading as="h3" variant={isMobile ? `h3` : `h2`}>
                  {step.heading}
                </Heading>
              </Box>
              <Box mb={stepKey === 2 ? 2 : `initial`}>
                <Text variant="3" color="gray.500" mb={10}>
                  {step.text}
                </Text>
              </Box>
            </Flex>
            <Conditional isTrue={stepKey === 0 || stepKey === 2}>
              <Box position="relative" mt={stepKey === 2 ? 5 : `initial`}>
                <Conditional isTrue={stepKey === 2}>
                  <ImageBorder width={210} height={230} bottom={3} left={-5} />
                </Conditional>
                <Box position="relative">{renderSVG()}</Box>
              </Box>
            </Conditional>
          </Flex>
        </Flex>
      </Flex>
    );
  }
);
