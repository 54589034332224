import { ReactNode, useEffect, useMemo, useState } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuProps,
  Portal,
} from '@chakra-ui/react';

import { Countries, Country, TrackingValue } from '@/config';

import { GatsbyFileGroupConnection } from '@/domain/entities';

import {
  Box,
  Emoji,
  Flex,
  Icon,
  Image,
  Suspense,
  Text,
} from '@/components/atoms';

import { useGetUniqueKey, useIsIos, useTracking } from '@/hooks';

import { useI18n } from '@/state';

import { toArray } from '@/utils';

function LabelWithCountry(props: {
  countryFlag: string;
  flag: ReactNode;
  label: ReactNode;
}): JSX.Element {
  const { countryFlag, flag, label } = props;
  const ios = useIsIos();
  const flags =
    useStaticQuery<Record<`allFile`, GatsbyFileGroupConnection>>(flagsQuery);
  const [matchingFlag] = toArray(flags.allFile).filter((flag) =>
    flag.relativePath.includes(countryFlag)
  );

  return (
    <Flex alignItems="center">
      <Suspense
        isLoading={ios}
        fallback={
          <Box mr={4}>
            <Emoji symbol={flag} label={countryFlag} />
          </Box>
        }
      >
        {matchingFlag.childImageSharp && (
          <Image
            image={matchingFlag.childImageSharp.gatsbyImageData}
            alt={countryFlag}
            width="20px"
            mr={2}
            right={1}
            sx={{ zIndex: 0 }}
          />
        )}
      </Suspense>
      <Text variant="3" fontWeight="semibold">
        {label}
      </Text>
    </Flex>
  );
}

export interface Props extends Omit<MenuProps, `children`> {
  switchOnChange?: boolean;
  suggestedCountry?: Country;
  handleClick?: (country: Country) => void;
}

export function CountrySwitcher(props: Props): JSX.Element {
  const { switchOnChange, suggestedCountry, handleClick, ...rest } = props;
  const { country, setCountry, invalidCountry, setInvalidCountry } = useI18n();
  const [currentCountry, setCurrentCountry] = useState<Country>();
  const getUniqueKey = useGetUniqueKey();
  const { track } = useTracking();

  useEffect(() => {
    setCurrentCountry(suggestedCountry || country);
  }, [suggestedCountry, country]);

  useEffect(() => {
    if (currentCountry) {
      handleClick?.(currentCountry);
    }
  }, [currentCountry]);

  const countries = useMemo(
    () =>
      Object.values(Countries)
        .filter((country) => !country.isDisabled)
        .filter((c) => c.path !== currentCountry?.path),
    [Countries, currentCountry]
  );
  const hasCountries = useMemo(() => countries.length > 0, [countries]);

  const onClick = async (country: Country) => {
    if (switchOnChange) {
      if (invalidCountry) {
        setInvalidCountry(false);
      }
      track(`layout-footer-country-switcher`, {
        [TrackingValue.COUNTRY]: country,
      });
      setCountry(country);
      await navigate(`/${country.path}`);
    } else {
      setCurrentCountry(country);
    }
  };

  const renderCountries = countries.map((country) => (
    <MenuItem
      data-test={country.iso}
      key={getUniqueKey(country)}
      onClick={() => onClick(country)}
    >
      <LabelWithCountry
        countryFlag={country.iso}
        flag={country.flag}
        label={country.label}
      />
    </MenuItem>
  ));

  return (
    <Menu isLazy {...rest}>
      <MenuButton data-test="country-selector">
        <LabelWithCountry
          countryFlag={currentCountry?.iso || ``}
          flag={currentCountry?.flag}
          label={currentCountry?.label}
        />
        {hasCountries && <Icon icon="chevron-down" fontSize={12} />}
      </MenuButton>
      {hasCountries && (
        <Portal>
          <MenuList>{renderCountries}</MenuList>
        </Portal>
      )}
    </Menu>
  );
}

const flagsQuery = graphql`
  query Flags {
    allFile(filter: { relativeDirectory: { eq: "flags" } }) {
      edges {
        node {
          id
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
