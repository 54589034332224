import { Maybe } from '@hdd/types';

import {
  ShopifyStorefrontOrderFinancialStatus,
  ShopifyStorefrontOrderFulfillmentStatus,
} from '@shopify/types';

export const renderOrderBadge = (
  status?: Maybe<
    | ShopifyStorefrontOrderFulfillmentStatus
    | ShopifyStorefrontOrderFinancialStatus
  >
) => {
  switch (status) {
    // Fulfillment
    case ShopifyStorefrontOrderFulfillmentStatus.Fulfilled:
      return {
        bg: `green.100`,
        color: `green.900`,
        children: `Commande traitée`,
      };
    case ShopifyStorefrontOrderFulfillmentStatus.InProgress:
      return {
        bg: `green.100`,
        color: `green.900`,
        children: `Commande en cours`,
      };
    case ShopifyStorefrontOrderFulfillmentStatus.OnHold:
      return {
        bg: `orange.100`,
        color: `orange.900`,
        children: `Commande en attente`,
      };
    case ShopifyStorefrontOrderFulfillmentStatus.Open:
      return {
        bg: `orange.100`,
        color: `orange.900`,
        children: `Commande ouverte`,
      };
    case ShopifyStorefrontOrderFulfillmentStatus.PartiallyFulfilled:
      return {
        bg: `orange.100`,
        color: `orange.900`,
        children: `Partiellement traitée`,
      };
    case ShopifyStorefrontOrderFulfillmentStatus.PendingFulfillment:
      return {
        bg: `orange.100`,
        color: `orange.900`,
        children: `En attente de traitement`,
      };
    case ShopifyStorefrontOrderFulfillmentStatus.Restocked:
      return {
        bg: `green.100`,
        color: `green.900`,
        children: `Restockée`,
      };
    case ShopifyStorefrontOrderFulfillmentStatus.Scheduled:
      return {
        bg: `green.100`,
        color: `green.900`,
        children: `Commande programmée`,
      };
    case ShopifyStorefrontOrderFulfillmentStatus.Unfulfilled:
      return {
        bg: `orange.100`,
        color: `orange.900`,
        children: `Commande non-traitée`,
      };
    // Financial
    case ShopifyStorefrontOrderFinancialStatus.Authorized:
      return {
        bg: `green.100`,
        color: `green.900`,
        children: `Paiement autorisé`,
      };
    case ShopifyStorefrontOrderFinancialStatus.Paid:
      return {
        bg: `green.100`,
        color: `green.900`,
        children: `Commande payée`,
      };
    case ShopifyStorefrontOrderFinancialStatus.PartiallyPaid:
      return {
        bg: `orange.100`,
        color: `orange.900`,
        children: `Partiellement payée`,
      };
    case ShopifyStorefrontOrderFinancialStatus.PartiallyRefunded:
      return {
        bg: `orange.100`,
        color: `orange.900`,
        children: `Partiellement remboursée`,
      };
    case ShopifyStorefrontOrderFinancialStatus.Pending:
      return {
        bg: `orange.100`,
        color: `orange.900`,
        children: `Paiement en attente`,
      };
    case ShopifyStorefrontOrderFinancialStatus.Refunded:
      return {
        bg: `green.100`,
        color: `green.900`,
        children: `Commande remboursée`,
      };
    case ShopifyStorefrontOrderFinancialStatus.Voided:
      return {
        bg: `red.100`,
        color: `red.900`,
        children: `Commande annulée`,
      };
    default:
      return {
        bg: `gray.100`,
        color: `black`,
        children: `Inconnu`,
      };
  }
};
