import { Dispatch, SetStateAction, useEffect } from 'react';

import { LocalStorage } from '@/config';

import { _Link } from '@/domain/entities';

import { Box, Flex, Link, Text } from '@/components/atoms';

import { useTranslation } from '@/hooks';

import { useBannersState } from '@/state';

export interface Props {
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  legalLink: _Link;
}

export function CookiesConsentBanner(props: Props): JSX.Element {
  const { legalLink, setIsVisible, ...rest } = props;
  const { setCookiesBannerVisible } = useBannersState();
  const { t } = useTranslation();

  useEffect(() => {
    const isHidden = localStorage.getItem(LocalStorage.CookiesBannerIsHidden);
    setIsVisible(isHidden === null || isHidden === `false`);
    setCookiesBannerVisible(isHidden === null || isHidden === `false`);
  }, []);

  return (
    <Box py={5} {...rest}>
      <Flex display="block">
        <Box ml={5} mr={5}>
          <Text
            variant="3"
            color="gray.500"
            fontWeight="medium"
            fontSize={14}
            mb={5}
          >
            {t(`cookiesBanner.intro`)}
          </Text>
          <Link to={legalLink.path}>
            <Text
              color="gray.700"
              variant="4"
              fontSize={14}
              textDecoration="underline"
              data-test="accept-cookies"
              _hover={{ textDecoration: `none` }}
            >
              {t(`cookiesBanner.engagement`)}
            </Text>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
}
