import { HttpClient } from '@/clients';

import { StoreRepository } from '@/domain/repositories';

export abstract class HeddaRepository implements StoreRepository {
  client: HttpClient;
  url: string;

  constructor(client: HttpClient, url: string) {
    this.client = client;
    this.url = url;
  }
}
