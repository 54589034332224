import { forwardRef } from 'react';
import { Link } from '@chakra-ui/react';

import { Languages } from '@/config';

import { Platform } from '@/domain/types';

import { Box, Flex, FlexProps } from '@/components/atoms';
import { ButtonBorder } from '@/components/atoms/ButtonBorder';

import { useTranslation } from '@/hooks';

export interface Props extends FlexProps {
  lang?: Languages;
  onStoreBadgeClick?: (platform: Platform) => void;
}

export const StoreButtons = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { lang: _lang, onStoreBadgeClick, ...rest } = props;
    const language = _lang || `fr`;
    const { t } = useTranslation();

    return (
      <Flex
        ref={ref}
        flexDirection={[`column`, `row`]}
        alignItems="center"
        {...rest}
      >
        <Box mr={[0, 5, 5]} mb={[5, 0, 0, 1, 0]}>
          <ButtonBorder
            width={150}
            height={65}
            onClick={() => onStoreBadgeClick?.(`apple`)}
          >
            <Link
              href={`https://apps.apple.com/be/app/hedda/id1575232503?l=${language}`}
              target="_blank"
              rel="noopener noreferrer"
              title="App Store"
              display="inline-block"
              _hover={{ style: `none` }}
            >
              {t(`common.app_store`)}
            </Link>
          </ButtonBorder>
        </Box>
        <Box>
          <ButtonBorder
            width={170}
            height={65}
            onClick={() => onStoreBadgeClick?.(`android`)}
            mb={[0, 0, 0, 1, 0]}
          >
            <Link
              href={`https://play.google.com/store/apps/details?id=app.hedda.mobile&hl=${language}`}
              target="_blank"
              rel="noopener noreferrer"
              title="Google Play"
              display="inline-block"
              _hover={{ style: `none` }}
            >
              {t(`common.google_play`)}
            </Link>
          </ButtonBorder>
        </Box>
      </Flex>
    );
  }
);
