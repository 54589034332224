import { cartFragment } from '@/graphql';

import { graphql } from '@/utils';

export const cartQuery = graphql`
  query CartQuery($id: ID!) {
    cart(id: $id) {
      ...CartFragment
    }
  }
  ${cartFragment}
`;
