import { forwardRef, ReactNode, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import { Icons } from '@/config';

import { Box, Flex, FlexProps, MotionBox, Tag } from '@/components/atoms';

import {
  useGetUniqueKey,
  useInterval,
  useIsMobile,
  useTranslation,
} from '@/hooks';

export interface Props extends FlexProps {}

export const TagHighlights = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { t } = useTranslation();
    const getUniqueKey = useGetUniqueKey();
    const isMobile = useIsMobile();
    const [index, setIndex] = useState(0);

    const onChange = () => {
      if (index < highlights.length - 1) {
        setIndex((I) => I + 1);
      } else {
        setIndex(0);
      }
    };

    useInterval(onChange, isMobile ? 2000 : null);

    const highlights = useMemo<{ icon: Icons; label: ReactNode }[]>(
      () => [
        {
          icon: `lock`,
          label: t(`highlights.securePayment`),
        },
        {
          icon: `shipping-fast`,
          label: t(`highlights.fastShipping`),
        },
        {
          icon: `undo-alt`,
          label: t(`highlights.freeReturn`),
        },
      ],
      [t]
    );

    const renderHighlights = highlights.map((highlight) => (
      <Box key={getUniqueKey(highlight)} mr={8} _last={{ mr: 0 }}>
        <Tag icon={highlight.icon}>{highlight.label}</Tag>
      </Box>
    ));

    const renderMobileHighlights = highlights.map((highlight, key) => (
      <AnimatePresence key={getUniqueKey(highlight)}>
        {index === key && (
          <MotionBox
            initial={{ opacity: 0, display: `none` }}
            animate={{
              opacity: 1,
              display: `block`,
            }}
            exit={{ opacity: 0, display: `none` }}
          >
            <Tag icon={highlight.icon}>{highlight.label}</Tag>
          </MotionBox>
        )}
      </AnimatePresence>
    ));

    return (
      <Flex
        ref={ref}
        justifyContent={[`center`, null, `flex-start`]}
        alignItems="center"
        {...props}
      >
        {isMobile ? renderMobileHighlights : renderHighlights}
      </Flex>
    );
  }
);
