import { Icons } from '@/config';

import { _Link } from '@/domain/entities';
import { SocialMedia } from '@/domain/types';

import { BoxProps, Flex, Icon, Link, Text } from '@/components/atoms';

import { useGetUniqueKey, useTranslation } from '@/hooks';

export interface Props extends BoxProps {
  title?: string;
  url?: string;
}

export function BlogPostShare(props: Props): JSX.Element {
  const { title, url, ...rest } = props;
  const getUniqueKey = useGetUniqueKey();
  const { t } = useTranslation();

  const shareLinks: _Link<SocialMedia>[] = [
    {
      label: `facebook`,
      path: `https://www.facebook.com/sharer/sharer.php?u=hedda.health${url}`,
    },
    {
      label: `linkedin`,
      path: `https://www.linkedin.com/shareArticle?mini=true&url=hedda.health${url}`,
    },
    {
      label: `pinterest`,
      path: `https://pinterest.com/pin/create/button/?url=${url}&description=hedda.health${title}`,
    },
    {
      label: `twitter`,
      path: `https://twitter.com/share?text=${title}&url=hedda.health${url}`,
    },
  ];

  const renderShareLinks = shareLinks.map((shareLink) => (
    <Flex
      alignItems="center"
      key={getUniqueKey(shareLink)}
      mr={5}
      _last={{ mr: 0 }}
      as={(rest) => <Link to={shareLink.path} target="_blank" {...rest} />}
      _hover={{
        svg: {
          path: {
            fill: `url(#paint0_linear_3333_9386)`,
          },
        },
      }}
    >
      <svg width="0" height="0">
        <defs>
          <radialGradient
            id="paint0_linear_3333_9386"
            r="150%"
            cx="30%"
            cy="107%"
          >
            <stop stopColor="#A770EF" />
            <stop offset="0.5" stopColor="#CF8BF3" />
            <stop offset="1" stopColor="#FDB99B" />
          </radialGradient>
        </defs>
      </svg>
      <Icon icon={shareLink.label as Icons} />
    </Flex>
  ));

  return (
    <Flex align="center" {...rest}>
      <Text variant="2" mr={5}>
        {t(`blog.shareOn`)}
      </Text>
      <Flex
        bgColor="gray.100"
        alignItems="center"
        p={2}
        pl={3}
        pr={3}
        borderRadius={8}
      >
        {renderShareLinks}
      </Flex>
    </Flex>
  );
}
