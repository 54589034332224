import {
  _HeddaPage,
  _HorizontalSection,
  GatsbyPrismicHeddaPage,
  GatsbyPrismicHeddaPageDataBody1HorizontalSectionItem,
  GatsbyPrismicHeddaPageDataBody2HorizontalSectionItem,
  GatsbyPrismicHeddaPageDataBodyHorizontalSectionItem,
} from '@/domain/entities';
import { toImage } from '@/domain/mappings/image';
import { toReactNode } from '@/domain/mappings/react';

import { convertStringToArray } from '@/utils';

export const toHeddaPage = (
  heddaPage?: GatsbyPrismicHeddaPage
): _HeddaPage => ({
  hero: {
    heading: toReactNode(heddaPage?.data.hero_heading?.richText),
    highlightedWords: convertStringToArray(
      heddaPage?.data.hero_highlighted_words || ``
    ),
    text: toReactNode(heddaPage?.data.hero_text?.richText),
    imageLeft: toImage(heddaPage?.data.hero_left_image),
    imageRight: toImage(heddaPage?.data.hero_right_image),
  },
  supplements: {
    slug: heddaPage?.data.supplements_slug || ``,
    heading: toReactNode(heddaPage?.data.supplements_heading?.richText),
    subtitle: toReactNode(heddaPage?.data.supplements_subtitle?.richText),
    buttonText: toReactNode(heddaPage?.data.supplements_button_text?.richText),
    image: toImage(heddaPage?.data.supplements_image),
    listHeading: toReactNode(
      heddaPage?.data.supplements_list_heading?.richText
    ),
    list:
      heddaPage?.data.supplements_list?.map((listItem) =>
        toReactNode(listItem?.list_item?.richText)
      ) || [],
    listImage: toImage(heddaPage?.data.supplements_list_image),
    horizontalContent:
      heddaPage?.data.body?.[0]?.items?.map((item) =>
        toHorizontalSection(item)
      ) || [],
  },
  engagements: {
    slug: heddaPage?.data.engagements_slug || ``,
    heading: toReactNode(heddaPage?.data.engagements_heading?.richText),
    subtitle: toReactNode(heddaPage?.data.engagements_subtitle?.richText),
    highlightedWords: convertStringToArray(
      heddaPage?.data.engagements_highlighted_words || ``
    ),
    buttonText: toReactNode(heddaPage?.data.engagements_button_text?.richText),
    image: toImage(heddaPage?.data.engagements_image),
    listHeading: toReactNode(
      heddaPage?.data.engagements_list_heading?.richText
    ),
    list:
      heddaPage?.data.engagements_list?.map((listItem) =>
        toReactNode(listItem?.list_item?.richText)
      ) || [],
    horizontalContent:
      heddaPage?.data.body1?.[0]?.items?.map((item) =>
        toHorizontalSection(item)
      ) || [],
  },
  whoWeAre: {
    slug: heddaPage?.data.who_we_are_slug || ``,
    heading: toReactNode(heddaPage?.data.who_we_are_heading?.richText),
    highlightedWords: convertStringToArray(
      heddaPage?.data.who_we_are_highlighted_words || ``
    ),
    subtitle: toReactNode(heddaPage?.data.who_we_are_subtitle?.richText),
    buttonText: toReactNode(heddaPage?.data.who_we_are_button_text?.richText),
    imageLeft: toImage(heddaPage?.data.who_we_are_left_image),
    imageRight: toImage(heddaPage?.data.who_we_are_right_image),
    listHeading: toReactNode(heddaPage?.data.who_we_are_list_title?.richText),
    list:
      heddaPage?.data.who_we_are_list?.map((listItem) =>
        toReactNode(listItem?.list_item?.richText)
      ) || [],
    horizontalContent:
      heddaPage?.data.body2?.[0]?.items?.map((item) =>
        toHorizontalSection(item)
      ) || [],
  },
});

export const toHorizontalSection = (
  heddaHorizontal?: horizontalBodyType
): _HorizontalSection => ({
  heading: toReactNode(heddaHorizontal?.heading?.richText),
  text: toReactNode(heddaHorizontal?.text?.richText),
  image:
    heddaHorizontal?.image?.gatsbyImageData !== null
      ? toImage(heddaHorizontal?.image)
      : undefined,
  // @ts-ignore
  textBackground: toImage(heddaHorizontal?.text_background),
});

type horizontalBodyType =
  | GatsbyPrismicHeddaPageDataBodyHorizontalSectionItem
  | GatsbyPrismicHeddaPageDataBody1HorizontalSectionItem
  | GatsbyPrismicHeddaPageDataBody2HorizontalSectionItem;
