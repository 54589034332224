import { graphql } from '@/utils';

export const customerAccessTokenCreateMutation = graphql`
  mutation CustomerAccessTokenCreateMutation(
    $input: CustomerAccessTokenCreateInput!
  ) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;
