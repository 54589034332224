import { cartFragment } from '@/graphql';

import { graphql } from '@/utils';

export const cartLinesRemoveMutation = graphql`
  mutation CartLinesAddMutation($cartId: ID!, $lineIds: [ID!]!) {
    cart: cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`;
