import { _MetaField, GatsbyShopifyMetafield } from '@/domain/entities';

import { Maybe } from '@/types';

export const toMetafield = <T>(
  metafield: Maybe<GatsbyShopifyMetafield>
): _MetaField<T> => ({
  key: metafield?.key || ``,
  value: metafield?.value as unknown as T,
});
