import { forwardRef, ReactNode } from 'react';

import {
  Box,
  Button,
  Flex,
  FlexProps,
  Input,
  InputProps,
} from '@/components/atoms';

import { useTranslation } from '@/hooks';

export interface Props extends FlexProps {
  inputProps?: InputProps;
  hasButton?: boolean;
  buttonText?: ReactNode;
}

export const NewsletterEmail = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { inputProps, hasButton = true, buttonText, ...rest } = props;
    const { t } = useTranslation();

    return (
      <Flex ref={ref} alignItems="center" {...rest}>
        <Box mr={2}>
          <Input
            data-test="newsletter-input"
            variant="search"
            type="email"
            mr={2}
            width={[`100%`, null, null, 400]}
            height={14}
            {...inputProps}
          />
        </Box>
        {hasButton && (
          <Box>
            <Button
              data-test="newsletter-button"
              title={t(`productPage.go`) || undefined}
              type="submit"
              size="sm"
              height={14}
              rightIcon="paper-plane_light"
            >
              {buttonText}
            </Button>
          </Box>
        )}
      </Flex>
    );
  }
);
