import { Icons } from '@/config';

import {
  _DownloadAppSection,
  _FaqSection,
  _Highlight,
  _HighlightsSection,
  _NewsletterSection,
  GatsbyPrismicDownloadAppSectionDataType,
  GatsbyPrismicFaqSection,
  GatsbyPrismicHighlightsSectionDataHighlights,
  GatsbyPrismicHighlightsSectionDataType,
  GatsbyPrismicHomePage,
  GatsbyPrismicNewsletterSectionDataType,
} from '@/domain/entities';
import { toReactNode } from '@/domain/mappings/react';

import { createLinkPath } from '@/utils';

export const toFaqSection = (
  faqSection?: GatsbyPrismicFaqSection
): _FaqSection => ({
  heading: faqSection?.data.heading || ``,
  text: faqSection?.data.text || ``,
  link: {
    path: createLinkPath([
      faqSection?.data?.link?.lang || ``,
      faqSection?.data.link?.uid || ``,
    ]),
  },
});

export const toHighlightsSection = (
  highlightsSection?: GatsbyPrismicHighlightsSectionDataType
): _HighlightsSection => ({
  heading: toReactNode(highlightsSection?.heading?.richText),
  highlights:
    highlightsSection?.highlights?.map((highlight) =>
      toHighlight(highlight || undefined)
    ) || [],
});

export const toHighlight = (
  highlight?: GatsbyPrismicHighlightsSectionDataHighlights,
  homePage?: GatsbyPrismicHomePage
): _Highlight => ({
  icon: highlight?.highlight_icon as Icons,
  iconColor: highlight?.icon_color || ``,
  heading: toReactNode(highlight?.title?.richText),
  text: toReactNode(highlight?.text?.richText),
  link: {
    label: highlight?.highlight_text || `Highlight`,
    path: createLinkPath([
      homePage?.lang,
      highlight?.highlight_link?.uid || ``,
    ]),
  },
});

export const toDownloadAppSection = (
  downloadAppSection?: GatsbyPrismicDownloadAppSectionDataType,
  homePage?: GatsbyPrismicHomePage
): _DownloadAppSection => ({
  heading: toReactNode(downloadAppSection?.heading?.richText),
  text: {
    desktop: toReactNode(downloadAppSection?.text?.richText),
    mobile: toReactNode(downloadAppSection?.text?.richText),
  },
  learnMore: {
    label: downloadAppSection?.learn_more_label || `Download`,
    path: createLinkPath([
      homePage?.lang,
      downloadAppSection?.learn_more_link?.uid || ``,
    ]),
  },
});

export const toNewsletterSection = (
  newsletterSection?: GatsbyPrismicNewsletterSectionDataType
): _NewsletterSection => ({
  heading: toReactNode(newsletterSection?.heading?.richText),
  text: toReactNode(newsletterSection?.text?.richText),
  button: toReactNode(newsletterSection?.button?.richText),
  confirmed: toReactNode(newsletterSection?.confirmed?.richText),
  textConfirmed: toReactNode(newsletterSection?.confirmed_text?.richText),
});
