import { createIcon } from '@chakra-ui/icons';

export const Search = createIcon({
  displayName: `Search`,
  viewBox: `0 0 24 29`,
  defaultProps: {
    width: `24px`,
    height: `29px`,
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM17.3329 22.7529C15.7264 23.5511 13.9156 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 15.9749 22.0674 19.4984 19.0906 21.6821L23.7046 26.2962C24.0951 26.6867 24.0951 27.3199 23.7046 27.7104C23.3141 28.1009 22.6809 28.1009 22.2904 27.7104L17.3329 22.7529Z"
    />
  ),
});
