import { forwardRef } from 'react';
import dayjs from 'dayjs';

import { DateFormat } from '@/config';

import { _Order } from '@/domain/entities';
import { toCurrency } from '@/domain/mappings';

import { Badge, Box, Button, Flex, FlexProps, Text } from '@/components/atoms';

import { renderOrderBadge, toPrice } from '@/utils';

export interface Props extends Omit<FlexProps, `order`> {
  order: _Order;
  selected?: boolean;
}

export const OrderLine = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { order, selected, ...rest } = props;

    return (
      <Flex
        ref={ref}
        as={(props) => (
          <Button color="black" _hover={{ color: `black` }} {...props} />
        )}
        w="100%"
        height="100%"
        flexDirection={[`column`, null, null, `row`]}
        justifyContent="space-between"
        borderBottomWidth={[0, null, 2]}
        borderBottomColor="gray.50"
        borderBottomStyle="solid"
        p={5}
        mb={2}
        bg={selected ? `gray.50` : `white`}
        borderRadius={selected ? `xl` : `none`}
        transition=".3s"
        _hover={{
          bg: `gray.50`,
          borderRadius: `xl`,
        }}
        {...rest}
      >
        <Flex
          width={[`100%`, null, null, `30%`]}
          justifyContent={[`space-between`, `flex-start`]}
          alignItems="center"
          mr={[0, null, null, 5]}
          mb={[2, null, null, 0]}
        >
          <Box mr={8}>
            <Text variant="3" fontWeight="semibold">
              #{` `}
              {order.orderNumber}
            </Text>
          </Box>
          <Box>
            <Text variant="3" fontWeight="semibold">
              {dayjs(order.processedAt).format(DateFormat.DD_MM_YYYY)}
            </Text>
          </Box>
        </Flex>
        <Flex
          width={[`100%`, null, null, `70%`]}
          flexDirection={[`column`, `row`]}
          justifyContent="space-between"
          alignItems={[`flex-start`, `center`]}
        >
          <Flex
            width="100%"
            justifyContent={[`space-between`, `flex-start`]}
            alignItems="center"
          >
            <Box mb={[2, null, 0]} mr={5}>
              <Text variant="3" fontWeight="semibold">
                {toPrice(
                  order.totalPriceV2?.amount,
                  toCurrency(order.totalPriceV2?.currencyCode)
                )}
              </Text>
            </Box>
          </Flex>
          <Box w={[null, null, `auto`]} textAlign="right">
            <Badge
              size="sm"
              w={[null, null, 200]}
              textAlign="center"
              {...renderOrderBadge(order.fulfillmentStatus)}
            />
          </Box>
        </Flex>
      </Flex>
    );
  }
);
