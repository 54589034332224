import { forwardRef } from 'react';

import { _LineItem } from '@/domain/entities';
import { toCurrency } from '@/domain/mappings';

import { Box, Flex, FlexProps, Text } from '@/components/atoms';

import { useTranslation } from '@/hooks';

import { toPrice } from '@/utils';

export interface Props extends FlexProps {
  lineItem: _LineItem;
}

export const OrderDetailLine = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const { lineItem, ...rest } = props;

    const { t } = useTranslation();

    return (
      <Flex
        ref={ref}
        as="li"
        justifyContent="space-between"
        pb={5}
        mb={5}
        borderBottomWidth={2}
        borderBottomColor="gray.200"
        borderBottomStyle="solid"
        _last={{
          borderBottomWidth: 0,
          pb: 0,
          mb: 0,
        }}
        {...rest}
      >
        <Box>
          <Text variant="3" fontWeight="semibold" mr={2}>
            {lineItem.productTitle}
          </Text>
          <Text variant="4" color="gray.500">
            {t(`common.quantity`)}: {lineItem.quantity}
          </Text>
        </Box>
        <Box>
          <Text variant="3" fontWeight="semibold" textAlign="right">
            {toPrice(
              lineItem.priceV2?.amount,
              toCurrency(lineItem.priceV2?.currencyCode)
            )}
          </Text>
        </Box>
      </Flex>
    );
  }
);
