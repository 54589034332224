import { Dispatch, SetStateAction, useEffect } from 'react';
import { Switch } from '@chakra-ui/react';

import { LocalStorage } from '@/config';

import { Box, Flex, Text } from '@/components/atoms';

import { useTranslation } from '@/hooks';

import { useBannersState } from '@/state';

export interface Props {
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  setIsPerformanceChecked: Dispatch<SetStateAction<boolean>>;
  setIsAnnouncesChecked: Dispatch<SetStateAction<boolean>>;
  setIsAllChecked: Dispatch<SetStateAction<boolean>>;
  isPerformanceChecked: boolean;
  isAnnouncesChecked: boolean;
  isAllChecked: boolean;
}

export function CookiesConsentChoice(props: Props): JSX.Element {
  const {
    isAllChecked,
    isAnnouncesChecked,
    isPerformanceChecked,
    setIsAnnouncesChecked,
    setIsAllChecked,
    setIsPerformanceChecked,
    setIsVisible,
    ...rest
  } = props;
  const { setCookiesBannerVisible } = useBannersState();
  const { t } = useTranslation();

  useEffect(() => {
    const isHidden = localStorage.getItem(LocalStorage.CookiesBannerIsHidden);
    setIsVisible(isHidden === null || isHidden === `false`);
    setCookiesBannerVisible(isHidden === null || isHidden === `false`);
  }, []);

  const onPerformanceCheck = () => {
    setIsPerformanceChecked(!isPerformanceChecked);
  };

  const onAnnounceCheck = () => {
    setIsAnnouncesChecked(!isAnnouncesChecked);
  };

  const onCheckAll = () => {
    setIsAllChecked(!isAllChecked);
    if (isAllChecked) {
      setIsPerformanceChecked(false);
      setIsAnnouncesChecked(false);
    } else {
      setIsPerformanceChecked(true);
      setIsAnnouncesChecked(true);
    }
  };

  const setBgColor = (isChecked: boolean) => {
    if (isChecked) {
      return `black`;
    }
    return ``;
  };

  return (
    <Box position="absolute" top={0} pt={5} pb={5} w="100%">
      <Flex display="block">
        <Flex alignItems="center" justify="space-between" mb={3} ml={5} mr={5}>
          <Text variant="2" fontWeight={600} fontSize={16} mr={2}>
            {t(`cookiesBanner.all`)}
          </Text>
          <Switch
            borderRadius={20}
            bgColor={setBgColor(isAllChecked)}
            colorScheme="blackAlpha"
            onChange={onCheckAll}
            isChecked={isAllChecked}
            size="md"
            {...rest}
          />
        </Flex>
      </Flex>
      <Flex pt={3} pb={3} display="block" ml={5} mr={5}>
        <Flex alignItems="center" justify="space-between" mb={2}>
          <Text variant="2" fontWeight={600} fontSize={16} mr={2}>
            {t(`cookiesBanner.necessary`)}
          </Text>
          <Switch
            colorScheme="blackAlpha"
            isDisabled
            size="md"
            isChecked
            {...rest}
          />
        </Flex>
        <Text
          variant="3"
          color="gray.500"
          fontWeight="medium"
          fontSize={13}
          maxW={300}
        >
          {t(`cookiesBanner.necessaryText`)}
        </Text>
      </Flex>
      <Flex pt={3} pb={3} display="block" ml={5} mr={5}>
        <Flex alignItems="center" justify="space-between" mb={2}>
          <Text variant="2" fontWeight={600} fontSize={16} mr={2}>
            {t(`cookiesBanner.performance`)}
          </Text>
          <Switch
            borderRadius={20}
            bg={setBgColor(isPerformanceChecked)}
            colorScheme="blackAlpha"
            onChange={onPerformanceCheck}
            isChecked={isPerformanceChecked}
            size="md"
            {...rest}
          />
        </Flex>
        <Text
          variant="3"
          color="gray.500"
          fontWeight="medium"
          fontSize={13}
          maxW={300}
        >
          {t(`cookiesBanner.performanceText`)}
        </Text>
      </Flex>
      <Flex pt={3} pb={3} display="block" ml={5} mr={5}>
        <Flex alignItems="center" justify="space-between" mb={2}>
          <Text variant="2" fontWeight={600} fontSize={16} mr={2}>
            {t(`cookiesBanner.analysis`)}
          </Text>
          <Switch
            borderRadius={20}
            bg={setBgColor(isAnnouncesChecked)}
            colorScheme="blackAlpha"
            onChange={onAnnounceCheck}
            isChecked={isAnnouncesChecked}
            size="md"
            {...rest}
          />
        </Flex>
        <Text
          variant="3"
          color="gray.500"
          fontWeight="medium"
          fontSize={13}
          maxW={300}
        >
          {t(`cookiesBanner.announcesText`)}
        </Text>
      </Flex>
    </Box>
  );
}
